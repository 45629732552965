import * as turf from '@turf/turf';
import * as map_helpers from '../components/app/map/Map_helpers';
import * as other_helpers from './Other_helpers';

import proj4 from 'proj4';
proj4.defs([
    [
        'EPSG:4326',
        '+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees'],
    [
        'EPSG:2154',
        '+proj=lcc +lat_0=46.5 +lon_0=3 +lat_1=49 +lat_2=44 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs'
    ]
]);


//__________ TOPO & MAJIC

export const get_topo_majic_buildings = async (capacity) => {
    // Fetch 1 : API IGN Alti
    // var topo = "error";
    var topo = await get_topo(capacity);

    // Fetch 2 : API MAJIC
    // var majic = capacity.landBase.lands;
    var majic = await get_majic(capacity);

    // Fetch 3 : API IGN BD Topo
    // var buildings = "error";
    var buildings = await get_buildings(capacity);

    // Fetch 4 : API OSM (District)
    // var district = "error";
    var district = await get_district(capacity);

    // Fetch 5 : API OSM (Trees)
    // var trees = "error";
    var trees = await get_trees(capacity);

    var result = {
        topo: topo,
        majic: majic,
        buildings: buildings,
        administrative: district,
        trees: trees
    };
    return result;
}

const get_topo = async (capacity) => {
    var max_points = 449; // From URL max length of API
    // Create grid of points inside the union at SPACING m each
    var union_buffer = turf.buffer(capacity.landBase.union.geometry, 0.0025, { units: 'kilometers' });
    // var union_buffer_area = turf.area(union_buffer);
    var extent = turf.bbox(union_buffer);
    var extent_h = turf.distance(turf.point([extent[0], extent[1]]), turf.point([extent[0], extent[3]])) * 1000;
    var extent_w = turf.distance(turf.point([extent[0], extent[1]]), turf.point([extent[2], extent[1]])) * 1000;
    var seed = Math.sqrt(extent_h * extent_w / max_points);
    var max_distance = Math.ceil(Math.max(extent_h / Math.floor(extent_h / seed), extent_w / Math.floor(extent_w / seed))) / 1000;
    // console.log("other", ((union_buffer_area / 800) / 1000));
    var spacing = Math.max(0.0025, max_distance);
    console.log("spacing", spacing);
    // var extent = [union_buffer_bbox[0], union_buffer_bbox[1], capacity.landBase.union.bbox.coordinates[0][2][0], capacity.landBase.union.bbox.coordinates[0][2][1]];
    // var spacing = Math.max(0.0025, ((capacity.landBase.union.area / 800) / 1000));
    // var extent = [capacity.landBase.union.bbox.coordinates[0][0][0], capacity.landBase.union.bbox.coordinates[0][0][1], capacity.landBase.union.bbox.coordinates[0][2][0], capacity.landBase.union.bbox.coordinates[0][2][1]];
    // var mask_poly = turf.buffer(capacity.landBase.union.geometry, 0.002, { units: 'kilometers' });
    // var grid = turf.pointGrid(extent, spacing, { units: 'kilometers', mask: mask_poly });
    var grid = turf.pointGrid(extent, spacing);
    if (grid.features.length >= max_points) {
        grid = turf.pointGrid(extent, spacing - 0.0005);
    }
    // Check which points are inside land
    grid.features.forEach(point => {
        if (turf.booleanPointInPolygon(point, capacity?.landBase?.union?.geometry)) {
            point.properties.position = "in";
        }
        else {
            point.properties.position = "out";
        }
    })
    // Add bounds points to grid
    for (var i = 0; i < capacity.landBase.union.bounds.length; i++) {
        grid.features.push({
            "type": "Feature",
            "properties": { position: "bound" },
            "geometry": {
                "type": "Point",
                "coordinates": capacity.landBase.union.bounds[i].start_coord_global
            }
        })
    }
    // Set id to each grid element and round coords to 4 decimals
    for (var i = 0; i < grid.features.length; i++) {
        grid.features[i].properties.id = i;
        // grid.features[i].geometry.coordinates = [Math.round( grid.features[i].geometry.coordinates[0] * 1e5 ) / 1e5, Math.round( grid.features[i].geometry.coordinates[1] * 1e5 ) / 1e5];
    }
    // Create triangulated network from grid
    var tin = turf.tin(grid, "id");

    // Create api url
    var lon_list = "";
    var lat_list = "";
    for (var i = 0; i < grid.features.length; i++) {
        if (lon_list !== "") {
            lon_list = lon_list + "|";
        }
        lon_list = lon_list + grid.features[i].geometry.coordinates[0].toFixed(5);

        if (lat_list !== "") {
            lat_list = lat_list + "|";
        }
        lat_list = lat_list + grid.features[i].geometry.coordinates[1].toFixed(5);
    }
    // MAX POINTS = 449
    // var api_url = "https://wxs.ign.fr/calcul/alti/rest/elevation.json?lon=" + lon_list + "&lat=" + lat_list + "&zonly=true";
    var api_url = "https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevation.json?lon=" + lon_list + "&lat=" + lat_list + "&resource=ign_rge_alti_wld&zonly=true";
    console.log("api_url", api_url);
    console.log('grid length', grid.features.length);

    // Fetch 1
    console.log("-FETCHING TOPO- : Loading...", grid);
    var alti = "error";
    try {
        const res1 = await fetch(api_url);
        const result = await res1.json();
        console.log("-FETCHING TOPO- : Success", result);
        // CREATE MATRIX
        var point_matrix = [];
        // var point_matrix_coords = [];
        var point_max = [null, null, -Infinity];
        var point_min = [null, null, Infinity];
        // var point_max = [grid.features[0].geometry.coordinates[0], grid.features[0].geometry.coordinates[1], result.elevations[0]];
        // var point_min = [grid.features[0].geometry.coordinates[0], grid.features[0].geometry.coordinates[1], result.elevations[0]];
        var elevation_sum = 0;
        var elevation_nb = 0;
        for (var i = 0; i < grid.features.length; i++) {
            // Add to matrix
            point_matrix.push({
                cg: grid.features[i].geometry.coordinates,
                cl: map_helpers.mercator_to_local(grid.features[i].geometry.coordinates, capacity.landBase.union.bbox.origin),
                z: result.elevations[i],
                pos: grid.features[i].properties?.position
            });
            // Add to matrix coords
            // point_matrix_coords.push(grid.features[i].geometry.coordinates[0] + "|" + grid.features[i].geometry.coordinates[1]);
            // If point inside land, check min and max and calculate mean elevation
            if ((grid.features[i].properties?.position === "in" || grid.features[i].properties?.position === "bound")) {
                // Check max
                if (result.elevations[i] > point_max[2] && result.elevations[i] < 99999) { point_max = [grid.features[i].geometry.coordinates[0], grid.features[i].geometry.coordinates[1], result.elevations[i]] };
                // Check min
                if (result.elevations[i] < point_min[2] && result.elevations[i] > -99999) { point_min = [grid.features[i].geometry.coordinates[0], grid.features[i].geometry.coordinates[1], result.elevations[i]] };
                // Iterate sum to calculate mean elevation
                elevation_sum += result.elevations[i];
                elevation_nb++;
            }
        }
        var elevation_mean = elevation_sum / elevation_nb;

        // Verify point min and point max exists
        if (point_max[0] === null || point_min[0] === null) {
            console.log("-FETCHING TOPO- : Error = No min and max points found");
            return "error"
        }

        // GET MAIN DATA
        var height = point_max[2] - point_min[2];
        var length = (turf.distance(turf.point([point_min[0], point_min[1]]), turf.point([point_max[0], point_max[1]])) * 1000);
        var slide = (height / length) * 100;
        alti = {
            // tin: tin,
            matrix: point_matrix,
            point_max: point_max,
            point_min: point_min,
            height: height,
            slide: slide,
            elevation_mean: elevation_mean
        }

        // GET PROFILE ELEMENTS
        // Get bearing
        var bearing = turf.bearing(turf.point([point_max[0], point_max[1]]), turf.point([point_min[0], point_min[1]]));
        // Get further points
        var destination1 = turf.destination(turf.point([point_max[0], point_max[1]]), 0.5, bearing);
        var destination2 = turf.destination(turf.point([point_max[0], point_max[1]]), -0.5, bearing);
        // Create new line
        var profile_line1 = turf.lineString([destination1.geometry.coordinates, destination2.geometry.coordinates]);
        // Create union poly
        var polygon = turf.polygon(capacity.landBase.union.geometry.geometry.coordinates);
        // Get intersections points
        var intersects = turf.lineIntersect(profile_line1, polygon);
        if (intersects.features.length > 1) {
            var profile_line = turf.lineString([intersects.features[0].geometry.coordinates, intersects.features[intersects.features.length - 1].geometry.coordinates]);
            var sampling = Math.ceil(turf.length(profile_line) / 0.0025);
            // if (sampling < 2) { sampling = 2 }
            if (sampling > 30) { sampling = 30 }

            // Fetch 2
            console.log("-FETCHING TOPO PROFILE- : Loading...");
            // var api_url_new = "https://wxs.ign.fr/calcul/alti/rest/elevationLine.json?sampling=" + sampling + "&lon=" + intersects.features[0].geometry.coordinates[0] + "|" + intersects.features[intersects.features.length - 1].geometry.coordinates[0] + "&lat=" + intersects.features[0].geometry.coordinates[1] + "|" + intersects.features[intersects.features.length - 1].geometry.coordinates[1];
            var api_url_new = "https://data.geopf.fr/altimetrie/1.0/calcul/alti/rest/elevationLine.json?lon=" + intersects.features[0].geometry.coordinates[0] + "|" + intersects.features[intersects.features.length - 1].geometry.coordinates[0] + "&lat=" + intersects.features[0].geometry.coordinates[1] + "|" + intersects.features[intersects.features.length - 1].geometry.coordinates[1] + "&resource=ign_rge_alti_wld&sampling=" + sampling;
            try {
                const res2 = await fetch(api_url_new);
                var result2 = await res2.json();
                console.log("-FETCHING TOPO PROFILE- : Success", result2);
                // Check -99999 values
                var filtered = [];
                for (var i = 0; i < result2.elevations.length; i++) {
                    if (result2.elevations[i].z === 99999 || result2.elevations[i].z === -99999) {

                    }
                    else {
                        filtered.push(result2.elevations[i]);
                    }
                }
                result2.elevations = filtered;
                // Get real max and min & add points to matrix
                var point_max_new = [result2.elevations[0].lon, result2.elevations[0].lat, result2.elevations[0].z];
                var point_min_new = [result2.elevations[0].lon, result2.elevations[0].lat, result2.elevations[0].z];
                for (var i = 0; i < result2.elevations.length; i++) {
                    // Check max & min point
                    if (result2.elevations[i].z > point_max_new[2] && result2.elevations[i].z < 99999) { point_max_new = [result2.elevations[i].lon, result2.elevations[i].lat, result2.elevations[i].z] };
                    if (result2.elevations[i].z < point_min_new[2] && result2.elevations[i].z > -99999) { point_min_new = [result2.elevations[i].lon, result2.elevations[i].lat, result2.elevations[i].z] };
                    // Get local coords
                    var coords_local = map_helpers.mercator_to_local([result2.elevations[i].lon, result2.elevations[i].lat], capacity.landBase.union.bbox.origin)
                    result2.elevations[i].coords_local = coords_local;
                    // Get distance from first point
                    var distance = 0;
                    if (i > 0) {
                        distance = turf.distance(turf.point([result2.elevations[0].lon, result2.elevations[0].lat]), turf.point([result2.elevations[i].lon, result2.elevations[i].lat])) * 1000;
                    }
                    result2.elevations[i].distance = distance;
                    // Push to matrix & grid
                    // if (!point_matrix_coords.includes(result.elevations[i].lon + "|" + result.elevations[i].lat)) {
                    //     point_matrix.push({
                    //         coord_global: [result.elevations[i].lon, result.elevations[i].lat],
                    //         coord_local: coords_local,
                    //         elevation: result.elevations[i].z
                    //     });
                    //     grid.features.push({
                    //         "type": "Feature",
                    //         "properties": {
                    //             id: grid.features.length
                    //         },
                    //         "geometry": {
                    //             "type": "Point",
                    //             "coordinates": [result.elevations[i].lon, result.elevations[i].lat]
                    //         }
                    //     })
                    // }
                }
                // New data
                var tin_new = turf.tin(grid, "id");
                var height_new = point_max_new[2] - point_min_new[2];
                var length_new = (turf.distance(turf.point([point_min_new[0], point_min_new[1]]), turf.point([point_max_new[0], point_max_new[1]])) * 1000);
                var slide_new = (height_new / length_new) * 100;
                alti = {
                    // tin: tin_new,
                    matrix: point_matrix,
                    point_max: point_max_new,
                    point_min: point_min_new,
                    point_min_all: point_min,
                    height: height_new,
                    slide: slide_new,
                    profile: result2,
                    elevation_mean: elevation_mean
                }

                // Return Fetch 2
                return alti;
            } catch (error) {
                // Error Fetch 2
                console.log("-FETCHING TOPO PROFILE- : Error", error);
                return alti;
            }
        }
        else {
            return alti;
        }
    } catch (error) {
        // Error Fetch 1
        console.log("-FETCHING TOPO- : Error", error);
        return "error";
    }
}

const get_majic = async (capacity) => {
    // Create api url
    var list_city = [];
    var list_section = [];
    var list_num = [];
    for (var i = 0; i < capacity.landBase.lands.length; i++) {
        if (!list_city.includes(capacity.landBase.lands[i].properties.commune)) {
            list_city.push(capacity.landBase.lands[i].properties.commune);
        }
        if (!list_section.includes(capacity.landBase.lands[i].properties.section)) {
            list_section.push(capacity.landBase.lands[i].properties.section);
        }
        var num = capacity.landBase.lands[i].properties.numero;
        if (num.length === 3) { num = "0" + num }
        else if (num.length === 2) { num = "00" + num }
        else if (num.length === 1) { num = "000" + num }
        if (!list_num.includes(num)) {
            list_num.push(num);
        }
    }
    var text_city = "(";
    for (var i = 0; i < list_city.length; i++) {
        if (i > 0) {
            text_city = text_city + "+OR+";
        }
        text_city = text_city + "com_arm_code%3D" + list_city[i];
    }
    text_city = text_city + ")";
    var text_section = "(";
    for (var i = 0; i < list_section.length; i++) {
        if (i > 0) {
            text_section = text_section + "+OR+";
        }
        text_section = text_section + "section%3D" + list_section[i];
    }
    text_section = text_section + ")";
    var text_num = "(";
    for (var i = 0; i < list_num.length; i++) {
        if (i > 0) {
            text_num = text_num + "+OR+";
        }
        text_num = text_num + "num_plan%3D" + list_num[i];
    }
    text_num = text_num + ")";
    var url_api = "https://public.opendatasoft.com/api/records/1.0/search/?dataset=buildingref-france-majic-parcelles-millesime&q=" + text_city + "+AND+" + text_section + "+AND+" + text_num;
    // Fetch : API MAJIC
    console.log("-FETCHING MAJIC- : Loading...");
    var majic = capacity.landBase.lands;
    if (capacity.landBase.lands.length > 0) {
        try {
            const res3 = await fetch(url_api);
            const result3 = await res3.json();
            console.log("-FETCHING MAJIC- : Success", result3);
            // Link results to lands
            for (var i = 0; i < result3.records.length; i++) {
                for (var j = 0; j < capacity.landBase.lands.length; j++) {
                    if (result3.records[i].fields.com_arm_code === capacity.landBase.lands[j].properties.commune && result3.records[i].fields.section === capacity.landBase.lands[j].properties.section && parseInt(result3.records[i].fields.num_plan) === parseInt(capacity.landBase.lands[j].properties.numero)) {
                        majic[j].majic = result3.records[i].fields;
                        break;
                    }
                }
            }
            // Return Fetch
            return majic;
        } catch (error) {
            // Error Fetch
            console.log("-FETCHING MAJIC- : Error", error);
            return majic;
        }
    }
    else {
        return majic;
    }
}

const get_buildings = async (capacity) => {
    var offset = 0.001;
    // var url_api = "https://wxs.ign.fr/choisirgeoportail/geoportail/wfs?SERVICE=WFS&REQUEST=GetFeature&TYPENAME=BDTOPO_V3:batiment&OUTPUTFORMAT=application/json&BBOX=" + (capacity.landBase.union.bbox.coordinates[0][0][0] - offset).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][0][1] - offset).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][2][0] + offset).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][2][1] + offset).toString() + ",EPSG:4326";
    var url_api = "https://data.geopf.fr/wfs/ows?SERVICE=WFS&REQUEST=GetFeature&TYPENAME=BDTOPO_V3:batiment&OUTPUTFORMAT=application/json&BBOX=" + (capacity.landBase.union.bbox.coordinates[0][0][0] - offset).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][0][1] - offset).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][2][0] + offset).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][2][1] + offset).toString() + ",EPSG:4326";
    console.log("url_api", url_api);
    // Fetch 3 : API Georisques Basias
    console.log("-FETCHING BUILDINGS- : Loading...");
    var buildings = "error";
    try {
        const res3 = await fetch(url_api);
        const result3 = await res3.json();
        console.log("-FETCHING BUILDINGS- : Success", result3);

        // Matching btw osm & ign ones
        var land_poly = turf.polygon(capacity.landBase.union.geometry.geometry.coordinates);
        buildings = [];
        var buildings_land = {
            osm: [],
            ign: [],
            index: [],
            data: []
        }
        var land_poly_buffer = turf.buffer(land_poly, 0.004);
        var buildings_close = {
            osm: [],
            ign: [],
            index: [],
            data: []
        }
        var matched_osm = [];
        var matched_ign = [];


        var buildings_osm = [];
        if (capacity.landBase?.buildings_osm) {
            buildings_osm = capacity.landBase.buildings_osm;
        }
        // else if (capacity.landBase?.buildings?.buildings) {
        //     buildings_osm = capacity.landBase.buildings.buildings;
        // }

        var buildings_old = capacity?.landBase?.buildings?.buildings_old || null;


        for (var i = 0; i < buildings_osm.length; i++) {
            var building_osm_poly = buildings_osm[i];
            var building_osm_area = turf.area(building_osm_poly);
            // Check land intersection
            var land_intersection_osm = turf.intersect(building_osm_poly, land_poly);
            var isLand_osm = false;
            if (land_intersection_osm !== null) {
                var land_intersection_osm_area = turf.area(land_intersection_osm);
                if ((land_intersection_osm_area / building_osm_area) > 0.5) {
                    buildings_land.osm.push(building_osm_poly.id.toString());
                    // isLand_osm = true;
                }
            }
            if (isLand_osm === false) {
                // Check land buffer intersection
                var land_intersection_osm = turf.intersect(building_osm_poly, land_poly_buffer);
                if (land_intersection_osm !== null) {
                    buildings_close.osm.push(building_osm_poly.id);
                }
            }

            var matched = null;
            var matching = 0;
            for (var j = 0; j < result3.features.length; j++) {
                var building_ign_poly = result3.features[j];
                var building_ign_area = turf.area(building_ign_poly);
                // Check land intersection
                if (i === 0) { // Check only on first loop of i
                    var land_intersection_ign = turf.intersect(building_ign_poly, land_poly);
                    var isLand_ign = false;
                    if (land_intersection_ign !== null) {
                        var land_intersection_ign_area = turf.area(land_intersection_ign);
                        if ((land_intersection_ign_area / building_ign_area) > 0.5) {
                            buildings_land.ign.push(building_ign_poly.id);
                            // isLand_ign = true;
                        }
                    }
                    if (isLand_ign === false) {
                        // Check land buffer intersection
                        var land_intersection_ign = turf.intersect(building_ign_poly, land_poly_buffer);
                        if (land_intersection_ign !== null) {
                            buildings_close.ign.push(building_ign_poly.id);
                        }
                    }
                }

                // Check area
                // if (building_osm_area > (building_ign_area - 2) && building_osm_area < (building_ign_area + 2)) {
                // Check intersection
                var intersection = turf.intersect(building_osm_poly, building_ign_poly);
                // if (intersection !== null && (turf.area(intersection) / building_osm_area) > 0.5) {
                if (intersection !== null) {
                    // Check if already matching one
                    if (matching < (turf.area(intersection) / building_osm_area)) {
                        matched = j;
                        matching = turf.area(intersection) / building_osm_area;
                    }
                }
                // }
            }
            // Add to final list
            if (matched !== null) {
                var height_max = result3.features[matched]?.properties.altitude_maximale_toit - result3.features[matched]?.properties.altitude_minimale_sol;
                if (height_max < result3.features[matched]?.properties.hauteur) {
                    height_max = result3.features[matched]?.properties.hauteur;
                }
                matched_osm.push(building_osm_poly.id);
                matched_ign.push(result3.features[matched].id);
                buildings.push({
                    geometry: building_osm_poly.geometry,
                    properties: {
                        type: "matched",
                        matching: matching,
                        id: "osm" + (building_osm_poly.id).toString() + "_ign" + result3.features[matched].id,
                        id_osm: (building_osm_poly.id).toString(),
                        id_ign: result3.features[matched].id,
                        area: building_osm_area,
                        height: result3.features[matched]?.properties.hauteur,
                        height_max: height_max,
                        osm_properties: building_osm_poly.properties,
                        ign_properties: result3.features[matched]?.properties,
                    },
                    type: "Feature",
                });
            }
        }
        // Add unmatched osm buildings
        for (var i = 0; i < buildings_osm.length; i++) {
            if (!matched_osm.includes(buildings_osm[i].id)) {
                buildings.push({
                    geometry: buildings_osm[i].geometry,
                    properties: {
                        type: "osm",
                        matching: matching,
                        id: "osm" + (buildings_osm[i].id).toString() + "_ign",
                        id_osm: (buildings_osm[i].id).toString(),
                        id_ign: null,
                        area: turf.area(buildings_osm[i]),
                        height: buildings_osm[i].properties.height,
                        height_max: buildings_osm[i].properties.height,
                        osm_properties: buildings_osm[i].properties,
                        ign_properties: null,
                    },
                    type: "Feature",
                });
            }
        }
        // Add unmatched ign buildings
        for (var i = 0; i < result3.features.length; i++) {
            if (!matched_ign.includes(result3.features[i].id)) {
                var height_max = result3.features[i]?.properties.altitude_maximale_toit - result3.features[i]?.properties.altitude_minimale_sol;
                if (height_max < result3.features[i]?.properties.hauteur) {
                    height_max = result3.features[i]?.properties.hauteur;
                }
                buildings.push({
                    geometry: result3.features[i].geometry,
                    properties: {
                        type: "ign",
                        matching: matching,
                        id: "osm_ign" + result3.features[i].id,
                        id_osm: null,
                        id_ign: result3.features[i].id,
                        area: turf.area(result3.features[i]),
                        height: result3.features[i]?.properties.hauteur,
                        height_max: height_max,
                        osm_properties: null,
                        ign_properties: result3.features[i].properties,
                    },
                    type: "Feature",
                });
                // CHECK LAND INTERSECTION
                var building_ign_poly = result3.features[i];
                var building_ign_area = turf.area(building_ign_poly);
                var land_intersection_ign = turf.intersect(building_ign_poly, land_poly);
                var isLand_ign = false;
                if (land_intersection_ign !== null) {
                    var land_intersection_ign_area = turf.area(land_intersection_ign);
                    if ((land_intersection_ign_area / building_ign_area) > 0.5) {
                        buildings_land.ign.push(building_ign_poly.id);
                        // isLand_ign = true;
                    }
                }
                if (isLand_ign === false) {
                    // Check land buffer intersection
                    var land_intersection_ign = turf.intersect(building_ign_poly, land_poly_buffer);
                    if (land_intersection_ign !== null) {
                        buildings_close.ign.push(building_ign_poly.id);
                    }
                }
            }
        }

        // Get buildings land indexes
        for (var i = 0; i < buildings.length; i++) {
            if ((buildings[i]?.properties?.id_osm && buildings_land.osm.includes(buildings[i].properties.id_osm.toString())) || (buildings[i]?.properties?.id_ign && buildings_land.ign.includes(buildings[i].properties.id_ign))) {
                buildings_land.index.push(i);
                buildings_land.data.push(buildings[i]);
            }
            if (buildings_close.osm.includes(buildings[i].properties.id_osm) || buildings_close.ign.includes(buildings[i].properties.id_ign)) {
                buildings_close.index.push(i);
                buildings_close.data.push(buildings[i]);
            }
        }

        // Sort buildings land by bigger surface first
        buildings_land.index.sort(function (a, b) {
            return buildings_land.data[buildings_land.index.indexOf(b)]["properties"]["area"] - buildings_land.data[buildings_land.index.indexOf(a)]["properties"]["area"];
        });

        // Preset demolition param and add label
        for (var i = 0; i < buildings_land.index.length; i++) {
            buildings[buildings_land.index[i]].properties.demolition = true;
            buildings[buildings_land.index[i]].properties.status = "demolition";
            buildings[buildings_land.index[i]].properties.label = "Construction N°" + (i + 1);
        }

        // Get sum area
        console.log("buildings_land", buildings_land);
        var buildings_land_area = 0;
        buildings_land.data.forEach(building => {
            buildings_land_area += building?.properties?.area;
        })

        // Keep only necessary data
        buildings_close = buildings_close.index;
        buildings_land = buildings_land.index;

        // TEST BDNB
        //     var lambert = await get_lambert_from_wgs84((capacity.landBase.union.bbox.coordinates[0][0][0]).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][0][1]).toString() + ";" + (capacity.landBase.union.bbox.coordinates[0][2][0]).toString() + "," + (capacity.landBase.union.bbox.coordinates[0][2][1]).toString());
        // console.log("lambert", lambert);

        var lambert_min = get_lambert_from_wgs84([capacity.landBase.union.bbox.coordinates[0][0][0], capacity.landBase.union.bbox.coordinates[0][0][1]]);
        var lambert_max = get_lambert_from_wgs84([capacity.landBase.union.bbox.coordinates[0][2][0], capacity.landBase.union.bbox.coordinates[0][2][1]]);
        var lambert = [lambert_min, lambert_max];
        console.log("lambert", lambert);

        var buildings_bdnb_details = { status: "none", list: [], indexes: [] };

        if (lambert !== "error") {
            var bdnb = await get_buildings_bdnb(lambert);
            console.log("bdnb", bdnb);


            if (bdnb !== "error") {
                // Transform coordinates
                bdnb.forEach((building) => {
                    if (building?.geombui?.type === "Polygon" && building?.geombui?.coordinates) {
                        var coords = [];
                        building.geombui.coordinates[0].forEach(coord => {
                            coords.push(get_wgs84_from_lambert(coord));
                        })
                        building.turf = turf.polygon([coords]);
                    }
                    else if (building?.geombui?.type === "MultiPolygon" && building?.geombui?.coordinates) {
                        var coords_group = [];
                        building.geombui.coordinates[0].forEach((coord_group, coord_group_index) => {
                            coords_group.push([]);
                            coord_group.forEach(coord => {
                                coords_group[coord_group_index].push(get_wgs84_from_lambert(coord));
                            })
                        })
                        building.turf = turf.multiPolygon([coords_group]);
                    }
                })
                // console.log("bdnb", bdnb);



                for (var j = 0; j < buildings_land.length; j++) {
                    var building_index = buildings_land[j];
                    var building_poly = buildings[building_index];
                    var building_poly_area = turf.area(building_poly);
                    // console.log(index, " - building_poly_area", building_poly_area);

                    for (var i = 0; i < bdnb.length; i++) {
                        var bdnb_poly = bdnb[i].turf;
                        var bdnb_poly_area = turf.area(bdnb_poly);
                        // console.log(i, " - bdnb_poly_area", bdnb_poly_area);

                        if (building_poly_area > (bdnb_poly_area - 10) && building_poly_area < (bdnb_poly_area + 10)) {
                            var building_bdnb_intersection = turf.intersect(building_poly, bdnb_poly);
                            if (building_bdnb_intersection !== null) {
                                // Get detailed data (for free because we are smart!)
                                // var bdnb_details = await get_building_bdnb_detail(bdnb[i].bnb_id);
                                // buildings[building_index].properties.bdnb_details = bdnb_details;
                                buildings[building_index].properties.bdnb = bdnb[i];
                                buildings_bdnb_details.status = "fetching";
                                buildings_bdnb_details.list.push(bdnb[i].bnb_id);
                                buildings_bdnb_details.indexes.push(building_index);
                                console.log("BDNB MATCH", building_index);
                            }
                        }
                    }
                }
            }
        }


        // Get bounds type
        var new_buildings = other_helpers.get_existing_buildings_bound_type({ ...capacity, landBase: { buildings: { buildings: buildings, buildings_land: buildings_land } } });
        if (new_buildings !== null) {
            buildings = new_buildings;
        }

        // Compare to buildings_old data
        if (buildings_old !== null) {
            buildings_land.forEach(building_index => {
                try {
                    if (buildings[building_index]?.properties?.id === buildings_old[building_index]?.properties?.id) {
                        buildings[building_index].properties.areaTotal_estimated = buildings_old[building_index].properties?.areaTotal_estimated || null;
                        buildings[building_index].properties.demolition = buildings_old[building_index].properties?.demolition || null;
                        buildings[building_index].properties.nbLevel_estimated = buildings_old[building_index].properties?.nbLevel_estimated || null;
                        buildings[building_index].properties.raisable = buildings_old[building_index].properties?.raisable || null;
                        buildings[building_index].properties.status = buildings_old[building_index].properties?.status || null;
                    }
                } catch (error) {
                    console.log("Error in comparing buildings old", error);
                }
            })
        }

        // Return Fetch 3
        var buildings_final = {
            // buildings_ign: result3.features,
            buildings: buildings,
            buildings_land: buildings_land,
            buildings_close: buildings_close,
            buildings_land_area: buildings_land_area,
            buildings_bdnb_details: buildings_bdnb_details
        }

        return buildings_final;
    } catch (error) {
        // Error Fetch 3
        console.log("-FETCHING BUILDINGS- : Error", error);
        return buildings;
    }
}

const get_lambert_from_wgs84 = (coordinates) => {
    var lambert = proj4('EPSG:4326', 'EPSG:2154', coordinates);
    return lambert;
}


const get_wgs84_from_lambert = (coordinates) => {
    var wgs = proj4('EPSG:2154', 'EPSG:4326', coordinates);
    return wgs;
}

const get_buildings_bdnb = async (lambert) => {
    // Fetch : API epsg.io
    console.log("-FETCHING BUILDINGS 3- : Loading...");
    var bdnb = "error";
    try {
        const res3 = await fetch("https://api.bdnb.io/v2/gorenove/buildings/bbox?xmin=" + lambert[0][0] + "&ymin=" + lambert[0][1] + "&xmax=" + lambert[1][0] + "&ymax=" + lambert[1][1] + "&limit=100", { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING BUILDINGS 3- : Success", result3);
        bdnb = result3;
        // Return Fetch 3
        return bdnb;
    } catch (error) {
        // Error Fetch 3
        console.log("-FETCHING BUILDINGS 3- : Error", error);
        return bdnb;
    }
}

const get_building_bdnb_detail = async (bdnb_id) => {
    // Fetch : API epsg.io
    console.log("-FETCHING BUILDINGS 4- : Loading...");
    var bdnb = "error";
    try {
        const res3 = await fetch("https://api.gorenove.fr/v2/particulier/buildings?bnb_id=eq." + bdnb_id, { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING BUILDINGS 4- : Success", result3);
        bdnb = result3;
        // Return Fetch 3
        return bdnb;
    } catch (error) {
        // Error Fetch 3
        console.log("-FETCHING BUILDINGS 4- : Error", error);
        return bdnb;
    }
}


const get_district = async (capacity) => {
    console.log("-FETCHING DISTRICT- : Loading...");
    var response = await fetchOSMdata_point(capacity?.landBase?.union?.center?.geometry?.coordinates, get_osm_bbox(capacity, 150), [{ label: "arrond", key: "admin_level", value: "9" }, { label: "district", key: "admin_level", value: "10" }, { label: "subdistrict", key: "admin_level", value: "11" }]);
    if (response !== "error") {
        response = {
            arrond: response?.arrond?.tags?.name || null,
            district: response?.district?.tags?.name || null,
            subdistrict: response?.subdistrict?.tags?.name || null,
        }
    }
    else {
        response = "error";
    }
    console.log("-FETCHING DISTRICT- : End");
    return response
}

const get_trees = async (capacity) => {
    console.log("-FETCHING TREES- : Loading...");
    try {
        var trees_point = await fetchOSMdata_bbox(get_osm_bbox(capacity, 150), ["node[natural=tree]"], capacity);
        var trees_line = await fetchOSMdata_bbox(get_osm_bbox(capacity, 200), ["nwr[natural=tree_row]"], capacity);
        var result = "error";
        // Add trees point
        if (trees_point?.items) {
            result = JSON.parse(JSON.stringify(trees_point));
        }
        // Add trees line
        if (trees_line?.items) {
            if (result?.items) {
                result.items = result.items.concat(trees_line.items);
                var inside_all = [];
                trees_line.inside.forEach(item => {
                    inside_all.push(item + trees_point.inside.length);
                })
                result.inside = result.inside.concat(inside_all);
                var near_5m_all = [];
                trees_line.near_5m.forEach(item => {
                    near_5m_all.push(item + trees_point.near_5m.length);
                })
                result.near_5m = result.near_5m.concat(near_5m_all);
            }
            else {
                result = trees_line;
            }
        }
        console.log("-FETCHING TREES- : Success", result);
        return result
    } catch (error) {
        console.log("-FETCHING TREES- : Error", error);
        return "error"
    }
}


//__________ RISKS

const risks_list = [
    { type: "nat", category: "Inondation", code: "1100000", libelle: "Inondation", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1110000", libelle: "Inondation - Par une crue (débordement de cours d'eau)", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1120000", libelle: "Inondation - Par une crue à débordement lent de cours d'eau", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1130000", libelle: "Inondation - Par une crue torrentielle ou à montée rapide de cours d’eau", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1140000", libelle: "Inondation - Par ruissellement et coulée de boue", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1150000", libelle: "Inondation - Par lave torrentielle (torrent et talweg)", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1160000", libelle: "Inondation - Par remontées de nappes naturelles", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Inondation", code: "1170000", libelle: "Inondation - Par submersion marine", icon: "https://static.wixstatic.com/shapes/f2dfcd_796554e9d6ed4f5fad68aecdb7457e1f.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1200000", libelle: "Mouvement de terrain", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1210000", libelle: "Mouvement de terrain - Affaissements et effondrements liés aux cavités souterraines (hors mines)", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1220000", libelle: "Mouvement de terrain - Effondrement", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1230000", libelle: "Mouvement de terrain - Eboulement, chutes de pierres et de blocs", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1240000", libelle: "Mouvement de terrain - Glissement de terrain", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1250000", libelle: "Mouvement de terrain - Avancée dunaire", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1260000", libelle: "Mouvement de terrain - Recul du trait de côte et de falaises", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Mouvement de terrain", code: "1270000", libelle: "Mouvement de terrain - Tassements différentiels", icon: "https://static.wixstatic.com/shapes/f2dfcd_0e16eb2e87e244f395a94ce4d985c423.svg", detail: [] },
    { type: "nat", category: "Séisme", code: "1300000", libelle: "Séisme", icon: "https://static.wixstatic.com/shapes/f2dfcd_f9f5a10afa91494bb0798df754096346.svg", detail: [] },
    { type: "nat", category: "Avalanche", code: "1400000", libelle: "Avalanche", icon: "https://static.wixstatic.com/shapes/f2dfcd_733b57f0e9104f8bb3dc4c6ceca19044.svg", detail: [] },
    { type: "nat", category: "Eruption volcanique", code: "1500000", libelle: "Eruption volcanique", icon: "https://static.wixstatic.com/shapes/f2dfcd_2d2df17c1ee14e3588b6f8e7e46dec09.svg", detail: [] },
    { type: "nat", category: "Feu de forêt", code: "1600000", libelle: "Feu de forêt", icon: "https://static.wixstatic.com/shapes/f2dfcd_45e58d2c93744c2d92e0e89f1744699d.svg", detail: [] },
    { type: "nat", category: "Phénomène lié à l'atmosphère", code: "1700000", libelle: "Phénomène lié à l'atmosphère", icon: "https://static.wixstatic.com/shapes/f2dfcd_84b44d6b9bbe41bb840063243428067d.svg", detail: [] },
    { type: "nat", category: "Phénomène lié à l'atmosphère", code: "1710000", libelle: "Phénomène lié à l'atmosphère - Cyclone/ouragan (vent)", icon: "https://static.wixstatic.com/shapes/f2dfcd_84b44d6b9bbe41bb840063243428067d.svg", detail: [] },
    { type: "nat", category: "Phénomène lié à l'atmosphère", code: "1720000", libelle: "Phénomène lié à l'atmosphère - Tempête et grains (vent)", icon: "https://static.wixstatic.com/shapes/f2dfcd_84b44d6b9bbe41bb840063243428067d.svg", detail: [] },
    { type: "nat", category: "Phénomène lié à l'atmosphère", code: "1740000", libelle: "Phénomène lié à l'atmosphère - Foudre", icon: "https://static.wixstatic.com/shapes/f2dfcd_84b44d6b9bbe41bb840063243428067d.svg", detail: [] },
    { type: "nat", category: "Phénomène lié à l'atmosphère", code: "1750000", libelle: "Phénomène lié à l'atmosphère - Grêle", icon: "https://static.wixstatic.com/shapes/f2dfcd_84b44d6b9bbe41bb840063243428067d.svg", detail: [] },
    { type: "nat", category: "Phénomène lié à l'atmosphère", code: "1760000", libelle: "Phénomène lié à l'atmosphère - Neige et Pluies verglaçantes", icon: "https://static.wixstatic.com/shapes/f2dfcd_84b44d6b9bbe41bb840063243428067d.svg", detail: [] },
    { type: "nat", category: "Radon", code: "1800000", libelle: "Radon", icon: "https://static.wixstatic.com/shapes/f2dfcd_c33427a90fc64b5a8298bf56b101ba8c.svg", detail: [] },
    { type: "ind", category: "Risque industriel", code: "2100000", libelle: "Risque industriel", icon: "https://static.wixstatic.com/shapes/f2dfcd_70b6ac0c665a4b129505977ae6d9be52.svg", detail: [] },
    { type: "ind", category: "Risque industriel", code: "2110000", libelle: "Risque industriel - Effet thermique", icon: "https://static.wixstatic.com/shapes/f2dfcd_70b6ac0c665a4b129505977ae6d9be52.svg", detail: [] },
    { type: "ind", category: "Risque industriel", code: "2120000", libelle: "Risque industriel - Effet de surpression", icon: "https://static.wixstatic.com/shapes/f2dfcd_70b6ac0c665a4b129505977ae6d9be52.svg", detail: [] },
    { type: "ind", category: "Risque industriel", code: "2130000", libelle: "Risque industriel - Effet toxique", icon: "https://static.wixstatic.com/shapes/f2dfcd_70b6ac0c665a4b129505977ae6d9be52.svg", detail: [] },
    { type: "ind", category: "Risque industriel", code: "2140000", libelle: "Risque industriel - Effet de projection", icon: "https://static.wixstatic.com/shapes/f2dfcd_70b6ac0c665a4b129505977ae6d9be52.svg", detail: [] },
    { type: "ind", category: "Nucléaire", code: "2200000", libelle: "Nucléaire", icon: "https://static.wixstatic.com/shapes/f2dfcd_6bce257ee9e64fd6b9cd0f9707834c4f.svg", detail: [] },
    { type: "ind", category: "Rupture de barrage", code: "2300000", libelle: "Rupture de barrage", icon: "", detail: [] },
    { type: "ind", category: "Transport de marchandises dangereuses", code: "2400000", libelle: "Transport de marchandises dangereuses", icon: "https://static.wixstatic.com/shapes/f2dfcd_5e7e88bb2ba1416bbe1a0558869a25a7.svg", detail: [] },
    { type: "ind", category: "Engins de guerre", code: "2500000", libelle: "Engins de guerre", icon: "", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3000000", libelle: "Risques miniers", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3100000", libelle: "Mouvements de terrains miniers", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3110000", libelle: "Mouvements de terrains miniers - Effondrements généralisés", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3120000", libelle: "Mouvements de terrains miniers - Effondrements localisés", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3130000", libelle: "Mouvements de terrains miniers - Affaissements progressifs", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3140000", libelle: "Mouvements de terrains miniers - Tassements", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3150000", libelle: "Mouvements de terrains miniers - Glissements ou mouvements de pente", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3160000", libelle: "Mouvements de terrains miniers - Coulées", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3170000", libelle: "Mouvements de terrains miniers - Écroulements rocheux", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3200000", libelle: "Inondations de terrain minier", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3210000", libelle: "Inondations de terrain minier - Pollution des eaux souterraines et de surface", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3220000", libelle: "Inondations de terrain minier - Pollution des sédiments et des sols", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "ind", category: "Risques miniers", code: "3300000", libelle: "Emissions en surface de gaz de mine", icon: "https://static.wixstatic.com/shapes/f2dfcd_6c7e59da05334a15ab8a666a0dfd1306.svg", detail: [] },
    { type: "", category: "Multi-risques", code: "999999", libelle: "Multi-risques", icon: "", detail: [] },
]

export const get_risks = async (center_coord) => {
    var risks = {};

    // Fetch 1 : API Georisques Gaspar
    var nat_ind = await get_risk_nat_ind(center_coord);

    // Fetch 2 : API Georisques Zone sismique
    var earthquake = await get_risk_earthquake(center_coord);

    // Fetch 3 : API Georisques Basias
    var basias = await get_risk_basias(center_coord);

    // Fetch 4 : API Georisques BRGM Argiles
    var clay = await get_risk_clay(center_coord);

    // Fetch 5 : API Georisques BRGM Canalisations
    var pipe = await get_risk_pipe(center_coord);

    // Update risks
    risks = {
        natural: nat_ind.natural,
        industrial: nat_ind.industrial,
        other: nat_ind.other,
        earthquake: earthquake,
        basias: basias,
        clay, clay,
        pipe: pipe
    }

    // Send result
    return risks;
}

const get_risk_nat_ind = async (center_coord) => {
    // Fetch 1 : API Georisques Gaspar
    console.log("-FETCHING RISKS 1- : Loading...");
    var natural = "error";
    var industrial = "error";
    var other = "error";
    try {
        const res1 = await fetch("https://www.georisques.gouv.fr/api/v1/gaspar/risques?rayon=10&latlon=" + center_coord[0].toString(10) + "%2C" + center_coord[1].toString(10), { "method": "get" });
        const result1 = await res1.json();
        console.log("-FETCHING RISKS 1- : Success", result1);
        if (result1.response_code === 200) {
            natural = [];
            industrial = [];
            other = [];
            // Search match with risks_list
            for (var i = 0; i < result1.data[0].risques_detail.length; i++) {
                var found = false;
                for (var j = 0; j < risks_list.length; j++) {
                    if (result1.data[0].risques_detail[i].libelle_risque_long === risks_list[j].libelle) {
                        found = true;
                        // Check if not already added
                        var already = false;
                        if (risks_list[j].type === "nat") {
                            for (var k = 0; k < natural.length; k++) {
                                if (risks_list[j].code.substring(0, 2) === natural[k].code.substring(0, 2)) {
                                    already = true;
                                    // Check if detail
                                    if (risks_list[j].code !== natural[k].code) {
                                        // Add as detail
                                        natural[k].detail.push(risks_list[j]);
                                    }
                                }
                            }
                        }
                        else {
                            for (var k = 0; k < industrial.length; k++) {
                                if (risks_list[j].code.substring(0, 2) === industrial[k].code.substring(0, 2)) {
                                    already = true;
                                    // Check if detail
                                    if (risks_list[j].code !== industrial[k].code) {
                                        // Add as detail
                                        industrial[k].detail.push(risks_list[j]);
                                    }
                                }
                            }
                        }
                        // Add if not already added
                        if (already === false) {
                            if (risks_list[j].type === "nat") {
                                natural.push(risks_list[j]);
                            }
                            else {
                                industrial.push(risks_list[j]);
                            }
                        }
                        break;
                    }
                }
                if (found === false) {
                    other.push({ type: "", category: "", code: "", libelle: result1.data[0].risques_detail[i].libelle_risque_long, icon: "", detail: [] });
                }
            }
        }
        // Return Fetch 1
        return {
            natural: natural,
            industrial: industrial,
            other: other
        };
    } catch (error) {
        // Error Fetch 1
        console.log("-FETCHING RISKS 1- : Error", error);
        return {
            natural: natural,
            industrial: industrial,
            other: other
        };
    }
}

const get_risk_earthquake = async (center_coord) => {
    // Fetch 2 : API Georisques Zone sismique
    console.log("-FETCHING RISKS 2- : Loading...");
    var earthquake = "error";
    try {
        const res2 = await fetch("https://www.georisques.gouv.fr/api/v1/zonage_sismique?rayon=10&latlon=" + center_coord[0].toString(10) + "%2C" + center_coord[1].toString(10), { "method": "get" });
        const result2 = await res2.json();
        console.log("-FETCHING RISKS 2- : Success", result2);
        if (result2.response_code === 200) {
            earthquake = [];
            for (var i = 0; i < result2.data.length; i++) {
                var code = parseInt(result2.data[i].code_zone);
                var label = "";
                if (code === 1) { label = "Très faible" }
                else if (code === 2) { label = "Faible" }
                else if (code === 3) { label = "Modéré" }
                else if (code === 4) { label = "Moyen" }
                else if (code === 5) { label = "Fort" }
                earthquake.push({
                    code: code,
                    label: label
                });
            }
        }
        // Return Fetch 2
        return earthquake;
    } catch (error) {
        // Error Fetch 2
        console.log("-FETCHING RISKS 2- : Error", error);
        return earthquake;
    }
}

const get_risk_basias = async (center_coord) => {
    // Fetch 3 : API Georisques Basias
    console.log("-FETCHING RISKS 3- : Loading...");
    var basias = "error";
    try {
        const res3 = await fetch("https://www.georisques.gouv.fr/api/v1/sis?rayon=1000&latlon=" + center_coord[0].toString(10) + "%2C" + center_coord[1].toString(10), { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING RISKS 3- : Success", result3);
        if (result3.response_code === 200) {
            basias = result3.data;
        }
        // Return Fetch 3
        return basias;
    } catch (error) {
        // Error Fetch 3
        console.log("-FETCHING RISKS 3- : Error", error);
        return basias;
    }
}

const get_risk_clay = async (center_coord) => {
    // Fetch 4 : API Georisques BRGM Argiles
    console.log("-FETCHING RISKS 4- : Loading...");
    var clay = "error";
    try {
        const res4 = await fetch("https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=ALEARG_XY&propertyname=id&X=" + center_coord[0].toString(10) + "&Y=" + center_coord[1].toString(10) + "&rayon=100&resultType=results");
        const result4 = await res4.text();
        const result4_dom = new window.DOMParser().parseFromString(result4, "text/xml");
        console.log("-FETCHING RISKS 4- : Success", result4_dom);
        const data = result4_dom.getElementsByTagName('ms:id');
        clay = [];
        for (var i = 0; i < data.length; i++) {
            var code = parseInt(data[i].innerHTML);
            var label = "";
            if (code === 0) { label = "A priori nul" }
            else if (code === 1) { label = "Faible" }
            else if (code === 2) { label = "Moyen" }
            else if (code === 3) { label = "Fort" }
            clay.push({
                code: code,
                label: label
            });
        }
        if (clay.length === 0) {
            clay.push({
                code: 0,
                label: "A priori nul"
            });
        }
        // Return Fetch 4
        return clay;
    } catch (error) {
        // Error Fetch 4
        console.log("-FETCHING RISKS 4- : Error", error);
        return clay;
    }
}

const get_risk_pipe = async (center_coord) => {
    // Fetch 5 : API Georisques BRGM Canalisations
    console.log("-FETCHING RISKS 5- : Loading...");
    var pipe = "error";
    try {
        const res5 = await fetch("https://mapsref.brgm.fr/wxs/georisques/rapport?X=" + center_coord[0].toString(10) + "&Y=" + center_coord[1].toString(10) + "&rayon=1000&service=wfs&version=1.0.0&request=getfeature&typename=CANALISATIONS&resultType=results");
        const result5 = await res5.text();
        const result5_dom = new window.DOMParser().parseFromString(result5, "text/xml");
        console.log("-FETCHING RISKS 5- : Success", result5_dom);
        const data = result5_dom.getElementsByTagName('ms:CANALISATIONS');
        pipe = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].getElementsByTagName('ms:longueur')[0].innerHTML !== "0") {
                // Get coordinates
                var pipe_geometry = data[i].getElementsByTagName('ms:msGeometry')[0];
                var pipe_coord_data = pipe_geometry.getElementsByTagName('gml:coordinates')[0].innerHTML;
                var pipe_coord_list = pipe_coord_data.split(" ");
                var pipe_coord = [];
                for (var j = 0; j < pipe_coord_list.length; j++) {
                    if (pipe_coord_list[j] !== "") {
                        var pipe_coord_text = pipe_coord_list[j].split(",");
                        for (var k = 0; k < pipe_coord_text.length; k++) {
                            pipe_coord_text[k] = parseFloat(pipe_coord_text[k]);
                        }
                        pipe_coord.push(pipe_coord_text);
                    }
                }
                // Push to pipe list
                pipe.push({
                    type: data[i].getElementsByTagName('ms:cat_fluide')[0].innerHTML,
                    operator: data[i].getElementsByTagName('ms:transporteur')[0].innerHTML,
                    length: parseInt(data[i].getElementsByTagName('ms:longueur')[0].innerHTML),
                    id: data[i].getElementsByTagName('ms:gid')[0].innerHTML,
                    coordinates: pipe_coord,
                });
            }
        }
        // Return Fetch 5
        return pipe;
    } catch (error) {
        // Error Fetch 5
        console.log("-FETCHING RISKS 5- : Error", error);
        return pipe;
    }
}



//__________ GPU

export const get_gpu = async (landBase) => {
    // var center_coord = union.center.geometry.coordinates;
    var center_coord = landBase.union.center.geometry.coordinates;
    var gpu = {};

    // Fetch 1 : API Carto IGN => Get Zone Urba & Partition
    var zoneUrba = await get_gpu_zoneUrba(landBase);

    // Fetch 2 : API GPU => Get Doc ID from partition
    var docId = await get_gpu_docId(zoneUrba);

    // Fetch 3 : API GPU => Get Doc data from docId
    var document = await get_gpu_document(docId);

    // Fetch 4 : API GPU => Get files list from docId
    var files = await get_gpu_files(docId);
    // Treat files to get label and url
    var files_ordered = "error";
    if (document !== "error" && document?.writingMaterials && files !== "error") {
        files_ordered = {};
        for (var i = 0; i < files.length; i++) {
            // treat path = null
            if (files[i].path === null) {
                if (files[i].title === "Projet d’aménagement et de développement durables") {
                    files[i].path = "Projet d’aménagement et de développement durables";
                    files[i].title = "PADD";
                }
                else {
                    files[i].path = "Autre";
                }
            }
            // Set file label
            var label = files[i].path;
            if (files[i].title !== null && files[i].title !== files[i].path) {
                if (label !== "") { label = label + " : " }
                label = label + files[i].title;
            }
            files[i].label = label;
            // Get url
            var url = null;
            var writingMaterials_list = Object.keys(document.writingMaterials);
            for (var j = 0; j < writingMaterials_list.length; j++) {
                if (files[i].name === writingMaterials_list[j]) {
                    files[i].url = document.writingMaterials[writingMaterials_list[j]];
                    break;
                }
            }
            //_____ Ordering
            if (!Object.keys(files_ordered).includes(files[i].path)) {
                // Add new file group
                files_ordered[files[i].path] = [files[i]];
            }
            else {
                files_ordered[files[i].path].push(files[i]);
            }
        }
    }

    // Sort alphabetically
    if (files_ordered !== "error") {
        var files_ordered_keys = Object.keys(files_ordered);
        for (var i = 0; i < files_ordered_keys.length; i++) {
            files_ordered[files_ordered_keys[i]].sort(function (a, b) {
                var textA = a.title.toUpperCase();
                var textB = b.title.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
    }




    // Update risks
    gpu = {
        zoneUrba: zoneUrba,
        document: document,
        // files: files
        files: files_ordered
    }

    // Send result
    return gpu;
}


const get_gpu_zoneUrba = async (landBase) => {
    console.log("-FETCHING GPU 1- : Loading...");
    var zoneUrba = "error";
    try {
        var url_var = "";
        if (landBase.lands.length <= 1) {
            var center_coord = landBase.union.center.geometry.coordinates;
            url_var = "geom=%7B%22type%22%3A%20%22Point%22%2C%22coordinates%22%3A%5B" + center_coord[0].toString(10) + "%2C" + center_coord[1].toString(10) + "%5D%7D";
        }
        else {
            var list = [];
            for (var i = 0; i < landBase.lands.length; i++) {
                var poly = null;
                if (landBase.lands[i].geometry.type === "Polygon") {
                    poly = turf.polygon(landBase.lands[i].geometry.coordinates);
                }
                else if (landBase.lands[i].geometry.type === "MultiPolygon") {
                    poly = turf.multiPolygon(landBase.lands[i].geometry.coordinates);
                }
                if (poly !== null) {
                    var center = turf.centroid(poly);
                    list.push(center.geometry.coordinates);
                }
                url_var = "geom=%7B%22type%22%3A%20%22LineString%22%2C%22coordinates%22%3A%5B";
                list.forEach((coords, index) => {
                    if (index > 0) {
                        url_var = url_var + "%2C";
                    }
                    url_var = url_var + "%5B" + coords.join("%2C") + "%5D";
                })
                url_var = url_var + "%5D%7D";
            }
            console.log("url_var", url_var);
        }
        // var center_coord = landBase.union.center.geometry.coordinates;
        // const res3 = await fetch("https://apicarto.ign.fr/api/gpu/zone-urba?geom=%7B%22type%22%3A%20%22Point%22%2C%22coordinates%22%3A%5B" + center_coord[0].toString(10) + "%2C" + center_coord[1].toString(10) + "%5D%7D", { "method": "get" });
        const res3 = await fetch("https://apicarto.ign.fr/api/gpu/zone-urba?" + url_var, { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING GPU 1- : Success", result3);
        if (result3?.features.length > 0) {
            zoneUrba = result3.features;
        }
        // Return Fetch
        return zoneUrba;
    } catch (error) {
        // Error Fetch
        console.log("-FETCHING GPU 1- : Error", error);
        return zoneUrba;
    }
}

const get_gpu_docId = async (zoneUrba) => {
    console.log("-FETCHING GPU 2- : Loading...");
    if (zoneUrba === "error") {
        return "error";
    }
    var docId = "error";
    try {
        // const res3 = await fetch("https://www.geoportail-urbanisme.gouv.fr/api/document?partition=" + zoneUrba[0].properties.partition, { "method": "get" });
        const res3 = await fetch("https://apicarto.ign.fr/api/gpu/document?partition=" + zoneUrba[0].properties.partition, { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING GPU 2- : Success", result3);
        if (result3?.features.length > 0 && result3.features[0]?.properties?.id) {
            docId = result3.features[0].properties.id;
        }
        // Return Fetch
        return docId;
    } catch (error) {
        // Error Fetch
        console.log("-FETCHING GPU 2- : Error", error);
        return docId;
    }
}

const get_gpu_document = async (docId) => {
    console.log("-FETCHING GPU 3- : Loading...");
    if (docId === "error") {
        return "error";
    }
    var document = "error";
    try {
        const res3 = await fetch("https://www.geoportail-urbanisme.gouv.fr/api/document/" + docId + "/details", { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING GPU 3- : Success", result3);
        if (result3?.id) {
            document = result3;
        }
        // Return Fetch
        return document;
    } catch (error) {
        // Error Fetch
        console.log("-FETCHING GPU 3- : Error", error);
        return document;
    }
}

const get_gpu_files = async (docId) => {
    console.log("-FETCHING GPU 4- : Loading...");
    if (docId === "error") {
        return "error";
    }
    var files = "error";
    try {
        const res3 = await fetch("https://www.geoportail-urbanisme.gouv.fr/api/document/" + docId + "/files", { "method": "get" });
        const result3 = await res3.json();
        console.log("-FETCHING GPU 4- : Success", result3);
        if (result3.length > 0) {
            files = result3;
        }
        // Return Fetch
        return files;
    } catch (error) {
        // Error Fetch
        console.log("-FETCHING GPU 4- : Error", error);
        return files;
    }
}








// __________ OSM Overpass API

const get_osm_bbox = (capacity, rayon) => {
    const bbox_turf = turf.bbox(turf.circle(capacity.landBase.union.center, (rayon / 1000), { steps: 10 }));
    const bbox = [bbox_turf[1], bbox_turf[0], bbox_turf[3], bbox_turf[2]];
    return bbox
}

const fetchOSMdata_point = async (point_coords, bbox, key_list) => {
    console.log("-FETCHING OSM Point- : Loading...");
    var result = "error";
    try {
        const data = "[timeout:10][out:json];is_in(" + point_coords[1] + "," + point_coords[0] + ")->.a;way(pivot.a);out tags bb;out ids geom(" + bbox.join(",") + ");relation(pivot.a);out tags bb;";
        const response = await fetch('https://www.overpass-api.de/api/interpreter?', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data
        });
        const response_json = await response.json();
        if (response_json?.elements) {
            result = response_json?.elements;
            var results_filtered = {};
            // KEY LIST EXEMPLE : [{label: "arrond", key: "admin_level", value: "9"}, {label: "district", key: "admin_level", value: "10"}, {label: "subdistrict", key: "admin_level", value: "11"}]
            for (var i = 0; i < key_list.length; i++) {
                if (key_list[i]?.label && key_list[i]?.key && key_list[i]?.value) {
                    results_filtered[key_list[i].label] = null;
                    for (var j = 0; j < result.length; j++) {
                        if (result[j]?.tags && Object.keys(result[j].tags).includes(key_list[i].key) && result[j].tags[key_list[i].key] === key_list[i].value) {
                            results_filtered[key_list[i].label] = result[j];
                            break;
                        }
                    }
                }
            }
            result = results_filtered;
        }
        console.log("-FETCHING OSM Point- : SUCCESS", result);
        return result

    } catch (error) {
        console.log("-FETCHING OSM Point- : ERROR", error);
        return "error"
    }
}

const fetchOSMdata_bbox = async (bbox, key_list, capacity, isDeep) => {
    console.log("-FETCHING OSM Bbox- : Loading...");
    var result = "error";
    try {
        const data_keys = key_list.map(key => { return key + "(" + bbox.join(",") + ");" });
        // const data_deep = isDeep ? ";(._;>;)" : "";
        const data_deep = ";(._;>;)";
        const data = "[out:json];(" + data_keys.join("") + ")" + data_deep + ";out;";
        const response = await fetch('https://www.overpass-api.de/api/interpreter?', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: data
        });
        const response_json = await response.json();
        if (response_json?.elements) {
            result = response_json?.elements;
            // POST TREATMENT
            // Arbres 1
            if (key_list.includes("node[natural=tree]")) {
                var result_filtered = [];
                var trees_inside_land = [];
                var trees_less_5m = [];
                result.forEach(res => {
                    if (res?.type === "node" && res?.tags?.natural === "tree" && res?.lon && res?.lat) {
                        var distance = get_distance_point_land([res.lon, res.lat], capacity);
                        result_filtered.push({
                            id: res?.id,
                            coords: [res.lon, res.lat],
                            type: res.type,
                            properties: {
                                distance: distance,
                                height: res?.tags?.height || res?.tags?.est_height || null,
                                diameter: res?.tags?.diameter || null,
                                circumference: res?.tags?.circumference || null,
                            }
                        });
                    }
                })
                for (var i = 0; i < result_filtered.length; i++) {
                    if (result_filtered[i].properties.distance === 0) {
                        trees_inside_land.push(i);
                    }
                    else if (result_filtered[i].properties.distance <= 5) {
                        trees_less_5m.push(i);
                    }
                }
                result = {
                    items: result_filtered,
                    inside: trees_inside_land,
                    near_5m: trees_less_5m
                };
            }
            // Arbres 2
            else if (key_list.includes("nwr[natural=tree_row]")) {
                var result_filtered = [];
                var result_simplified = [];
                var result_splitted = [];
                var result_nodes = {};
                var trees_inside_land = [];
                var trees_less_5m = [];
                result.forEach(res => {
                    if (res?.type === "way" && res?.tags?.natural === "tree_row") {
                        result_filtered.push(res);
                    }
                    if (res?.type === "node" && res?.id && res?.lon && res?.lat) {
                        result_nodes[(res.id).toString()] = res;
                    }
                })
                // Simplify & Add cords
                result_filtered.forEach(res_fil => {
                    var coords = [];
                    res_fil.nodes.forEach(nd_id => {
                        if (Object.keys(result_nodes).includes(nd_id.toString())) {
                            coords.push([result_nodes[nd_id].lon, result_nodes[nd_id].lat]);
                        }
                    })
                    var length = 0;
                    if (coords.length > 1) {
                        var linestring = turf.lineString(coords);
                        length = turf.length(linestring) * 1000;
                        var nb_seg = Math.round(length / 8);
                        var lg_seg = length / nb_seg;
                        var points = [];
                        // Create equidistant points along linestring
                        for (var i = 0; i < (nb_seg + 1); i++) {
                            if (i === 0) {
                                points.push(coords[0]);
                            }
                            else if (i === nb_seg) {
                                points.push(coords[coords.length - 1]);
                            }
                            else {
                                var along = turf.along(linestring, i * (lg_seg / 1000));
                                if (along?.geometry?.coordinates) {
                                    points.push(along.geometry.coordinates);
                                }
                            }
                        }
                        points.forEach((point, point_index) => {
                            var distance = get_distance_point_land(point, capacity);
                            result_splitted.push({
                                id: res_fil?.id + "_" + point_index,
                                coords: point,
                                type: "node",
                                properties: {
                                    distance: distance,
                                    height: res_fil?.tags?.height || res_fil?.tags?.est_height || null,
                                    diameter: res_fil?.tags?.diameter || null,
                                    circumference: res_fil?.tags?.circumference || null,
                                }
                            });
                        })
                    }
                })
                for (var i = 0; i < result_splitted.length; i++) {
                    if (result_splitted[i].properties.distance === 0) {
                        trees_inside_land.push(i);
                    }
                    else if (result_splitted[i].properties.distance <= 5) {
                        trees_less_5m.push(i);
                    }
                }
                result = {
                    items: result_splitted,
                    inside: trees_inside_land,
                    near_5m: trees_less_5m
                };
            }
            // // Parcs & jardin
            // else if (key_list.includes("nwr[leisure=park]")) {
            //     var result_filtered = [];
            //     var result_simplified = [];
            //     var result_nodes = {};
            //     result.forEach(res => {
            //         if (res?.type === "way" && res?.tags?.name && !(res?.tags?.access === "private") && res?.nodes && res.nodes.length > 2) {
            //             result_filtered.push(res);
            //         }
            //         if (res?.type === "node" && res?.id && res?.lon && res?.lat) {
            //             result_nodes[(res.id).toString()] = res;
            //         }
            //     })
            //     // Simplify & Add cords
            //     result_filtered.forEach(res_fil => {
            //         var coords = [];
            //         res_fil.nodes.forEach(nd_id => {
            //             if (Object.keys(result_nodes).includes(nd_id.toString())) {
            //                 coords.push([result_nodes[nd_id].lon, result_nodes[nd_id].lat]);
            //             }
            //         })
            //         // Close coords if needed
            //         if (coords.length > 0 && (coords[0][0] !== coords[coords.length - 1][0] || coords[0][1] !== coords[coords.length - 1][1])) {
            //             coords.push(coords[0]);
            //         }
            //         result_simplified.push({
            //             id: res_fil?.id,
            //             coords: coords,
            //             type: res_fil?.type,
            //             properties: { name: res_fil?.tags?.name || "" },
            //         })
            //     })
            //     result = { items: result_simplified };
            // }
            // // Santé
            // else if (key_list.includes("node[healthcare=clinic]")) {
            //     var result_filtered = {
            //         clinic: [],
            //         doctor: [],
            //         hospital: [],
            //         laboratory: [],
            //         nurse: [],
            //         pharmacy: [],
            //     };
            //     result.forEach(res => {
            //         if (res?.tags?.healthcare === "dentist") {
            //             res.tags.healthcare = "doctor";
            //             res.tags["healthcare:speciality"] = "dentist";
            //         }
            //         if (res?.type === "node" && res?.tags?.healthcare && res?.tags?.name && res?.lon && res?.lat && Object.keys(result_filtered).includes(res.tags.healthcare)) {
            //             var spe = null;
            //             if (res?.tags && Object.keys(res.tags).includes("healthcare:speciality")) {
            //                 spe = res.tags["healthcare:speciality"];
            //             }
            //             result_filtered[res.tags.healthcare].push({
            //                 id: res?.id,
            //                 coords: [res.lon, res.lat],
            //                 type: res.type,
            //                 properties: {
            //                     name: res?.tags?.name || "",
            //                     speciality: spe
            //                 },
            //             });
            //         }
            //     })
            //     result = { items: result_filtered };
            // }
            // // Bornes
            // else if (key_list.includes("node[amenity=charging_station]")) {
            //     var result_filtered = [];
            //     result.forEach(res => {
            //         if (res?.type === "node" && res?.lon && res?.lat) {
            //             result_filtered.push({
            //                 id: res?.id,
            //                 coords: [res.lon, res.lat],
            //                 type: res.type,
            //                 properties: {
            //                     access: res?.tags?.access || null,
            //                     slots: res?.tags?.capacity || null,
            //                     fee: res?.tags?.fee || null,
            //                 }
            //             });
            //         }
            //     })
            //     result = { items: result_filtered };
            // }
            // // Vélos
            // else if (key_list.includes("node[bicycle_rental=docking_station]")) {
            //     var result_filtered = [];
            //     result.forEach(res => {
            //         if (res?.type === "node" && res?.tags?.name && res?.lon && res?.lat) {
            //             var distance = get_distance_point_land([res.lon, res.lat], capacity);
            //             result_filtered.push({
            //                 id: res?.id,
            //                 coords: [res.lon, res.lat],
            //                 type: res.type,
            //                 properties: {
            //                     name: res?.tags?.name || null,
            //                     distance: distance,
            //                     slots: res?.tags?.capacity ? parseInt(res.tags.capacity) : null,
            //                     network: res?.tags?.network || null,
            //                     operator: res?.tags?.operator || null,
            //                 }
            //             });
            //         }
            //     })
            //     var result_sorted = result_filtered.sort(function (a, b) {
            //         let x = a.properties.distance;
            //         let y = b.properties.distance;
            //         if (x > y) { return 1; }
            //         if (x < y) { return -1; }
            //         return 0;
            //     });
            //     result = { items: result_sorted };
            // }
            // // Gare
            // else if (key_list.includes("node[railway=station]")) {
            //     var result_filtered = [];
            //     result.forEach(res => {
            //         if (res?.type === "node" && res?.tags?.name && res?.lon && res?.lat && res?.tags?.train === "yes") {
            //             var distance = get_distance_point_land([res.lon, res.lat], capacity);
            //             result_filtered.push({
            //                 id: res?.id,
            //                 coords: [res.lon, res.lat],
            //                 type: res.type,
            //                 properties: {
            //                     name: res?.tags?.name || null,
            //                     distance: distance,
            //                     operator: res?.tags?.operator || null,
            //                 }
            //             });
            //         }
            //     })
            //     var result_sorted = result_filtered.sort(function (a, b) {
            //         let x = a.properties.distance;
            //         let y = b.properties.distance;
            //         if (x > y) { return 1; }
            //         if (x < y) { return -1; }
            //         return 0;
            //     });
            //     result = { items: result_sorted };
            // }
            // // Parkings
            // else if (key_list.includes("nwr[amenity=parking]")) {
            //     var result_filtered = [];
            //     var result_simplified = [];
            //     var result_nodes = {};
            //     var total_slots = 0;
            //     result.forEach(res => {
            //         if (res?.type === "way" && !(res?.tags?.access === "private") && res?.nodes && res.nodes.length > 2) {
            //             result_filtered.push(res);
            //         }
            //         if (res?.type === "node" && res?.id && res?.lon && res?.lat) {
            //             result_nodes[(res.id).toString()] = res;
            //         }
            //     })
            //     // Simplify & Add cords
            //     result_filtered.forEach(res_fil => {
            //         var coords = [];
            //         var area = null;
            //         var est_slots = null;
            //         res_fil.nodes.forEach(nd_id => {
            //             if (Object.keys(result_nodes).includes(nd_id.toString())) {
            //                 coords.push([result_nodes[nd_id].lon, result_nodes[nd_id].lat]);
            //             }
            //         })
            //         // Close coords if needed
            //         if (coords.length > 0 && (coords[0][0] !== coords[coords.length - 1][0] || coords[0][1] !== coords[coords.length - 1][1])) {
            //             coords.push(coords[0]);
            //         }
            //         if (coords.length > 0 && coords[0][0] === coords[coords.length - 1][0] && coords[0][1] === coords[coords.length - 1][1]) {
            //             area = turf.area(turf.polygon([coords]));
            //             est_slots = Math.max(Math.round(area / 28), 1);
            //         }
            //         result_simplified.push({
            //             id: res_fil?.id,
            //             coords: coords,
            //             type: res_fil?.type,
            //             properties: {
            //                 access: res_fil?.tags?.access || null,
            //                 category: res_fil?.tags?.parking || null,
            //                 slots: res_fil?.tags?.capacity ? parseInt(res_fil?.tags?.capacity) : est_slots,
            //                 fee: res_fil?.tags?.fee || null,
            //                 area: area
            //             },
            //         })
            //         total_slots = 0;
            //         result_simplified.forEach(item => {
            //             total_slots = total_slots + (item?.properties?.slots || 0)
            //         })
            //     })
            //     result = { items: result_simplified, total_slots };
            // }
            // // Education
            // else if (key_list.includes("node[amenity=college]")) {
            //     var result_filtered = {
            //         kindergarten: [],
            //         maternel: [],
            //         elementary: [],
            //         primary: [],
            //         medschool: [],
            //         highschool: [],
            //         university: [],
            //         music_school: [],
            //         language_school: []
            //     };
            //     result.forEach(res => {
            //         if (res?.tags?.amenity === "school") {
            //             if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "maternelle") || (res?.tags?.name && res.tags.name.toLowerCase().includes("maternelle"))) {
            //                 res.tags.amenity = "maternel";
            //             }
            //             else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "élémentaire") || (res?.tags?.name && res.tags.name.toLowerCase().includes("élémentaire"))) {
            //                 res.tags.amenity = "elementary";
            //             }
            //             else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "primaire") || (res?.tags?.name && res.tags.name.toLowerCase().includes("primaire"))) {
            //                 res.tags.amenity = "primary";
            //             }
            //             else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "collège") || (res?.tags?.name && res.tags.name.toLowerCase().includes("collège"))) {
            //                 res.tags.amenity = "medschool";
            //             }
            //             else if ((Object.keys(res.tags).includes("school:FR") && res.tags["school:FR"] === "lycée") || (res?.tags?.name && res.tags.name.toLowerCase().includes("lycée"))) {
            //                 res.tags.amenity = "highschool";
            //             }
            //         }
            //         else if (res?.tags?.amenity === "college") {
            //             res.tags.amenity = "university";
            //         }
            //         if (res?.type === "node" && res?.tags?.amenity && res?.tags?.name && res?.lon && res?.lat && Object.keys(result_filtered).includes(res.tags.amenity)) {
            //             var operator = null;
            //             if ((Object.keys(res.tags).includes("operator:type") && res.tags["operator:type"] === "public") || (res?.tags?.name && (res.tags.name.toLowerCase().includes("public") || res.tags.name.toLowerCase().includes("publique")))) {
            //                 operator = "public";
            //             }
            //             else if ((Object.keys(res.tags).includes("operator:type") && res.tags["operator:type"] === "private") || (res?.tags?.name && (res.tags.name.toLowerCase().includes("privé") || res.tags.name.toLowerCase().includes("privée")))) {
            //                 operator = "private";
            //             }
            //             result_filtered[res.tags.amenity].push({
            //                 id: res?.id,
            //                 coords: [res.lon, res.lat],
            //                 type: res.type,
            //                 properties: {
            //                     name: res?.tags?.name || "",
            //                     operator: operator
            //                 },
            //             });
            //         }
            //     })
            //     result = { items: result_filtered };
            // }
            // // Transport
            // else if (key_list.includes("relation[route=bus]")) {
            //     var result_filtered = [];
            //     var result_nodes = {};
            //     result.forEach(res => {
            //         if (res?.type === "relation") {
            //             var members_filtered = [];
            //             res.members.forEach(mem => {
            //                 if (mem?.type === "node" && mem?.ref && mem?.role && (mem.role.includes("platform") || mem.role.includes("stop"))) {
            //                     members_filtered.push(mem.ref);
            //                 }
            //             })
            //             res.nodes = members_filtered;
            //             result_filtered.push(res);
            //         }
            //         // Get nodes
            //         else if (res?.type === "node" && res?.id && res?.tags?.name && res?.lon && res?.lat) {
            //             result_nodes[(res.id).toString()] = res;
            //         }
            //     })
            //     // Simplify & Add cords
            //     var result_simplified = [];
            //     var result_ref = {};
            //     var result_counter = -1;
            //     result_filtered.forEach(res_fil => {
            //         var stops = [];
            //         var nearest_stop = null;
            //         var counter = -1;
            //         res_fil.nodes.forEach(nd_id => {
            //             if (Object.keys(result_nodes).includes(nd_id.toString())) {
            //                 counter++;
            //                 var distance = get_distance_point_land([result_nodes[nd_id].lon, result_nodes[nd_id].lat], capacity);
            //                 stops.push({
            //                     id: nd_id,
            //                     coords: [result_nodes[nd_id].lon, result_nodes[nd_id].lat],
            //                     distance: distance,
            //                     name: result_nodes[nd_id]?.tags?.name || "",
            //                     level: result_nodes[nd_id]?.tags?.level || null,
            //                     // tags: result_nodes[nd_id]?.tags,
            //                 });
            //                 if (nearest_stop === null || distance < stops[nearest_stop]?.distance) {
            //                     nearest_stop = counter;
            //                 }
            //             }
            //         })
            //         if (nearest_stop !== null && stops[nearest_stop]?.distance <= 500) {
            //             result_counter++;
            //             result_simplified.push({
            //                 id: res_fil?.id,
            //                 stops: stops,
            //                 nearest_stop: nearest_stop,
            //                 type: res_fil?.type,
            //                 properties: {
            //                     name: res_fil?.tags?.name || null,
            //                     ref: res_fil?.tags?.ref || null,
            //                     color: res_fil?.tags?.colour || null,
            //                     color_txt: res_fil?.tags?.text_colour || null,
            //                     category: res_fil?.tags?.route || null,
            //                 },
            //                 // tags: res_fil?.tags || null,
            //             })
            //             // Get ref
            //             var ref = res_fil?.tags?.ref + "__" + res_fil?.tags?.operator;
            //             if (res_fil?.tags?.ref && !Object.keys(result_ref).includes(ref)) {
            //                 result_ref[ref] = [result_counter];
            //             }
            //             else if (res_fil?.tags?.ref && Object.keys(result_ref).includes(ref)) {
            //                 result_ref[ref].push(result_counter);
            //             }
            //         }
            //     })
            //     // Aggregate by ref
            //     var result_aggregated = [];
            //     Object.keys(result_ref).forEach(ref_key => {
            //         var result_ref_item = result_ref[ref_key];
            //         if (result_ref_item.length > 0) {
            //             var line0 = result_simplified[result_ref_item[0]];
            //             var name = null;
            //             if (result_ref_item.length > 1) {
            //                 name = get_matching_substring(line0?.properties?.name, result_simplified[result_ref_item[1]]?.properties?.name);
            //             }
            //             if (name === null) {
            //                 name = (line0?.properties?.category === "bus" ? "Bus " : line0?.properties?.category === "subway" ? "Métro " : line0?.properties?.category === "tram" ? "Tram " : "") + (line0?.properties?.ref || "");
            //             }
            //             var lines = [];
            //             var nearest_stop = null;
            //             result_ref_item.forEach((line_index, i) => {
            //                 lines.push(result_simplified[line_index]);
            //                 if (nearest_stop === null || nearest_stop.distance > result_simplified[line_index].stops[result_simplified[line_index]["nearest_stop"]]?.distance) {
            //                     nearest_stop = {
            //                         line_i: i,
            //                         distance: result_simplified[line_index].stops[result_simplified[line_index]["nearest_stop"]]?.distance,
            //                         name: result_simplified[line_index].stops[result_simplified[line_index]["nearest_stop"]]?.name,
            //                     }
            //                 }
            //             })
            //             result_aggregated.push({
            //                 ref: line0?.properties?.ref || "",
            //                 name: name,
            //                 color: line0?.properties?.color || null,
            //                 color_txt: line0?.properties?.color_txt || null,
            //                 category: line0?.properties?.category || null,
            //                 lines: lines,
            //                 nearest_stop: nearest_stop
            //             })
            //         }
            //     })
            //     var result_categorized = {
            //         bus: [],
            //         subway: [],
            //         tram: [],
            //         other: []
            //     }
            //     result_aggregated.forEach(aggreg => {
            //         if (aggreg?.category && Object.keys(result_categorized).includes(aggreg?.category)) {
            //             result_categorized[aggreg.category].push(aggreg);
            //         }
            //         else {
            //             result_categorized["other"].push(aggreg);
            //         }
            //     })
            //     Object.keys(result_categorized).forEach(cat => {
            //         var result_sorted = result_categorized[cat].sort(function (a, b) {
            //             let x = a.nearest_stop.distance;
            //             let y = b.nearest_stop.distance;
            //             if (x > y) { return 1; }
            //             if (x < y) { return -1; }
            //             return 0;
            //         });
            //         result_categorized[cat] = result_sorted;
            //     })
            //     result = { items: result_categorized };
            // }
        }
        console.log("-FETCHING OSM Bbox- : SUCCESS", result);
        return result
    } catch (error) {
        console.log("-FETCHING OSM Bbox- : ERROR", error);
        return "error"
    }
}

const get_distance_point_land = (point_coords, capacity) => {
    if (point_coords.length === 2 && capacity?.landBase?.union?.geometry?.geometry?.type === "Polygon" && capacity?.landBase?.union?.outerLine?.geometry?.type === "LineString") {
        var isInside = turf.booleanPointInPolygon(turf.point(point_coords), capacity?.landBase?.union?.geometry);
        var distance = 0;
        if (isInside === false) {
            // distance = turf.distance(turf.point([res.lon, res.lat]), turf.point(point_coords)) * 1000;
            distance = Math.round(turf.pointToLineDistance(turf.point(point_coords), capacity?.landBase?.union?.outerLine) * 1000);
        }
        return distance
    }
    else {
        return null
    }
}

const get_matching_substring = (string1, string2) => {
    var list1 = string1.split(" ");
    var list2 = string2.split(" ");

    var matching_text = null;
    var matching_list = [];
    for (var i = 0; i < list1.length; i++) {
        if (list1[i].toLowerCase() === "direction") {
            break
        }
        else if (list1[i] === list2[i]) {
            matching_list.push(list1[i]);
        }
        else {
            break
        }
    }
    if (matching_list.length > 0) {
        matching_text = matching_list.join(" ");
    }

    return matching_text
}