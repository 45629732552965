import * as turf from '@turf/turf';
import * as THREE from 'three';
import mapboxgl from 'mapbox-gl';
import { SkeletonBuilder } from 'straight-skeleton';
import * as map_helpers from '../Map_helpers';




const sources_ids = ['sourceCombinationsSelected'];
const layers_ids = ['layerCombinationsSelected'];

export const get_ids = (type) => {
    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })

    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    var sources_layers = {
        sources: sources,
        layers: layers
    }

    return sources_layers
}

export const get_data = (capacity, highlightedCombination) => {

    var map_data = {
        sources: [],
        layers: []
    }


    // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // 3D
    // Set point 0
    var point0 = capacity.landBase.union.bbox.origin;
    // Georeferencing
    var modelOrigin = point0;
    var modelAltitude = 0;
    var modelRotate = [0, 0, 0];
    var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
    );
    // Set position, rotation and scale
    var modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    };
    // Set THREE
    var customLayer = {
        id: layers_ids[0],
        type: 'custom',
        source: sources_ids[0],
        renderingMode: '3d',
        onAdd: function (map, gl) {
            this.camera = new THREE.Camera();
            this.scene = new THREE.Scene();
            // Create lights
            var directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.5);
            // directionalLight1.position.set(-100, 20, -100).normalize();
            directionalLight1.position.x = -10;
            directionalLight1.position.y = -10;
            directionalLight1.position.z = 20;
            this.scene.add(directionalLight1);

            var directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
            // directionalLight1.position.set(-100, 20, -100).normalize();
            directionalLight2.position.x = 10;
            directionalLight2.position.y = 10;
            directionalLight2.position.z = 20;
            this.scene.add(directionalLight2);

            var ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
            this.scene.add(ambientLight);


            // Loop on each combi selected
            highlightedCombination.forEach((highlightedCombination_item, highlightedCombination_index) => {

                const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
                var combi_group_id = 0;
                var combi_id = 0;
                var indexOf_raise = highlightedCombination_item.id.indexOf("raise");
                var indexOf_zone = highlightedCombination_item.id.indexOf("-");
                if (indexOf_raise > -1) {
                    combi_group_id = highlightedCombination_item.id.substring(6, indexOf_zone);
                    combi_id = highlightedCombination_item.id.substring(indexOf_zone + 1);
                }
                else if (indexOf_zone > -1) {
                    combi_group_id = letters.indexOf(highlightedCombination_item.id.substring(0, indexOf_zone));
                    combi_id = highlightedCombination_item.id.substring(indexOf_zone + 1);
                }
                else {
                    combi_id = highlightedCombination_item.id;
                }
                var combination = capacity?.buildable?.combinations[combi_group_id][combi_id];

                // Create test elements
                // if (combination?.test?.skeletonLine?.lines) {


                //     combination?.test?.skeletonLine?.lines.forEach(line => {

                //         if (line?.geometry?.type === "LineString") {
                //             var points = [];

                //             // Get local coordinates
                //             var building_coords_global = line.geometry.coordinates;
                //             var building_coords_local = [];
                //             for (var i = 0; i < building_coords_global.length; i++) {
                //                 var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                //                 if (building_coords_global[i][0] < point0[0]) {
                //                     deltaX = -deltaX;
                //                 }
                //                 var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                //                 if (building_coords_global[i][1] < point0[1]) {
                //                     deltaY = -deltaY;
                //                 }
                //                 building_coords_local.push([deltaX, deltaY]);
                //             }
                //             for (var i = 0; i < building_coords_local.length; i++) {
                //                 points.push( new THREE.Vector3( building_coords_local[i][0], building_coords_local[i][1], 0 ) );
                //             }

                //             const geometry = new THREE.BufferGeometry().setFromPoints( points );

                //             const material = new THREE.LineBasicMaterial({
                //                 color: 0x0000ff
                //             });

                //             const line_mesh = new THREE.Line( geometry, material );
                //             this.scene.add(line_mesh);
                //         }
                //     })

                // }

                // if (combination?.test?.skeletonLine?.buffers) {

                //     combination?.test?.skeletonLine?.buffers.forEach(buffer => {

                //         if (buffer.geometry.type === "Polygon") {

                //             // Get local coordinates
                //             var building_coords_global = buffer.geometry.coordinates[0];
                //             var building_coords_local = [];
                //             for (var i = 0; i < building_coords_global.length; i++) {
                //                 var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                //                 if (building_coords_global[i][0] < point0[0]) {
                //                     deltaX = -deltaX;
                //                 }
                //                 var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                //                 if (building_coords_global[i][1] < point0[1]) {
                //                     deltaY = -deltaY;
                //                 }
                //                 building_coords_local.push([deltaX, deltaY]);
                //             }

                //             // Create Shape
                //             var building_shape = new THREE.Shape();
                //             building_shape.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                //             for (var i = 0; i < building_coords_local.length; i++) {
                //                 building_shape.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                //             }

                //             // Create Shape Holes
                //             if (buffer.geometry.coordinates.length > 1) {
                //                 for (var hole_index = 1; hole_index < buffer.geometry.coordinates.length; hole_index++) {
                //                     // Get local coordinates
                //                     var building_coords_global = buffer.geometry.coordinates[hole_index];
                //                     var building_coords_local = [];
                //                     for (var i = 0; i < building_coords_global.length; i++) {
                //                         var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                //                         if (building_coords_global[i][0] < point0[0]) {
                //                             deltaX = -deltaX;
                //                         }
                //                         var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                //                         if (building_coords_global[i][1] < point0[1]) {
                //                             deltaY = -deltaY;
                //                         }
                //                         building_coords_local.push([deltaX, deltaY]);
                //                     }

                //                     var building_shape_hole = new THREE.Shape();
                //                     building_shape_hole.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                //                     for (var i = 0; i < building_coords_local.length; i++) {
                //                         building_shape_hole.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                //                     }
                //                     building_shape.holes.push(building_shape_hole);
                //                 }
                //             }

                //             // Create geometry
                //             var building_extrude_settings = {
                //                 steps: 1,
                //                 depth: 0.2,
                //                 bevelEnabled: false
                //             };
                //             var building_geometry = new THREE.ExtrudeGeometry(building_shape, building_extrude_settings);

                //             // Create mesh
                //             var building_material = new THREE.MeshPhongMaterial({
                //                 color: '#F1F1F1',
                //                 transparent: true,
                //                 opacity: 0.7,
                //                 side: THREE.DoubleSide
                //             });
                //             var building_mesh = new THREE.Mesh(building_geometry, building_material);

                //             // Add mesh to scene
                //             this.scene.add(building_mesh);

                //         }


                //     })

                // }

                // if (combination?.test?.skeletonLine?.bufferLines) {

                //     combination?.test?.skeletonLine?.bufferLines.forEach(line => {

                //         if (line?.geometry?.type === "LineString") {
                //             var points = [];

                //             // Get local coordinates
                //             var building_coords_global = line.geometry.coordinates;
                //             var building_coords_local = [];
                //             for (var i = 0; i < building_coords_global.length; i++) {
                //                 var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                //                 if (building_coords_global[i][0] < point0[0]) {
                //                     deltaX = -deltaX;
                //                 }
                //                 var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                //                 if (building_coords_global[i][1] < point0[1]) {
                //                     deltaY = -deltaY;
                //                 }
                //                 building_coords_local.push([deltaX, deltaY]);
                //             }
                //             for (var i = 0; i < building_coords_local.length; i++) {
                //                 points.push( new THREE.Vector3( building_coords_local[i][0], building_coords_local[i][1], 0 ) );
                //             }

                //             const geometry = new THREE.BufferGeometry().setFromPoints( points );

                //             const material = new THREE.LineBasicMaterial({
                //                 color: 0xff0000
                //             });

                //             const line_mesh = new THREE.Line( geometry, material );
                //             this.scene.add(line_mesh);
                //         }
                //     })

                // }


                // Create buildings
                // if (combination?.buildings && combination?.buildings.length > 1) {
                if (combination?.buildings && combination?.buildings.length > 0) {
                    // console.log("ERROR no buildings on combination", combination);
                    // return

                    for (var building_index = 0; building_index < combination.buildings.length; building_index++) {
                        var building = combination.buildings[building_index];

                        // Check if levels exists
                        if (!building?.levels || building?.levels.length <= 0) {
                            console.log("ERROR no levels on building", building);
                            break;
                        }

                        // Loop th all levels of building
                        for (var level_index = 0; level_index < building.levels.length; level_index++) {
                            var level = building.levels[level_index];

                            // Check if polygon is type "Polygon"
                            if (!level?.polygon || !(level?.polygon?.geometry?.type === "Polygon" || level?.polygon?.geometry?.type === "MultiPolygon")) {
                                console.log("ERROR no polygon on level", level);
                                break;
                            }

                            var polygons = [level.polygon.geometry.coordinates];
                            if (level?.polygon?.geometry?.type === "MultiPolygon") {
                                polygons = level.polygon.geometry.coordinates;
                            }

                            polygons.forEach(polygon_coords => {

                                // Get local coordinates
                                var building_coords_global = polygon_coords[0];
                                var building_coords_local = [];
                                for (var i = 0; i < building_coords_global.length; i++) {
                                    var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                                    if (building_coords_global[i][0] < point0[0]) {
                                        deltaX = -deltaX;
                                    }
                                    var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                                    if (building_coords_global[i][1] < point0[1]) {
                                        deltaY = -deltaY;
                                    }
                                    building_coords_local.push([deltaX, deltaY]);
                                }

                                // _____ Create mesh
                                var building_shape = new THREE.Shape();
                                building_shape.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                                for (var i = 0; i < building_coords_local.length; i++) {
                                    building_shape.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                                }

                                // _____ Create Shape Holes
                                if (polygon_coords.length > 1) {
                                    for (var hole_index = 1; hole_index < polygon_coords.length; hole_index++) {
                                        // Get local coordinates
                                        var building_coords_global = polygon_coords[hole_index];
                                        var building_coords_local = [];
                                        for (var i = 0; i < building_coords_global.length; i++) {
                                            var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                                            if (building_coords_global[i][0] < point0[0]) {
                                                deltaX = -deltaX;
                                            }
                                            var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                                            if (building_coords_global[i][1] < point0[1]) {
                                                deltaY = -deltaY;
                                            }
                                            building_coords_local.push([deltaX, deltaY]);
                                        }

                                        var building_shape_hole = new THREE.Shape();
                                        building_shape_hole.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                                        for (var i = 0; i < building_coords_local.length; i++) {
                                            building_shape_hole.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                                        }
                                        building_shape.holes.push(building_shape_hole);
                                    }
                                }

                                var building_elevation = level.elevations[0];
                                // var building_elevation = 0;
                                // if (level_index > 0) {
                                //     building_elevation = building.levels[level_index - 1].elevation;
                                // }
                                // If raise and level 0 => adapt to existing height
                                if (building?.parameters?.height_raise > 0 && level_index === 0) {
                                    building_elevation = building.parameters.height_raise;
                                }

                                var building_height = level.elevations[1] - building_elevation;
                                // var building_height = level.elevation - building_elevation;

                                var building_extrude_settings = {
                                    steps: 1,
                                    depth: building_height,
                                    bevelEnabled: false
                                };
                                var building_geometry = new THREE.ExtrudeGeometry(building_shape, building_extrude_settings);
                                if (building_elevation > 0) {
                                    building_geometry.translate(0, 0, building_elevation);
                                }
                                var building_opacity = 0.2;
                                if (highlightedCombination_item.section === null || highlightedCombination_item.section === highlightedCombination_item.id) {
                                    var building_opacity = 1;
                                }
                                var building_material = new THREE.MeshPhongMaterial({
                                    color: '#F1F1F1',
                                    transparent: building_opacity === 1 ? false : true,
                                    opacity: building_opacity,
                                    side: THREE.DoubleSide,
                                    // depthWrite: false
                                });
                                var building_mesh = new THREE.Mesh(building_geometry, building_material);
                                this.scene.add(building_mesh);
                                var building_mesh_edges = new THREE.LineSegments(new THREE.EdgesGeometry(building_mesh.geometry), new THREE.LineBasicMaterial({
                                    color: 'grey',
                                    transparent: true,
                                    opacity: building_opacity
                                }));
                                this.scene.add(building_mesh_edges);


                                // _____ Create roof
                                if (level_index === building.levels.length - 1 && capacity?.buildable?.volume?.parameters?.roof_angle > 0) {
                                    try {
                                        var skeleton_coords = [...building_coords_local];
                                        skeleton_coords.splice(-1);
                                        // SKELETON
                                        const skeleton = SkeletonBuilder.BuildFromGeoJSON([[
                                            skeleton_coords,
                                            // [[0, 0], [100, 0], [100, 50], [0, 50]],    // outer
                                            // [[50, 30], [70, 30], [70, 20], [50, 20]],  // inner
                                        ]]);
                                        // console.log("skeleton", skeleton);

                                        // Get skeletyon points list
                                        var angle = capacity?.buildable?.volume?.parameters?.roof_angle;
                                        var skeleton_points = [];
                                        var point_id = 0;
                                        var elevation_max = 0;
                                        for (const [pointPos, distance] of skeleton.Distances) {
                                            var coords_local = [pointPos.X, pointPos.Y];
                                            var coords_global = map_helpers.local_to_mercator(coords_local, capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)
                                            var turf_point = turf.point(coords_global);
                                            var elevation_new = building.levels[level_index].elevations[1] + ((angle / 45) * distance);
                                            turf_point.properties.id = point_id;
                                            turf_point.properties.elevation = elevation_new;
                                            turf_point.properties.coords_local = coords_local;
                                            skeleton_points.push(turf_point);

                                            if (elevation_new > elevation_max) {
                                                elevation_max = elevation_new;
                                            }

                                            point_id++;
                                        }
                                        // console.log("skeleton_points", skeleton_points);

                                        // Get skeleton polygons
                                        var skeleton_polys = [];
                                        for (const edgeResult of skeleton.Edges) {
                                            const poly = edgeResult.Polygon;
                                            var skeleton_poly_points = [];
                                            for (var sk_point_index = 0; sk_point_index < poly.length; sk_point_index++) {
                                                // Get point id
                                                var sk_poly_id = null;
                                                for (var sk_pointlist_index = 0; sk_pointlist_index < skeleton_points.length; sk_pointlist_index++) {
                                                    if (poly[sk_point_index].X === skeleton_points[sk_pointlist_index].properties.coords_local[0] && poly[sk_point_index].Y === skeleton_points[sk_pointlist_index].properties.coords_local[1]) {
                                                        sk_poly_id = sk_pointlist_index;
                                                        break;
                                                    }
                                                }
                                                skeleton_poly_points.push(sk_poly_id);
                                            }
                                            var skeleton_poly = {
                                                points: skeleton_poly_points,
                                            }
                                            skeleton_polys.push(skeleton_poly);
                                        }
                                        // console.log("skeleton_polys", skeleton_polys);

                                        // Get grid
                                        var skeleton_tin = [];
                                        skeleton_polys.forEach(poly => {
                                            var points_list = [];
                                            var coords_global = [];
                                            poly.points.forEach(point_id => {
                                                if (point_id !== null) {
                                                    points_list.push(skeleton_points[point_id]);
                                                    coords_global.push(skeleton_points[point_id].geometry.coordinates);
                                                }
                                            })
                                            coords_global.push(coords_global[0]);
                                            if (points_list.length >= 3) {
                                                var skeleton_polygon = turf.polygon([coords_global]);
                                                var tin = turf.tin({ features: points_list }, "id");
                                                // Check if poly is inside skeleton polygon
                                                var tin_filtered = [];
                                                for (var tin_index = 0; tin_index < tin.features.length; tin_index++) {
                                                    var centroid = turf.centroid(tin.features[tin_index]);
                                                    var isInside = turf.booleanPointInPolygon(centroid, skeleton_polygon);
                                                    if (isInside === true) {
                                                        tin_filtered.push(tin.features[tin_index]);
                                                    }
                                                }
                                                skeleton_tin = skeleton_tin.concat(tin_filtered);
                                            }
                                        })
                                        // console.log("skeleton_tin", skeleton_tin);

                                        // Create 3D shape
                                        var vertices_list = [];
                                        for (var i = 0; i < skeleton_tin.length; i++) {
                                            // Vertice a
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.coords_local[0]);
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.coords_local[1]);
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.elevation);
                                            // Vertice b
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.coords_local[0]);
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.coords_local[1]);
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.elevation);
                                            // Vertice c
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.coords_local[0]);
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.coords_local[1]);
                                            vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.elevation);
                                        }
                                        // console.log("vertices_list", vertices_list);
                                        var vertices = new Float32Array(vertices_list);
                                        // Set vertices to geometry
                                        var geometry = new THREE.BufferGeometry();
                                        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                                        // Compute vertex normals
                                        geometry.computeVertexNormals();
                                        // Create material
                                        var material = new THREE.MeshPhongMaterial({
                                            color: '#F1F1F1',
                                            side: THREE.DoubleSide,
                                            transparent: building_opacity === 1 ? false : true,
                                            opacity: building_opacity,
                                        });
                                        // Create mesh
                                        var mesh = new THREE.Mesh(geometry, material);
                                        // console.log("mesh", mesh);

                                        // Check if higher than buildabale max height
                                        // if (elevation_max > capacity?.buildable?.volume?.parameters?.max_height + 0.02) {
                                        // }
                                        // else {
                                        this.scene.add(mesh);
                                        // Create edges
                                        var edgesGeometry = new THREE.EdgesGeometry(mesh.geometry);
                                        var edgesMesh = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color: "grey", transparent: true, opacity: building_opacity }));
                                        // console.log("edgesMesh", edgesMesh);
                                        this.scene.add(edgesMesh);
                                        // }

                                    } catch (error) {
                                        console.log("ERROR CREATING ROOF", error);
                                    }
                                }


                            })


                        }
                    }
                }


                // Create underground
                var isUgVisible = true;
                for (var hl_i = 0; hl_i < highlightedCombination.length; hl_i++) {
                    if (highlightedCombination[hl_i].section !== -1) {
                        isUgVisible = false;
                        break
                    }
                }

                if (isUgVisible === true && combination?.undergrounds && combination?.undergrounds.length > 0) {

                    // Create land
                    if (highlightedCombination_index === 0) {

                        // Check if land is polygon
                        if (capacity?.landBase?.union?.geometry?.geometry?.type === "Polygon") {

                            // Get local coordinates
                            var building_coords_global = capacity.landBase.union.geometry.geometry.coordinates[0];
                            var building_coords_local = [];
                            for (var i = 0; i < building_coords_global.length; i++) {
                                var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                                if (building_coords_global[i][0] < point0[0]) {
                                    deltaX = -deltaX;
                                }
                                var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                                if (building_coords_global[i][1] < point0[1]) {
                                    deltaY = -deltaY;
                                }
                                building_coords_local.push([deltaX, deltaY]);
                            }

                            // Create mesh
                            var building_shape = new THREE.Shape();
                            building_shape.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                            for (var i = 0; i < building_coords_local.length; i++) {
                                building_shape.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                            }
                            var building_extrude_settings = {
                                steps: 1,
                                depth: 0.1,
                                bevelEnabled: false
                            };
                            // var building_geometry = new THREE.ExtrudeGeometry(building_shape, building_extrude_settings);
                            var building_geometry = new THREE.ShapeGeometry(building_shape);
                            var building_material = new THREE.MeshPhongMaterial({
                                color: '#062134',
                                transparent: true,
                                opacity: 0.4,
                                side: THREE.DoubleSide,
                                depthWrite: false
                            });
                            var building_mesh = new THREE.Mesh(building_geometry, building_material);
                            this.scene.add(building_mesh);

                            // Create edges
                            var building_mesh_edges = new THREE.LineSegments(new THREE.EdgesGeometry(building_mesh.geometry), new THREE.LineBasicMaterial({
                                color: "#062134",
                                linewidth: 12,
                            }));
                            this.scene.add(building_mesh_edges);

                        }

                    }


                    // Loop th all undergrounds
                    combination.undergrounds.forEach(underground => {

                        // Loop th all levels
                        for (var level_index = 0; level_index < underground.levels.length; level_index++) {
                            var level = underground.levels[level_index];

                            // Get polygon list
                            var ug_polygons_coords = [];
                            if (level.polygon.geometry.type === "Polygon") {
                                ug_polygons_coords.push(level.polygon.geometry.coordinates);
                            }
                            else if (level.polygon.geometry.type === "MultiPolygon") {
                                level.polygon.geometry.coordinates.forEach(coords => {
                                    ug_polygons_coords.push(coords);
                                })
                            }

                            // Loop th all polygons coords
                            ug_polygons_coords.forEach(ug_polygon => {

                                // Get local coordinates
                                var building_coords_global = ug_polygon[0];
                                var building_coords_local = [];
                                for (var i = 0; i < building_coords_global.length; i++) {
                                    var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                                    if (building_coords_global[i][0] < point0[0]) {
                                        deltaX = -deltaX;
                                    }
                                    var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                                    if (building_coords_global[i][1] < point0[1]) {
                                        deltaY = -deltaY;
                                    }
                                    building_coords_local.push([deltaX, deltaY]);
                                }

                                // Create mesh
                                var building_shape = new THREE.Shape();
                                building_shape.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                                for (var i = 0; i < building_coords_local.length; i++) {
                                    building_shape.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                                }
                                var building_extrude_settings = {
                                    steps: 1,
                                    depth: Math.abs(level.elevations[1] - level.elevations[0]),
                                    bevelEnabled: false
                                };
                                var building_geometry = new THREE.ExtrudeGeometry(building_shape, building_extrude_settings);
                                building_geometry.translate(0, 0, (level.elevations[1] - 0.01));
                                var building_material = new THREE.MeshPhongMaterial({
                                    color: '#BDBDBD',
                                    transparent: false,
                                    // opacity: 0.4
                                    // side: THREE.DoubleSide,
                                    // depthWrite: false
                                });
                                var building_mesh = new THREE.Mesh(building_geometry, building_material);
                                this.scene.add(building_mesh);

                                // Create edges
                                var building_mesh_edges = new THREE.LineSegments(new THREE.EdgesGeometry(building_mesh.geometry), new THREE.LineBasicMaterial({ color: "#5b5a5a" }));
                                // var building_mesh_edges = new THREE.LineSegments(new THREE.EdgesGeometry(building_mesh.geometry), new THREE.LineDashedMaterial({
                                //     color: "grey",
                                //     linewidth: 1,
                                //     scale: 1,
                                //     dashSize: 3,
                                //     gapSize: 1,
                                // }));
                                this.scene.add(building_mesh_edges);

                                // Create holes


                            })


                        }


                    })

                    // // Loop th all levels
                    // for (var level_index = 0; level_index < combination.undergrounds.levels.length; level_index++) {
                    //     var level = combination.parking.sub.levels[level_index];

                    //     // Check if polygon is type "Polygon"
                    //     if (!level?.polygon || level?.polygon?.geometry?.type !== "Polygon") {
                    //         console.log("ERROR no polygon on level", level);
                    //         break;
                    //     }

                    //     // Get local coordinates
                    //     var building_coords_global = level.polygon.geometry.coordinates[0];
                    //     var building_coords_local = [];
                    //     for (var i = 0; i < building_coords_global.length; i++) {
                    //         var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                    //         if (building_coords_global[i][0] < point0[0]) {
                    //             deltaX = -deltaX;
                    //         }
                    //         var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                    //         if (building_coords_global[i][1] < point0[1]) {
                    //             deltaY = -deltaY;
                    //         }
                    //         building_coords_local.push([deltaX, deltaY]);
                    //     }

                    //     // Create mesh
                    //     var building_shape = new THREE.Shape();
                    //     building_shape.moveTo(building_coords_local[0][0], building_coords_local[0][1]);
                    //     for (var i = 0; i < building_coords_local.length; i++) {
                    //         building_shape.lineTo(building_coords_local[i][0], building_coords_local[i][1]);
                    //     }
                    //     var building_extrude_settings = {
                    //         steps: 1,
                    //         depth: level.height,
                    //         bevelEnabled: false
                    //     };
                    //     var building_geometry = new THREE.ExtrudeGeometry(building_shape, building_extrude_settings);
                    //     building_geometry.translate(0, 0, (level.elevation - level.height));
                    //     var building_material = new THREE.MeshPhongMaterial({
                    //         color: '#BDBDBD',
                    //         // transparent: false,
                    //         // opacity: 0.4
                    //         side: THREE.DoubleSide
                    //     });
                    //     var building_mesh = new THREE.Mesh(building_geometry, building_material);
                    //     this.scene.add(building_mesh);
                    //     // var building_mesh_edges = new THREE.LineSegments(new THREE.EdgesGeometry(building_mesh.geometry), new THREE.LineBasicMaterial({ color: "grey" }));
                    //     var building_mesh_edges = new THREE.LineSegments(new THREE.EdgesGeometry(building_mesh.geometry), new THREE.LineDashedMaterial({
                    //         color: "grey",
                    //         linewidth: 1,
                    //         scale: 1,
                    //         dashSize: 3,
                    //         gapSize: 1,
                    //     }));
                    //     this.scene.add(building_mesh_edges);

                    //     // Create holes


                    // }

                }





                // for (var level_index = 1; level_index < building_levels.length; level_index++) {
                //     for (var footprint_index = 0; footprint_index < building_levels[level_index].footprints.length; footprint_index++) {
                //         if (building_levels[level_index].footprints[footprint_index].geometry.type === "Polygon") {
                //             // Get bbox local coordinates
                //             var bbox_coords_global = building_levels[level_index].footprints[footprint_index].geometry.coordinates[0];
                //             var bbox_coords = [];
                //             for (var i = 0; i < bbox_coords_global.length; i++) {
                //                 var deltaX = (turf.distance(turf.point(bbox_coords_global[i]), turf.point([point0[0], bbox_coords_global[i][1]])) * 1000);
                //                 if (bbox_coords_global[i][0] < point0[0]) {
                //                     deltaX = -deltaX;
                //                 }
                //                 var deltaY = (turf.distance(turf.point(bbox_coords_global[i]), turf.point([bbox_coords_global[i][0], point0[1]])) * 1000);
                //                 if (bbox_coords_global[i][1] < point0[1]) {
                //                     deltaY = -deltaY;
                //                 }
                //                 bbox_coords.push([deltaX, deltaY]);
                //             }
                //             // console.log("bbox local coords: ", bbox_coords);

                //             // CREATE LEVEL MESH
                //             var bboxShape = new THREE.Shape();
                //             bboxShape.moveTo(bbox_coords[0][0], bbox_coords[0][1]);
                //             for (var i = 0; i < bbox_coords.length; i++) {
                //                 bboxShape.lineTo(bbox_coords[i][0], bbox_coords[i][1]);
                //             }
                //             var bboxExtrudeSettings = {
                //                 steps: 1,
                //                 depth: building_levels[level_index].elevation - building_levels[level_index - 1].elevation,
                //                 bevelEnabled: false
                //             };
                //             var bboxGeometry = new THREE.ExtrudeGeometry(bboxShape, bboxExtrudeSettings);
                //             bboxGeometry.translate(0, 0, building_levels[level_index - 1].elevation);
                //             // bboxGeometry.translate(0, 0, building_levels[level_index - 1].elevation - 6);
                //             var bboxMaterial = new THREE.MeshPhongMaterial({
                //                 color: '#F1F1F1',
                //                 // transparent: false,
                //                 // opacity: 0.4
                //             });
                //             var bboxMesh = new THREE.Mesh(bboxGeometry, bboxMaterial);

                //             // CREATE HOLES
                //             if (building_levels[level_index].footprints[footprint_index].geometry.coordinates.length > 1) {
                //                 console.log("HOLES");
                //                 // Create hole mesh
                //                 var hole_index = 1;
                //                 var bbox_coords_global = building_levels[level_index].footprints[footprint_index].geometry.coordinates[hole_index];
                //                 var bbox_coords = [];
                //                 for (var i = 0; i < bbox_coords_global.length; i++) {
                //                     var deltaX = (turf.distance(turf.point(bbox_coords_global[i]), turf.point([point0[0], bbox_coords_global[i][1]])) * 1000);
                //                     if (bbox_coords_global[i][0] < point0[0]) {
                //                         deltaX = -deltaX;
                //                     }
                //                     var deltaY = (turf.distance(turf.point(bbox_coords_global[i]), turf.point([bbox_coords_global[i][0], point0[1]])) * 1000);
                //                     if (bbox_coords_global[i][1] < point0[1]) {
                //                         deltaY = -deltaY;
                //                     }
                //                     bbox_coords.push([deltaX, deltaY]);
                //                 }
                //                 // console.log("bbox local coords: ", bbox_coords);
                //                 // Shape and Geometry
                //                 var bboxShape = new THREE.Shape();
                //                 bboxShape.moveTo(bbox_coords[0][0], bbox_coords[0][1]);
                //                 for (var i = 0; i < bbox_coords.length; i++) {
                //                     bboxShape.lineTo(bbox_coords[i][0], bbox_coords[i][1]);
                //                 }
                //                 var bboxExtrudeSettings = {
                //                     steps: 1,
                //                     depth: building_levels[level_index].elevation - building_levels[level_index - 1].elevation,
                //                     bevelEnabled: false
                //                 };
                //                 var holeGeometry = new THREE.ExtrudeGeometry(bboxShape, bboxExtrudeSettings);
                //                 holeGeometry.translate(0, 0, building_levels[level_index - 1].elevation);
                //                 // bboxGeometry.translate(0, 0, building_levels[level_index - 1].elevation - 6);
                //                 var holeMaterial = new THREE.MeshPhongMaterial({
                //                     color: '#FF0000',
                //                     // transparent: false,
                //                     // opacity: 0.4
                //                 });
                //                 var holeMesh = new THREE.Mesh(holeGeometry, holeMaterial);
                //                 // CSG operation
                //                 bboxMesh.updateMatrix();
                //                 holeMesh.updateMatrix();
                //                 var interceptorMesh = CSG.subtract(bboxMesh, holeMesh);
                //                 this.scene.add(interceptorMesh);
                //                 bboxMesh = interceptorMesh;
                //             }

                //             this.scene.add(bboxMesh);
                //             // Edges
                //             var edgesGeometry = new THREE.EdgesGeometry(bboxMesh.geometry);
                //             var edgesMesh = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color: "grey" }));
                //             this.scene.add(edgesMesh);



                //             // CREATE ROOF MESH
                //             if (level_index === building_levels.length - 1 && capacity?.buildable?.volume?.parameters?.roof_angle > 0) {
                //                 var skeleton_coords = [...bbox_coords];
                //                 skeleton_coords.splice(-1);
                //                 // SKELETON
                //                 const skeleton = SkeletonBuilder.BuildFromGeoJSON([[
                //                     skeleton_coords,
                //                     // [[0, 0], [100, 0], [100, 50], [0, 50]],    // outer
                //                     // [[50, 30], [70, 30], [70, 20], [50, 20]],  // inner
                //                 ]]);
                //                 // console.log("skeleton", skeleton);

                //                 // Get skeletyon points list
                //                 var angle = capacity?.buildable?.volume?.parameters?.roof_angle;
                //                 var skeleton_points = [];
                //                 var point_id = 0;
                //                 var elevation_max = 0;
                //                 for (const [pointPos, distance] of skeleton.Distances) {
                //                     var coords_local = [pointPos.X, pointPos.Y];
                //                     var coords_global = map_helpers.local_to_mercator(coords_local, capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)
                //                     var turf_point = turf.point(coords_global);
                //                     var elevation_new = building_levels[level_index].elevation + ((angle / 45) * distance);
                //                     turf_point.properties.id = point_id;
                //                     turf_point.properties.elevation = elevation_new;
                //                     turf_point.properties.coords_local = coords_local;
                //                     skeleton_points.push(turf_point);

                //                     if (elevation_new > elevation_max) {
                //                         elevation_max = elevation_new;
                //                     }

                //                     point_id++;
                //                 }
                //                 // console.log("skeleton_points", skeleton_points);

                //                 // Get skeleton polygons
                //                 var skeleton_polys = [];
                //                 for (const edgeResult of skeleton.Edges) {
                //                     const poly = edgeResult.Polygon;
                //                     var skeleton_poly_points = [];
                //                     for (var sk_point_index = 0; sk_point_index < poly.length; sk_point_index++) {
                //                         // Get point id
                //                         var sk_poly_id = null;
                //                         for (var sk_pointlist_index = 0; sk_pointlist_index < skeleton_points.length; sk_pointlist_index++) {
                //                             if (poly[sk_point_index].X === skeleton_points[sk_pointlist_index].properties.coords_local[0] && poly[sk_point_index].Y === skeleton_points[sk_pointlist_index].properties.coords_local[1]) {
                //                                 sk_poly_id = sk_pointlist_index;
                //                                 break;
                //                             }
                //                         }
                //                         skeleton_poly_points.push(sk_poly_id);
                //                     }
                //                     var skeleton_poly = {
                //                         points: skeleton_poly_points,
                //                     }
                //                     skeleton_polys.push(skeleton_poly);
                //                 }
                //                 // console.log("skeleton_polys", skeleton_polys);

                //                 // Get grid
                //                 var skeleton_tin = [];
                //                 skeleton_polys.forEach(poly => {
                //                     var points_list = [];
                //                     var coords_global = [];
                //                     poly.points.forEach(point_id => {
                //                         if (point_id !== null) {
                //                             points_list.push(skeleton_points[point_id]);
                //                             coords_global.push(skeleton_points[point_id].geometry.coordinates);
                //                         }
                //                     })
                //                     coords_global.push(coords_global[0]);
                //                     if (points_list.length >= 3) {
                //                         var skeleton_polygon = turf.polygon([coords_global]);
                //                         var tin = turf.tin({ features: points_list }, "id");
                //                         // Check if poly is inside skeleton polygon
                //                         var tin_filtered = [];
                //                         for (var tin_index = 0; tin_index < tin.features.length; tin_index++) {
                //                             var centroid = turf.centroid(tin.features[tin_index]);
                //                             var isInside = turf.booleanPointInPolygon(centroid, skeleton_polygon);
                //                             if (isInside === true) {
                //                                 tin_filtered.push(tin.features[tin_index]);
                //                             }
                //                         }
                //                         skeleton_tin = skeleton_tin.concat(tin_filtered);
                //                     }
                //                 })
                //                 // console.log("skeleton_tin", skeleton_tin);

                //                 // Create 3D shape
                //                 var vertices_list = [];
                //                 for (var i = 0; i < skeleton_tin.length; i++) {
                //                     // Vertice a
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.coords_local[0]);
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.coords_local[1]);
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.elevation);
                //                     // Vertice b
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.coords_local[0]);
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.coords_local[1]);
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.elevation);
                //                     // Vertice c
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.coords_local[0]);
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.coords_local[1]);
                //                     vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.elevation);
                //                 }
                //                 // console.log("tin_to_recreate", tin_to_recreate);
                //                 var vertices = new Float32Array(vertices_list);
                //                 // Set vertices to geometry
                //                 var geometry = new THREE.BufferGeometry();
                //                 geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                //                 // Compute vertex normals
                //                 geometry.computeVertexNormals();
                //                 // Create material
                //                 var material = new THREE.MeshPhongMaterial({
                //                     color: '#F1F1F1',
                //                     side: THREE.DoubleSide
                //                     // transparent: true,
                //                     // opacity: 0.4
                //                 });
                //                 // Create mesh
                //                 var mesh = new THREE.Mesh(geometry, material);
                //                 // console.log("mesh", mesh);

                //                 // Check if higher than buildabale max height
                //                 // if (elevation_max > capacity?.buildable?.volume?.parameters?.max_height + 0.02) {
                //                 // }
                //                 // else {
                //                 this.scene.add(mesh);
                //                 // Create edges
                //                 var edgesGeometry = new THREE.EdgesGeometry(mesh.geometry);
                //                 var edgesMesh = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color: "grey" }));
                //                 // console.log("edgesMesh", edgesMesh);
                //                 this.scene.add(edgesMesh);
                //                 // }
                //             }


                //         }
                //         else {
                //             console.log("THE FOOTPRINT OF COMBI #" + highlightedCombination_item + " IS NOT A POLYGON",);
                //             console.log("level index: " + level_index + " | footprint index: " + footprint_index);
                //             console.log("polygon :", building_levels[level_index].footprints[footprint_index]);
                //         }
                //     }
                // }


            })







            // Add map
            this.map = map;
            // use the Mapbox GL JS map canvas for three.js
            this.renderer = new THREE.WebGLRenderer({
                canvas: map.getCanvas(),
                context: gl,
                antialias: true
            });
            // this.renderer.shadowMap.enabled = true;
            // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.renderer.autoClear = false;




        },
        render: function (gl, matrix) {
            var rotationX = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(1, 0, 0),
                modelTransform.rotateX
            );
            var rotationY = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 1, 0),
                modelTransform.rotateY
            );
            var rotationZ = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 0, 1),
                modelTransform.rotateZ
            );
            var m = new THREE.Matrix4().fromArray(matrix);
            var l = new THREE.Matrix4()
                .makeTranslation(
                    modelTransform.translateX,
                    modelTransform.translateY,
                    modelTransform.translateZ
                )
                .scale(
                    new THREE.Vector3(
                        modelTransform.scale,
                        -modelTransform.scale,
                        modelTransform.scale
                    )
                )
                .multiply(rotationX)
                .multiply(rotationY)
                .multiply(rotationZ);

            this.camera.projectionMatrix = m.multiply(l);
            this.renderer.resetState();
            this.renderer.render(this.scene, this.camera);
            // this.map.triggerRepaint();
        }
    };
    map_data.layers.push(customLayer);





    // Return
    console.log("map_data", map_data);
    return map_data

}

