import React, { useState, useEffect } from 'react';

// Import CSS & assets
import './Buildings.css';
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import ico_dots_menu from '../../../../assets/ico/ico_dots_menu.svg';
import ico_arrow from '../../../../assets/ico/ico_arrow_up.svg';
import fade from '../../../../assets/fade_grey.svg';

// Import external functions
import * as other_helpers from '../../../../helpers/Other_helpers';

// Import components
import DoughnutChart from '../../../charts/DoughnutChart';
import Building from './Building';


const Buildings = ({ capacity, setCapacity, mapLayers, setMapLayers, setBuildingUpdateTracker }) => {

    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }
    const layer_Toggle_All = () => {
        layer_Toggle("IGN_BuildingsLand_erase");
        layer_Toggle("IGN_BuildingsLand_keep");
    }
    const [layer_Toggled, set_layer_Toggled] = useState(false);
    useEffect(() => {
        if (mapLayers["IGN_BuildingsLand_erase"]["isVisible"] === true || mapLayers["IGN_BuildingsLand_keep"]["isVisible"] === true) {
            set_layer_Toggled(true);
        }
        else {
            set_layer_Toggled(false);
        }
    }, [mapLayers]);

    // DOTS_MENU
    const [dotMenu_Visible, set_dotsMenu_Visible] = useState(false);
    const setBuildingsStatus_All = (newStatus) => {
        console.log("CHANGE ALL BUILDINGS STATUS", newStatus);

        var nb_changed = 0;

        var new_landBase = { ...capacity.landBase };
        new_landBase.buildings.buildings_land.forEach(item_index => {

            if (new_landBase.buildings.buildings[item_index].properties.status !== newStatus) {
                new_landBase.buildings.buildings[item_index].properties.status = newStatus;
                // Estimated Built Area
                var builtArea_estimated = new_landBase.buildings.buildings[item_index]?.properties?.area || 0;
                var nbLevel_estimated = 1;
                if (new_landBase.buildings.buildings[item_index]?.properties?.area > 0 && new_landBase.buildings.buildings[item_index]?.properties?.ign_properties?.nombre_d_etages) {
                    nbLevel_estimated = new_landBase.buildings.buildings[item_index]?.properties?.ign_properties?.nombre_d_etages;
                    builtArea_estimated = new_landBase.buildings.buildings[item_index]?.properties?.area * new_landBase.buildings.buildings[item_index]?.properties?.ign_properties?.nombre_d_etages;
                }
                else if (new_landBase.buildings.buildings[item_index]?.properties?.area > 0 && new_landBase.buildings.buildings[item_index]?.properties?.height) {
                    nbLevel_estimated = Math.max(1, Math.round(new_landBase.buildings.buildings[item_index]?.properties?.height / 3));
                    builtArea_estimated = new_landBase.buildings.buildings[item_index]?.properties?.area * nbLevel_estimated;
                }
                new_landBase.buildings.buildings[item_index].properties.areaTotal_estimated = builtArea_estimated;
                new_landBase.buildings.buildings[item_index].properties.nbLevel_estimated = nbLevel_estimated;

                // Raisable
                new_landBase.buildings.buildings[item_index].properties.raisable = true;

                var isDemolition = false;
                if (newStatus === "demolition") {
                    isDemolition = true;
                }
                new_landBase.buildings.buildings[item_index].properties.demolition = isDemolition;

                // Count nb changes
                nb_changed++;
            }

        });

        if (nb_changed > 0) {
            var status_text = "démolition";
            if (newStatus === "keep") { status_text = "conservation en l'état" }
            if (newStatus === "renovation") { status_text = "rénovation" }

            // Update hook & indicator
            // setStatus(newStatus);
            // setIsRaisable(true);

            // Get bounds type
            if (!new_landBase.buildings.buildings[new_landBase.buildings.buildings_land[0]]?.properties?.bounds_type?.mit_distance >= 0) {
                var new_buildings = other_helpers.get_existing_buildings_bound_type({ ...capacity, landBase: new_landBase });
                if (new_buildings !== null) {
                    new_landBase.buildings.buildings = new_buildings;
                }
            }

            // Update capacity
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("landBase");
            toSaveData.autoSave = false;
            toSaveData.action = "Modification du statut de toutes les constructions existantes à";
            toSaveData.value = status_text;
            setCapacity({ ...capacity, landBase: new_landBase, toSave: toSaveData });

            // Track updates
            setBuildingUpdateTracker((Date.now()).toString());
        }

    }


    // CHART
    const pieChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start'
            },
        },
        cutout: 60
    }
    var chartData = {
        labels: ['Surface imperméable', 'Surface perméable'],
        datasets: [
            {
                label: 'Surfaces',
                data: [capacity?.landBase?.buildings?.buildings_land_area || 0, (capacity?.landBase?.union?.area - capacity?.landBase?.buildings?.buildings_land_area) || 0],
                backgroundColor: [
                    '#062134',
                    '#E5E5E5',
                ],
                hoverBackgroundColor: [
                    '#062134',
                    '#E5E5E5',
                ],
                borderColor: "#D9D9D9",
                hoverBorderColor: "#D9D9D9",
                borderWidth: 0,
                hoverBorderWidth: 0,
            },
        ],
    };


    const [buildingsDetail, setBuildingsDetail] = useState(false);
    const handleBuildingsDetail = () => {
        if (buildingsDetail === true) {
            // Scroll
            var topList = document.getElementById("buildings_anchor").offsetTop;
            var topSection = document.getElementById("section_1").offsetTop;
            document.getElementById("section_data").scrollTop = topSection + topList + 10;
        }
        setBuildingsDetail(!buildingsDetail);
    }


    return (
        <>
            {(capacity?.landBase?.buildings?.buildings_land && capacity.landBase.buildings.buildings_land.length > 0) &&
                <div>
                    <div className="app__sectiondata_widget_spacer_40"></div>
                    <div id="buildings_anchor" className="app__sectiondata_widget_title_container">
                        <div id="section_1_buildings" className="app__sectiondata_widget_title">Constructions existantes sur le terrain</div>
                        <div className="app__sectiondata_widget_layer">
                            <div className="app__sectiondata_widget_submenu_container" style={{ marginRight: '6px' }} onMouseLeave={() => { set_dotsMenu_Visible(false) }}>
                                <img src={ico_dots_menu} alt="ico_dots_menu" title="Plus d'actions" onClick={() => { set_dotsMenu_Visible(true) }} />
                                <div className={`app__sectiondata_widget_submenu scale-up-topright ${dotMenu_Visible ? "app__sectiondata_widget_submenu_opened" : ""}`}>
                                    <div className="app__sectiondata_widget_submenu_item" onClick={() => { setBuildingsStatus_All("keep"); set_dotsMenu_Visible(false) }}>Conserver toutes les constructions</div>
                                    <div className="app__sectiondata_widget_submenu_item" onClick={() => { setBuildingsStatus_All("demolition"); set_dotsMenu_Visible(false) }}>Démolir toutes les constructions</div>
                                    <div className="app__sectiondata_widget_submenu_placeholder"></div>
                                </div>
                            </div>
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            {/* <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_buildings_ign_land" checked={mapLayers.IGN_Buildings.isVisible} onChange={() => { layer_Toggle("IGN_Buildings") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_buildings_ign_land">Toggle</label></div> */}
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switch_buildings_ign_land" checked={layer_Toggled} onChange={() => { layer_Toggle_All() }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switch_buildings_ign_land">Toggle</label></div>
                        </div>
                    </div>
                    <div id="buildings_list" className={`app__sectiondata_buildings_container ${(!buildingsDetail && capacity.landBase.buildings.buildings_land.length > 3) ? "app__sectiondata_buildings_container_small" : ""}`}>
                        {capacity.landBase.buildings.buildings_land.map((item_index, index) => {
                            return (
                                <Building key={"building_" + index} capacity={capacity} setCapacity={setCapacity} setBuildingUpdateTracker={setBuildingUpdateTracker} item={capacity.landBase.buildings.buildings[item_index]} item_index={item_index} index={index} />
                            )

                        })}
                    </div>
                    {/* HIDE SHOW */}
                    {capacity.landBase.buildings.buildings_land.length > 3 &&
                        <div className="app__context_housing_detail_button_container2">
                            {!buildingsDetail ?
                                <img className="app__fade" src={fade} alt="" />
                                :
                                <div className="app__sectiondata_widget_spacer_10"></div>
                            }
                            <div className="app__context_housing_detail_button_container" onClick={handleBuildingsDetail}>
                                {buildingsDetail &&
                                    <div className="app__context_housing_detail_button_up"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                                <div className="app__context_housing_detail_button">{buildingsDetail ? 'Afficher moins de constructions' : 'Afficher les ' + capacity.landBase.buildings.buildings_land.length + ' constructions'}</div>
                                {!buildingsDetail &&
                                    <div className="app__context_housing_detail_button_down"><img src={ico_arrow} alt="arrow_up" /></div>
                                }
                            </div>
                        </div>
                    }
                    {/* INFO */}
                    <div className="app__sectiondata_widget_info_container">
                        <div className="app__sectiondata_widget_info_text">
                            Source : IGN BD Topo, Base de Données Nationale des Bâtiments et CSTB
                        </div>
                        <div className="app__sectiondata_widget_info_icon">i</div>
                    </div>
                    {/* IMPERMEABILITY */}
                    {(capacity?.landBase?.union?.area && capacity?.landBase?.buildings?.buildings_land_area) &&
                        <>
                            <div className="app__sectiondata_widget_spacer_40"></div>
                            <div id="section_1_waterproof" className="app__sectiondata_widget_title">Coefficient d'imperméabilisation du terrain</div>
                            <div className="app__buidings_coef_body app__sectiondata__widget">
                                {window.innerWidth > 500 &&
                                    <div className="app__buildings_chart">
                                        <div className="app__buildings_chart_value">≥<span style={{ fontSize: '32px', fontWeight: '600', marginLeft: '4px', marginRight: '4px' }}>{other_helpers.get_pretty_num((100 * capacity?.landBase?.buildings?.buildings_land_area / capacity?.landBase?.union?.area).toFixed(1))}</span>%</div>
                                        <DoughnutChart chartData={chartData} chartOptions={pieChartOptions} />
                                    </div>
                                }
                                <div className="app__buidings_coef_text">
                                    <div>Le terrain fait <strong>{other_helpers.get_pretty_num(capacity?.landBase?.union?.area.toFixed(2))}m²</strong> et présente une surface construite de <strong>{other_helpers.get_pretty_num(capacity?.landBase?.buildings?.buildings_land_area.toFixed(2))}m²</strong> (sans compter les voiries, ou autres surfaces imperméables extérieures non connues).</div>
                                    <div>Ce qui représente un coefficient d'imperméabilisation d'une valeur au moins égale à <strong>{other_helpers.get_pretty_num((100 * capacity?.landBase?.buildings?.buildings_land_area / capacity?.landBase?.union?.area).toFixed(1))}%</strong>.</div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            }
        </>
    );
};

export default Buildings;