import React from 'react';

// Import external functions
import * as other_helpers from '../../../../helpers/Other_helpers';
import WidgetMessage from './WidgetMessage';

const Combination = ({ combination, capacity, highlightedCombination, setHighlightedCombination, index, orderedBy, unavailableSimu }) => {

    const showCombi = (combi) => {
        var new_highlightedCombination = [];
        var isDeleting = false;

        // Removing items of same zone
        var combi_group_id = "";
        var indexOf = combi.id.indexOf("-");
        if (indexOf > -1) {
            combi_group_id = combi.id.substring(0, indexOf);
        }
        highlightedCombination.forEach((highlighted, i) => {
            if (combi_group_id !== highlighted.id.substring(0, highlighted.id.indexOf("-"))) {
                new_highlightedCombination.push({ ...highlighted, section: null });
            }
            if (combi.id === highlighted.id) {
                isDeleting = true;
            }
        })

        // Adding new selected
        if (!isDeleting) {
            new_highlightedCombination.push({ id: combi.id, section: null });
        }

        // Sort
        new_highlightedCombination.sort(function (a, b) {
            var x = a.id; var y = b.id;
            return ((x > y) ? 1 : ((x < y) ? -1 : 0));
        });

        setHighlightedCombination(new_highlightedCombination);

    }

    if (combination?.buildings && combination.buildings.length > 0) {
        var combi_id = 0;
        var indexOf_raise = combination.id.indexOf("raise");
        var indexOf_zone = combination.id.indexOf("-");
        if (indexOf_raise > -1) {
            combi_id = combination.id.substring(indexOf_zone + 1);
        }
        else if (indexOf_zone > -1) {
            combi_id = combination.id.substring(indexOf_zone + 1);
        }
        else {
            combi_id = combination.id;
        }
        return (
            // <div className={`combi__card ${highlightedCombination?.id === combination.id ? "combi__card_selected" : ""} ${combination?.validity === false ? "combi__card_invalid" : ""}`} onClick={() => { showCombi(combination) }}>
            <div className={`combi__card ${highlightedCombination.some((item) => item.id === combination.id) && "combi__card_selected"} ${Object.keys(unavailableSimu).includes(combination.id) && "combi__card_unavailable"} ${combination?.validity === false && "combi__card_invalid"}`} onClick={() => { if (!Object.keys(unavailableSimu).includes(combination.id)) { showCombi(combination) } }}>
                <div className="combi__card_info">
                    {/* <div className="combi__card_info_title">Simulation #{combination.id} {(orderedBy !== "byId" && index === 0) ? "🥇" : (orderedBy !== "byId" && index === 1) ? "🥈" : (orderedBy !== "byId" && index === 2) ? "🥉" : ""}</div> */}
                    <div className="combi__card_info_title">{combination.id.includes("raise") ? "Surélévation #C" + combination?.raise + "-" + combi_id : "Simulation #" + combination.id}</div>
                    <div className="combi__card_info_body">
                        {Object.keys(unavailableSimu).includes(combination.id) &&
                            <div className="combi__card_unavailable_message">Cette simulation ne respecte pas l'espacement minimum avec la simulation #{unavailableSimu[combination.id].from} sélectionnée</div>
                        }
                        {orderedBy === "byGroundArea" ?
                            <div className="combi__card_info_body_section">
                                <div className="combi__card_info_body_kpi_container">
                                    <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num((combination.groundArea).toFixed(0))} <span style={{ fontSize: "10px" }}>m²</span></div>
                                    <div className="combi__card_info_body_kpi_legend">Emprise au sol</div>
                                </div>
                                <div className="combi__card_info_body_section_separator"></div>
                                <div className="combi__card_info_body_kpi_container">
                                    <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num((combination.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0))} <span style={{ fontSize: "10px" }}>m²</span></div>
                                    <div className="combi__card_info_body_kpi_legend">SHAB/SU</div>
                                </div>
                            </div>
                            :
                            orderedBy === "byCompacity" ?
                                <div className="combi__card_info_body_section">
                                    <div className="combi__card_info_body_kpi_container">
                                        <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num((combination.facade_area / combination.area).toFixed(2))}</div>
                                        <div className="combi__card_info_body_kpi_legend">Compacité</div>
                                    </div>
                                    <div className="combi__card_info_body_section_separator"></div>
                                    <div className="combi__card_info_body_kpi_container">
                                        <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num((combination.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0))} <span style={{ fontSize: "10px" }}>m²</span></div>
                                        <div className="combi__card_info_body_kpi_legend">SHAB/SU</div>
                                    </div>
                                </div>
                                :
                                <div className="combi__card_info_body_section">
                                    <div className="combi__card_info_body_kpi_container">
                                        <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num(Math.min(100, (100 * combination.area / Math.min(capacity?.buildable?.volume?.parameters?.buildableArea, capacity?.buildable?.volume?.parameters?.max_area_total)).toFixed(0)))} <span style={{ fontSize: "10px" }}>%</span></div>
                                        <div className="combi__card_info_body_kpi_legend">Optimisation</div>
                                    </div>
                                    <div className="combi__card_info_body_section_separator"></div>
                                    <div className="combi__card_info_body_kpi_container">
                                        <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num((combination.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0))} <span style={{ fontSize: "10px" }}>m²</span></div>
                                        <div className="combi__card_info_body_kpi_legend">SHAB/SU</div>
                                    </div>
                                </div>
                        }
                        <div className="combi__card_info_body_section_dynamic">
                            {orderedBy === "byCompacity" ?
                                <div className="combi__card_info_body_kpi_container">
                                    <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num(Math.min(100, (100 * combination.area / Math.min(capacity?.buildable?.volume?.parameters?.buildableArea, capacity?.buildable?.volume?.parameters?.max_area_total)).toFixed(0)))} <span style={{ fontSize: "10px" }}>%</span></div>
                                    <div className="combi__card_info_body_kpi_legend">Optimisation</div>
                                </div>
                                :
                                <div className="combi__card_info_body_kpi_container">
                                    <div className="combi__card_info_body_kpi_value">{other_helpers.get_pretty_num((combination.facade_area / combination.area).toFixed(2))}</div>
                                    <div className="combi__card_info_body_kpi_legend">Compacité</div>
                                </div>
                            }
                            <div className="combi__card_info_body_section_separator"></div>
                            <div className="combi__card_info_body_kpi_container">
                                <div className="combi__card_info_body_kpi_value">{combination.buildings.length}</div>
                                <div className="combi__card_info_body_kpi_legend">{combination.buildings.length > 1 ? "Bâtiments" : "Bâtiment"}</div>
                            </div>
                        </div>
                        {/* <div className="combi__card_info_legend">Superstructure</div>
                        <div className="combi__card_info_text">{combination.buildings.length} {combination.buildings.length > 1 ? "Bâtiments" : "Bâtiment"}</div>
                        <div className="combi__card_info_text"><strong>{other_helpers.get_pretty_num(combination.area.toFixed(2))} m²</strong></div>
                        {(combination?.undergrounds.length > 0 && combination.undergrounds[0].levels.length > 0) &&
                            <>
                                <div className="combi__card_info_legend">Infrastructure</div>
                                <div className="combi__card_info_text">{combination.undergrounds[0].levels.length} {combination.undergrounds[0].levels.length > 1 ? "Niveaux" : "Niveau"}</div>
                                <div className="combi__card_info_text"><strong>{other_helpers.get_pretty_num(combination.parking.sub.area.toFixed(2))} m²</strong></div>
                            </>
                        } */}
                    </div>
                </div>
                <div className="combi__card_svg">
                    <svg width="100%" viewBox="-20 -20 840 840" preserveAspectRatio="xMidYMid meet" fillRule="evenodd" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {/* Land svg */}
                        <polygon points={other_helpers.get_svgCoords_from_globalCoords(capacity?.landBase?.union?.geometry?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#AFC76B"} stroke={"red"} strokeWidth="0" />
                        {/* Buildings svgs */}
                        {combination.buildings.map(footprint => {
                            if (footprint?.levels && footprint?.levels.length > 0) {
                                return (
                                    <polygon points={other_helpers.get_svgCoords_from_globalCoords(footprint?.levels[0]?.polygon?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#FFFFFF"} stroke={"grey"} strokeWidth="5" />
                                )
                            }
                        })}
                    </svg>
                </div>
                {combination?.validity === false &&
                    <WidgetMessage text={combination.message} />
                }
            </div>
        );
    }
};

export default Combination;