import React, { useState, useEffect } from 'react';

// Import CSS & assets
import close from '../../../../assets/ico/ico_close.svg';

// Import external functions
import * as other_helpers from '../../../../helpers/Other_helpers';

// Import components
import DoughnutChart from '../../../charts/DoughnutChart';

const Combination = ({ capacity, highlightedCombination, setHighlightedCombination, dataType }) => {

    const [selectedSection, setSelectedSection] = useState(0);
    const [sectionOffset, setSectionOffset] = useState(0);
    const [indicatorOffset, setIndicatorOffset] = useState({ width: 33, left: 0 });
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    var combinations = [];
    var buildings = [];
    var undergrounds = [];
    var combinations_data = {
        title: "",
        area: 0,
        area_facade: 0,
        area_ground: 0,
        area_land_free: capacity.landBase.union.area,
        area_pk: 0,
        area_pk_ext: 0,
        area_pk_sub: 0,
        nb_spot: 0,
        nb_spot_ext: 0,
        nb_spot_sub: 0,
        levels_sub: 0
    }
    var combination = capacity?.buildable?.combinations[0][0];
    const [combinationsData, setCombinationsData] = useState(combinations_data);
    const [combinationsAll, setCombinationsAll] = useState(combinations);
    const [buildingsAll, setBuildingsAll] = useState(buildings);
    const [chartDataAll, setChartDataAll] = useState({});

    const getCombinationsData = () => {
        // Reset values
        combinations = [];
        buildings = [];
        undergrounds = [];
        combinations_data = {
            title: "",
            area: 0,
            area_facade: 0,
            area_ground: 0,
            area_land_free: capacity.landBase.union.area,
            area_pk: 0,
            area_pk_ext: 0,
            area_pk_sub: 0,
            nb_spot: 0,
            nb_spot_ext: 0,
            nb_spot_sub: 0,
            levels_sub: 0
        }
        const letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        var building_nb = 0;
        var raise_nb = 0;
        highlightedCombination.forEach(highlightedCombination_item => {
            // console.log("00", highlightedCombination_item);
            var combi_group_id = 0;
            var combi_id = 0;
            var indexOf_raise = highlightedCombination_item.id.indexOf("raise");
            var indexOf_zone = highlightedCombination_item.id.indexOf("-");
            if (indexOf_raise > -1) {
                combi_group_id = highlightedCombination_item.id.substring(6, indexOf_zone);
                combi_id = highlightedCombination_item.id.substring(indexOf_zone + 1);
            }
            else if (indexOf_zone > -1) {
                combi_group_id = letters.indexOf(highlightedCombination_item.id.substring(0, indexOf_zone));
                combi_id = highlightedCombination_item.id.substring(indexOf_zone + 1);
            }
            else {
                combi_id = highlightedCombination_item.id;
            }
            // console.log("01", combi_group_id);
            // console.log("02", combi_id);
            var combination = capacity?.buildable?.combinations[combi_group_id][combi_id];
            // console.log("COMBI", combination);

            combinations.push({ ...combination, combi_id: combi_id });
            combination.buildings.forEach((building, building_id) => {
                var i_nb = null;
                if (indexOf_raise > -1) { raise_nb++; i_nb = raise_nb }
                else { building_nb++; i_nb = building_nb }
                building.combi_group_id = combi_group_id;
                building.combi_id = combi_id;
                building.building_id = building_id;
                building.combi_id_real = highlightedCombination_item.id;
                building.nb = i_nb;
                building.raise = combination?.raise || null;
                buildings.push(building);
            })

            undergrounds = undergrounds.concat(combination.undergrounds);
            combination.undergrounds.forEach(underground => {
                combinations_data.levels_sub += underground.levels.length;
            })


            combinations_data.area += combination.area;
            combinations_data.area_facade += combination.facade_area;
            combinations_data.area_ground += combination.groundArea;
            combinations_data.area_land_free -= (combination.groundArea + combination.parking.ext.area);

            combinations_data.area_pk_ext += combination.parking.ext.area;
            combinations_data.area_pk_sub += combination.parking.sub.area;
            combinations_data.area_pk += (combination.parking.ext.area + combination.parking.sub.area);
            combinations_data.nb_spot_ext += combination.parking.ext.nb_spot;
            combinations_data.nb_spot_sub += combination.parking.sub.nb_spot;
            combinations_data.nb_spot += (combination.parking.ext.nb_spot + combination.parking.sub.nb_spot);
        })
        // Add exixting values
        // console.log("dataType", dataType);
        if (dataType === "all") {
            combinations_data.area += (capacity?.buildable?.volume?.parameters?.builtArea || 0);
            combinations_data.area_ground += (capacity?.buildable?.volume?.parameters?.builtArea_ground || 0);
            combinations_data.area_land_free = capacity.landBase.union.area - (combinations_data.area_ground + combinations_data.area_pk_ext);
        }
        // Set hook
        setCombinationsData(combinations_data);
        setCombinationsAll(combinations);
        setBuildingsAll(buildings);

        // Chart
        var chartLabels = ['Surface construite', 'Surface construite (existante)', 'Surface constructible restante'];
        var chartDatas = [combinations_data.area, 0, Math.min(capacity?.buildable?.volume?.parameters?.buildableArea, capacity?.buildable?.volume?.parameters?.max_area_total) - combinations_data.area];
        var chartColors = [
            '#062134',
            '#909FA7',
            '#E5E5E5',
        ];
        if (dataType === "all") {
            chartLabels = ['Surface construite (nouvelle)', 'Surface construite (existante)', 'Surface constructible restante'];
            chartDatas = [combinations_data.area, 100, Math.min(capacity?.buildable?.volume?.parameters?.buildableArea, capacity?.buildable?.volume?.parameters?.max_area_total) - combinations_data.area];
        }
        var chartData = {
            labels: chartLabels,
            datasets: [
                {
                    label: 'Surfaces',
                    data: chartDatas,
                    backgroundColor: chartColors,
                    hoverBackgroundColor: chartColors,
                    borderColor: "#D9D9D9",
                    hoverBorderColor: "#D9D9D9",
                    borderWidth: 0,
                    hoverBorderWidth: 0,
                },
            ],
        };
        setChartDataAll(chartData);
    }

    useEffect(() => {
        getCombinationsData();
        selectedSection_change(0);
    }, [dataType]);


    const closeCombi = () => {
        setHighlightedCombination([]);
    }

    const selectedSection_change = (section_id) => {
        console.log("change", section_id, buildings, combinations);
        // Selected section
        setSelectedSection(section_id);
        // setHighlightedCombination({ ...highlightedCombination, section: section_id });
        // Move section
        // GET BACK
        var section_width = Math.floor(document.getElementById('selected_buildable_section').getBoundingClientRect().width);
        setSectionOffset(section_id * section_width);
        // Move indicator
        // GET BACK
        // setIndicatorOffset({ width: document.getElementById("menu_title_" + section_id).offsetWidth - 6, left: document.getElementById("menu_title_" + section_id).offsetLeft + 3 })
        setIndicatorOffset({ width: document.getElementById("menu_title_" + section_id).offsetWidth - 0, left: document.getElementById("menu_title_" + section_id).offsetLeft + 0 })

        // if (section_id > 0 && section_id <= combination.buildings.length) {
        if (section_id > 0) {
            setSelectedBuilding(section_id - 1);

            var new_highlightedCombination = [...highlightedCombination];
            // new_highlightedCombination.forEach(hl => {
            //     if (buildings[section_id - 1]?.combi_id_real === hl.id) {
            //         hl.section = buildings[section_id - 1].building_id + 1;
            //     }
            //     else {
            //         hl.section = -1;
            //     }
            // })
            var section_matching_id = null;
            // Is section selected a combination?
            if (section_id <= new_highlightedCombination.length) {
                section_matching_id = new_highlightedCombination[section_id - 1]?.id || null;
            }
            else {
                section_matching_id = "existing";
            }
            new_highlightedCombination.forEach(hl => {
                hl.section = section_matching_id;
            })
            setHighlightedCombination(new_highlightedCombination);
        }
        else {
            setSelectedBuilding(null);
            var new_highlightedCombination = [...highlightedCombination];
            new_highlightedCombination.forEach(hl => {
                hl.section = null;
            })
            setHighlightedCombination(new_highlightedCombination);
        }
    }

    useEffect(() => {
        if (highlightedCombination.length === 0 || highlightedCombination[0].section === null) {
            setSelectedSection(0);
            setSectionOffset(0);
            // setIndicatorOffset({ width: document.getElementById("menu_title_0").offsetWidth - 6, left: document.getElementById("menu_title_0").offsetLeft + 3 })
            setIndicatorOffset({ width: document.getElementById("menu_title_0").offsetWidth - 0, left: document.getElementById("menu_title_0").offsetLeft + 0 })
            setSelectedBuilding(null);
        }
        var tables = document.getElementsByClassName("buildable__selected_section_table_container");
        for (var i = 0; i < tables.length; i++) {
            tables[i].scrollTo({ top: 100, behavior: 'smooth' });
        }

        if (highlightedCombination.length > 0) {
            getCombinationsData();
        }
    }, [highlightedCombination]);


    // CHART
    const pieChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start'
            },
        },
        cutout: 44
    }
    // var chartData = {
    //     labels: ['Surface construite', 'Surface constructible restante'],
    //     datasets: [
    //         {
    //             label: 'Surfaces',
    //             data: [combinations_data.area, Math.min(capacity?.buildable?.volume?.parameters?.buildableArea, capacity?.buildable?.volume?.parameters?.max_area_total) - combinations_data.area],
    //             backgroundColor: [
    //                 '#062134',
    //                 '#E5E5E5',
    //             ],
    //             hoverBackgroundColor: [
    //                 '#062134',
    //                 '#E5E5E5',
    //             ],
    //             borderColor: "#D9D9D9",
    //             hoverBorderColor: "#D9D9D9",
    //             borderWidth: 0,
    //             hoverBorderWidth: 0,
    //         },
    //     ],
    // };


    if (highlightedCombination.length > 0) {
        return (
            <div className="buildable__selected_container">
                <div className="buildable__selected_close" onClick={closeCombi}><img src={close}></img></div>
                <div className="buildable__selected_info">
                    <div className="buildable__selected_title">
                        {highlightedCombination.length === 1 ?
                            <> {highlightedCombination[0].id.includes("raise") ?
                                <>
                                    Simulation #C{combinationsAll[0]?.raise || ""}-{combinationsAll[0]?.combi_id || ""}
                                </>
                                :
                                <>
                                    Simulation #{highlightedCombination[0].id}
                                </>

                            }
                            </>
                            :
                            <>
                                Simulations{highlightedCombination.map((item, index) => {
                                    var separator = " ";
                                    if (index === highlightedCombination.length - 1) { separator = " et " }
                                    else if (index > 0) { separator = ", " }
                                    if (item.id.includes("raise")) {
                                        return (separator + "#C" + (combinationsAll[index]?.raise || "") + "-" + (combinationsAll[index]?.combi_id || ""));
                                    }
                                    else {
                                        return (separator + "#" + item.id);
                                    }
                                })}
                            </>
                        }
                    </div>
                    <div className="buildable__selected_menu">
                        <div className="buildiable__selected_menu_border"></div>
                        <div id="menu_title_0" className={`buildable__selected_menu_item ${selectedSection === 0 ? "buildable__selected_menu_item_selected" : ""}`} onClick={() => { selectedSection_change(0) }}>Infos</div>
                        {buildingsAll.map((building, index) => {
                            return (
                                <div id={"menu_title_" + (index + 1)} key={"menu_title_" + index} className={`buildable__selected_menu_item ${selectedSection === index + 1 ? "buildable__selected_menu_item_selected" : ""}`} onClick={() => { selectedSection_change(index + 1) }}>{(building?.combi_id_real && building.combi_id_real.includes("raise")) ? "Surélévation" + (buildingsAll.length > 1 ? " " + ((building?.raise && "C" + building?.raise) || building?.nb || index + 1) : "") : "Bâtiment" + (buildingsAll.length > 1 ? " " + (building?.nb || index + 1) : "")}</div>
                            )
                        })}
                        {dataType === "all" &&
                            <div id={"menu_title_" + (buildingsAll.length + 1)} key={"menu_title_" + (buildingsAll.length + 1)} className={`buildable__selected_menu_item ${selectedSection === (buildingsAll.length + 1) ? "buildable__selected_menu_item_selected" : ""}`} onClick={() => { selectedSection_change(buildingsAll.length + 1) }}>Existant</div>
                        }
                        {/* {(combination?.parking?.ext?.area > 0 || combination?.parking?.sub?.area > 0) &&
                            <div id={"menu_title_" + (buildingsAll.length + 1)} className={`buildable__selected_menu_item ${selectedSection === (buildingsAll.length + 1) ? "buildable__selected_menu_item_selected" : ""}`} onClick={() => { selectedSection_change(buildingsAll.length + 1) }}>Stationnements</div>
                            } */}
                        <div className="buildable__selected_menu_indicator" style={{ left: indicatorOffset.left + "px", width: indicatorOffset.width + "px" }}></div>
                    </div>
                    <div id="selected_buildable_section" className="buildable__selected_section_container">
                        {/* SECTION INFOS */}
                        <div className="buildable__selected_section" style={{ transform: "translateX(-" + sectionOffset + "px)" }}>
                            <div className="buildable__selected_section_text_container">
                                {window.innerWidth > 500 &&
                                    <div className="buildable__selected_section_optim_container">
                                        <div className="buildable__selected_section_optim_text_container">
                                            <div className="buildable__selected_section_optim_text" title="Optimisation = Surface Construite / Surface Constructible">{Math.min(100, (100 * combinationsData.area / Math.min(capacity?.buildable?.volume?.parameters?.buildableArea, capacity?.buildable?.volume?.parameters?.max_area_total)).toFixed(0))} <span style={{ fontSize: "16px" }}>%</span></div>
                                            <div className="buildable__selected_section_optim_text_legend" title="Optimisation = Surface Construite / Surface Constructible">Optimisation</div>
                                        </div>
                                        <DoughnutChart chartData={chartDataAll} chartOptions={pieChartOptions} />
                                    </div>
                                }
                                <div className="buildable__selected_section_other_container">
                                    <div className="buildable__selected_section_other_subcontainer">
                                        <div className="buildable__selected_section_other_kpi_container">
                                            <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num((combinationsData.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                            <div className="buildable__selected_section_other_kpi_legend">SHAB/SU</div>
                                        </div>
                                        <div className="combi__card_info_body_section_separator"></div>
                                        <div className="buildable__selected_section_other_kpi_container">
                                            <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(combinationsData.area_ground.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                            <div className="buildable__selected_section_other_kpi_legend">Emprise au sol</div>
                                        </div>
                                        <div className="combi__card_info_body_section_separator"></div>
                                        <div className="buildable__selected_section_other_kpi_container" style={{ width: "28%" }}>
                                            <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num((combinationsData.area_facade / combinationsData.area).toFixed(2))}</div>
                                            <div className="buildable__selected_section_other_kpi_legend">Compacité</div>
                                        </div>
                                    </div>
                                    <div className="app__sectiondata_widget_spacer_20"></div>
                                    <div className="buildable__selected_section_other_subcontainer">
                                        <div className="buildable__selected_section_other_kpi_container">
                                            <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(combinationsData.area_pk.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                            <div className="buildable__selected_section_other_kpi_legend">Surface parking</div>
                                        </div>
                                        <div className="combi__card_info_body_section_separator"></div>
                                        <div className="buildable__selected_section_other_kpi_container">
                                            <div className="buildable__selected_section_other_kpi_value">{combinationsData.levels_sub}</div>
                                            <div className="buildable__selected_section_other_kpi_legend">Niv. sous-sol</div>
                                        </div>
                                        <div className="combi__card_info_body_section_separator"></div>
                                        <div className="buildable__selected_section_other_kpi_container" style={{ width: "28%" }}>
                                            <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(combinationsData.nb_spot.toFixed(0))}</div>
                                            <div className="buildable__selected_section_other_kpi_legend">{combinationsData.nb_spot > 1 ? "Places" : "Place"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="buildable__selected_section_text">Cette simulation présente une surface de plancher de <strong>{other_helpers.get_pretty_num(combination.area.toFixed(2))} m²</strong>{combination.buildings.length > 1 && ", répartie sur " + combination.buildings.length + " bâtiments"}. */}
                            {/* <br></br>Cela représente <strong>{other_helpers.get_pretty_num((100 * combination.area / capacity.buildable.volume.parameters.buildableArea).toFixed(0))} %</strong> de la surface constructible totale.</div> */}
                            {/* <div className="buildable__selected_section_text">L'emprise au sol est de <strong>{other_helpers.get_pretty_num(combination.groundArea.toFixed(2))} m²</strong>, soit une surface libre de <strong>{other_helpers.get_pretty_num((capacity.landBase.union.area - combination.groundArea).toFixed(2))} m²</strong>.</div> */}
                        </div>
                        {/* SECTION BUILDING */}
                        {buildingsAll.map((building, index) => {
                            return (
                                <div key={"menu_section_" + index} className="buildable__selected_section" style={{ transform: "translateX(-" + sectionOffset + "px)" }}>
                                    {(building?.combi_id_real && building.combi_id_real.includes("raise")) ?
                                        // SURELEVATION
                                        <div className="buildable__selected_section_other_subcontainer" style={{ marginTop: "4px" }}>
                                            <div className="buildable__selected_section_other_kpi_container" style={{ width: "28%" }}>
                                                <div className="buildable__selected_section_other_kpi_value">{building.levels.length}</div>
                                                <div className="buildable__selected_section_other_kpi_legend">Etages</div>
                                            </div>
                                            <div className="combi__card_info_body_section_separator"></div>
                                            <div className="buildable__selected_section_other_kpi_container" style={{ width: "36%" }}>
                                                <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(building.levels[0].area.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                                <div className="buildable__selected_section_other_kpi_legend">Projection au sol</div>
                                            </div>
                                            <div className="combi__card_info_body_section_separator"></div>
                                            <div className="buildable__selected_section_other_kpi_container" style={{ width: "36%" }}>
                                                <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num((building.parameters.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                                <div className="buildable__selected_section_other_kpi_legend">SHAB/SU</div>
                                            </div>
                                        </div>
                                        :
                                        // BATIMENT
                                        <div className="buildable__selected_section_other_subcontainer" style={{ marginTop: "4px" }}>
                                            <div className="buildable__selected_section_other_kpi_container" style={{ width: "20%" }}>
                                                <div className="buildable__selected_section_other_kpi_value">{building.levels.length}</div>
                                                <div className="buildable__selected_section_other_kpi_legend">Etages</div>
                                            </div>
                                            <div className="combi__card_info_body_section_separator"></div>
                                            <div className="buildable__selected_section_other_kpi_container" style={{ width: "30%" }}>
                                                <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(building.levels[0].area.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                                <div className="buildable__selected_section_other_kpi_legend">Emprise au sol</div>
                                            </div>
                                            <div className="combi__card_info_body_section_separator"></div>
                                            <div className="buildable__selected_section_other_kpi_container" style={{ width: "30%" }}>
                                                <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num((building.parameters.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                                <div className="buildable__selected_section_other_kpi_legend">SHAB/SU</div>
                                            </div>
                                            {window.innerWidth > 500 &&
                                                <>
                                                    <div className="combi__card_info_body_section_separator"></div>
                                                    <div className="buildable__selected_section_other_kpi_container" style={{ width: "20%" }}>
                                                        <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num((building.parameters.facade_area / building.parameters.area).toFixed(2))}</div>
                                                        <div className="buildable__selected_section_other_kpi_legend">Compacité</div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                    <div className="app__sectiondata_widget_spacer_10"></div>
                                    <div className="buildable__selected_section_table_header">
                                        <div className="buildable__selected_section_table_header_col" style={{ width: "17%" }}>Niveau</div>
                                        <div className="buildable__selected_section_table_header_col" style={{ width: "20%" }}>Elévation</div>
                                        <div className="buildable__selected_section_table_header_col" style={{ width: "35%" }}>Surface Construite</div>
                                        <div className="buildable__selected_section_table_header_col" title="Surface Habitable ou Surface Utile">SHAB/SU</div>
                                    </div>
                                    <div className="buildable__selected_section_table_container">
                                        {building.levels.map((level, level_index) => {
                                            var currentIndex = (building.levels.length - 1) - level_index;
                                            var currentItem = building.levels[currentIndex];
                                            var level_label = "R+" + currentIndex;
                                            if (currentIndex === 0) { level_label = "RDC" }
                                            if (building?.combi_id_real && building.combi_id_real.includes("raise") && building?.raise > 0 && capacity?.landBase?.buildings?.buildings && capacity?.landBase?.buildings?.buildings.length >= capacity?.landBase?.buildings?.buildings_land[building?.raise - 1] && capacity?.landBase?.buildings?.buildings[capacity?.landBase?.buildings?.buildings_land[building?.raise - 1]]?.properties?.nbLevel_estimated) {
                                                level_label = "R+" + (currentIndex + capacity?.landBase?.buildings?.buildings[capacity?.landBase?.buildings?.buildings_land[building?.raise - 1]]?.properties?.nbLevel_estimated);
                                            }
                                            return (
                                                <div className="buildable__selected_section_table">
                                                    <div className="buildable__selected_section_table_col" style={{ width: "17%" }}>{level_label}</div>
                                                    <div className="buildable__selected_section_table_col" style={{ width: "20%" }}>{(currentItem.elevations[0]).toFixed(2)} m</div>
                                                    <div className="buildable__selected_section_table_col" style={{ width: "35%" }}>{other_helpers.get_pretty_num((currentItem.area).toFixed(2))} m²</div>
                                                    <div className="buildable__selected_section_table_col">{other_helpers.get_pretty_num((currentItem.area * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(2))} m²</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                        {/* SECTION EXISTING */}
                        {dataType === "all" &&
                            <div key={"menu_section_" + (buildingsAll.length + 1)} className="buildable__selected_section" style={{ transform: "translateX(-" + sectionOffset + "px)" }}>
                                <div className="buildable__selected_section_other_subcontainer" style={{ marginTop: "4px" }}>
                                    <div className="buildable__selected_section_other_kpi_container" style={{ width: "28%" }}>
                                        <div className="buildable__selected_section_other_kpi_value">{capacity?.buildable?.volume?.parameters?.buildings_kept || "NC"}</div>
                                        <div className="buildable__selected_section_other_kpi_legend">{capacity?.buildable?.volume?.parameters?.buildings_kept > 1 ? "Bâtiments" : "Bâtiment"}</div>
                                    </div>
                                    <div className="combi__card_info_body_section_separator"></div>
                                    <div className="buildable__selected_section_other_kpi_container" style={{ width: "36%" }}>
                                        <div className="buildable__selected_section_other_kpi_value">{capacity?.buildable?.volume?.parameters?.builtArea_ground > 0 ? other_helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.builtArea_ground.toFixed(0)) : "NC"} <span style={{ fontSize: "12px" }}>m²</span></div>
                                        <div className="buildable__selected_section_other_kpi_legend">Emprise au sol</div>
                                    </div>
                                    <div className="combi__card_info_body_section_separator"></div>
                                    <div className="buildable__selected_section_other_kpi_container" style={{ width: "36%" }}>
                                        <div className="buildable__selected_section_other_kpi_value">{capacity?.buildable?.volume?.parameters?.builtArea > 0 ? other_helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.builtArea * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(0)) : "NC"} <span style={{ fontSize: "12px" }}>m²</span></div>
                                        <div className="buildable__selected_section_other_kpi_legend">SHAB/SU</div>
                                    </div>
                                </div>
                                <div className="app__sectiondata_widget_spacer_10"></div>
                                <div className="buildable__selected_section_table_header">
                                    <div className="buildable__selected_section_table_header_col" style={{ width: "37%" }}>Construction</div>
                                    <div className="buildable__selected_section_table_header_col" style={{ width: "37%" }}>Surface Construite</div>
                                    <div className="buildable__selected_section_table_header_col" title="Surface Habitable ou Surface Utile">SHAB/SU</div>
                                </div>
                                <div className="buildable__selected_section_table_container">
                                    {capacity?.landBase?.buildings?.buildings_land.map((building_index, map_index) => {
                                        if (capacity?.landBase?.buildings?.buildings && capacity?.landBase?.buildings?.buildings.length >= (building_index + 1) && capacity?.landBase?.buildings?.buildings[building_index]?.properties?.status !== "demolition") {
                                            return (
                                                <div className="buildable__selected_section_table">
                                                    <div className="buildable__selected_section_table_col" style={{ width: "37%" }}>Construction C{map_index + 1}</div>
                                                    <div className="buildable__selected_section_table_col" style={{ width: "37%" }}>{capacity?.landBase?.buildings?.buildings[building_index]?.properties?.areaTotal_estimated ? other_helpers.get_pretty_num((capacity?.landBase?.buildings?.buildings[building_index]?.properties?.areaTotal_estimated).toFixed(2)) : "NC"} m²</div>
                                                    <div className="buildable__selected_section_table_col">{capacity?.landBase?.buildings?.buildings[building_index]?.properties?.areaTotal_estimated ? other_helpers.get_pretty_num((capacity?.landBase?.buildings?.buildings[building_index]?.properties?.areaTotal_estimated * (capacity?.buildable?.volume?.parameters?.coef_area_to_shabsu || 1)).toFixed(2)) : "NC"} m²</div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        }
                        {/* SECTION PARKING */}
                        <div className="buildable__selected_section" style={{ transform: "translateX(-" + sectionOffset + "px)" }}>
                            <div className="buildable__selected_section_text">Parking extérieur</div>
                            <div className="buildable__selected_section_other_subcontainer">
                                <div className="buildable__selected_section_other_kpi_container" style={{ width: "33%" }}>
                                    <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(combinationsData.area_pk_ext.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                    <div className="buildable__selected_section_other_kpi_legend">Surface parking</div>
                                </div>
                                <div className="combi__card_info_body_section_separator"></div>
                                <div className="buildable__selected_section_other_kpi_container" style={{ width: "33%" }}>
                                    <div className="buildable__selected_section_other_kpi_value">{combinationsData.nb_spot_ext}</div>
                                    <div className="buildable__selected_section_other_kpi_legend">{combinationsData.nb_spot_ext > 1 ? "Places" : "Place"}</div>
                                </div>
                                <div className="combi__card_info_body_section_separator"></div>
                                <div className="buildable__selected_section_other_kpi_container" style={{ width: "33%" }}>
                                    <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(combinationsData.area_land_free.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                    <div className="buildable__selected_section_other_kpi_legend">Surface libre</div>
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                            <div className="buildable__selected_section_text">Parking en sous-sol</div>
                            <div className="buildable__selected_section_other_subcontainer">
                                <div className="buildable__selected_section_other_kpi_container" style={{ width: "33%" }}>
                                    <div className="buildable__selected_section_other_kpi_value">{other_helpers.get_pretty_num(combinationsData.area_pk_sub.toFixed(0))} <span style={{ fontSize: "12px" }}>m²</span></div>
                                    <div className="buildable__selected_section_other_kpi_legend">Surface parking</div>
                                </div>
                                <div className="combi__card_info_body_section_separator"></div>
                                <div className="buildable__selected_section_other_kpi_container" style={{ width: "33%" }}>
                                    <div className="buildable__selected_section_other_kpi_value">{combinationsData.nb_spot_sub}</div>
                                    <div className="buildable__selected_section_other_kpi_legend">{combinationsData.nb_spot_sub > 1 ? "Places" : "Place"}</div>
                                </div>
                                <div className="combi__card_info_body_section_separator"></div>
                                <div className="buildable__selected_section_other_kpi_container" style={{ width: "33%" }}>
                                    <div className="buildable__selected_section_other_kpi_value">{combinationsData.levels_sub}</div>
                                    <div className="buildable__selected_section_other_kpi_legend">{combinationsData.levels_sub > 1 ? "Niveaux" : "Niveau"}</div>
                                </div>
                            </div>
                            {/* UNDERGROUNDS
                            {combination?.parking?.ext?.area > 0 &&
                                <div className="buildable__selected_section_text">Parking extérieur : <strong>{other_helpers.get_pretty_num(combination?.parking?.ext?.area.toFixed(2))} m²</strong> soit environ <strong>{combination?.parking?.ext?.nb_spot}</strong> places</div>
                            }
                            {combination?.parking?.sub?.area > 0 &&
                                <div className="buildable__selected_section_text">Parking en sous-sol : <strong>{other_helpers.get_pretty_num(combination?.parking?.sub?.area.toFixed(2))} m²</strong> soit environ <strong>{combination?.parking?.sub?.nb_spot} places</strong>{combination?.undergrounds[0].levels.length > 1 ? ", réparties sur " + combination?.undergrounds[0].levels.length + " niveaux" : ""}</div>
                            } */}
                        </div>
                    </div>


                    {/* <div className="combi__card_info_legend">Superstructure</div>
                    <div className="combi__card_info_text">{combination.buildings.length} {combination.buildings.length > 1 ? "Bâtiments" : "Bâtiment"}</div>
                    <div className="combi__card_info_text"><strong>{other_helpers.get_pretty_num(combination.area.toFixed(2))} m²</strong></div>
                    <div className="combi__card_info_legend">Infrastructure</div>
                    <div className="combi__card_info_text">0 Niveau</div>
                    <div className="combi__card_info_text">0.00 m²</div> */}
                </div>
                <div className="buildable__selected_svg">
                    <svg width="100%" viewBox="-20 -20 840 840" preserveAspectRatio="xMidYMid meet" fillRule="evenodd" fill="none" xmlns="http://www.w3.org/2000/svg">
                        {/* Land svg */}
                        <polygon points={other_helpers.get_svgCoords_from_globalCoords(capacity?.landBase?.union?.geometry?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#AFC76B"} stroke={"red"} strokeWidth="0" />
                        {/* Underground svgs */}
                        {(undergrounds.length > 0) &&
                            <>
                                {undergrounds.map(underground => {
                                    return (
                                        <>
                                            {underground.levels.length > 0 && underground?.levels[0]?.polygon?.geometry?.type === "Polygon" &&
                                                <polygon points={other_helpers.get_svgCoords_from_globalCoords(underground?.levels[0]?.polygon?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#BDBDBD"} stroke={"grey"} opacity={`${selectedBuilding === buildingsAll.length ? "1" : "0.2"}`} strokeWidth="5" />
                                            }
                                            {underground.levels.length > 0 && underground?.levels[0]?.polygon?.geometry?.type === "MultiPolygon" &&
                                                <>
                                                    {underground?.levels[0]?.polygon?.geometry?.coordinates.map(polygon => {
                                                        return (
                                                            <polygon points={other_helpers.get_svgCoords_from_globalCoords(polygon, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#BDBDBD"} stroke={"grey"} opacity={`${selectedBuilding === buildingsAll.length ? "1" : "0.2"}`} strokeWidth="5" />
                                                        )
                                                    })}
                                                </>
                                            }
                                        </>
                                    )
                                })}
                            </>
                        }
                        {/* {combination?.parking?.sub?.area > 0 &&
                            <polygon points={other_helpers.get_svgCoords_from_globalCoords(combination?.parking?.sub?.levels[0]?.polygon?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#BDBDBD"} stroke={"grey"} opacity={`${selectedBuilding === combination.buildings.length ? "1" : "0.2"}`} strokeWidth="5" />
                        } */}
                        {/* Existing svgs */}
                        {(dataType === "all" && capacity?.landBase?.buildings?.buildings_land && capacity?.landBase?.buildings?.buildings_land.length > 0) &&
                            <>
                                {capacity.landBase.buildings.buildings_land.map((building_index) => {
                                    if (capacity.landBase.buildings.buildings[building_index]?.properties?.status === "keep") {
                                        return (
                                            // <polygon points={other_helpers.get_svgCoords_from_globalCoords(capacity.landBase.buildings.buildings[building_index]?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#062134"} stroke={"black"} opacity={"0.3"} strokeWidth="4" />
                                            <polygon points={other_helpers.get_svgCoords_from_globalCoords(capacity.landBase.buildings.buildings[building_index]?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#FFFFFF"} stroke={"grey"} opacity={`${(selectedBuilding === null || selectedBuilding === buildingsAll.length) ? "1" : "0.5"}`} strokeWidth="4" />
                                        )
                                    }
                                })}
                            </>
                        }
                        {/* Buildings svgs */}
                        {buildingsAll.map((footprint, index) => {
                            if (footprint?.levels && footprint?.levels.length > 0) {
                                return (
                                    <polygon points={other_helpers.get_svgCoords_from_globalCoords(footprint?.levels[0]?.polygon?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#FFFFFF"} stroke={"grey"} opacity={`${(selectedBuilding === null || selectedBuilding === index) ? "1" : (selectedBuilding === combination.buildings.length ? "0.3" : "0.5")}`} strokeWidth="5" />
                                )
                            }
                        })}
                    </svg>
                </div>
            </div>
        );
    }
};

export default Combination;