// Ajouter les popups les plus récents en haut de la liste
// Garder que la plus récente en isActive = true

const popup_list = [
    {
        id: "0544cac7bb1941689dc9ad9491894fb1",
        type: "release",
        params: {
            title: "🎉 Nouvelle version 2.0",
            // description: <><div>Cette nouvelle version ajoute les sous-sections dans la barre de navigation.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre ou sur la page <a className="popup__text_link" href="/release">Release Notes</a>.</div></>,
            description: <><div>Notre toute première version permettant la prise en compte des bâtiments existants.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre.</div></>,
        },
        audience: {
            users: ["all"],
            teams: ["all"],
        },
        isActive: true,
        isPersistent: false
    },
    {
        id: "0544cac7bb1941689dc9ad9491894fb4",
        type: "release",
        params: {
            title: "🎉 Nouvelle version 1.5",
            // description: <><div>Cette nouvelle version ajoute les sous-sections dans la barre de navigation.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre ou sur la page <a className="popup__text_link" href="/release">Release Notes</a>.</div></>,
            description: <><div>Cette nouvelle version enrichie grandement les données contextuelles en intégrant les commodités à proximité du terrain.<br />6 thématiques sont maintenant disponibles : Stationnement, Transport, Santé, Education, Commerce et Loisir.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre.</div></>,
        },
        audience: {
            users: ["all"],
            teams: ["all"],
        },
        isActive: false,
        isPersistent: false
    },
    {
        id: "b7d0001c66354b77b1b6888f93301c3d",
        type: "release",
        params: {
            title: "🎉 Nouvelle version 1.4",
            // description: <><div>Cette nouvelle version ajoute les sous-sections dans la barre de navigation.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre ou sur la page <a className="popup__text_link" href="/release">Release Notes</a>.</div></>,
            description: <><div>Cette nouvelle version apporte deux nouveautés :<br/>- le calcul automatique de la SHAB ou SU,<br />- l'affichage des surfaces de parcelles via le cadastre.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre.</div></>,
        },
        audience: {
            users: ["all"],
            teams: ["all"],
        },
        isActive: false,
        isPersistent: false
    },
    {
        id: "3e494a02753d440fb0a1d6411cf5aadg",
        type: "release",
        params: {
            title: "🎉 Nouvelle version 1.3",
            description: <><div>Cette nouvelle version améliore la navigation grâce aux sous-sections.</div><div className="popup__text_note">Découvrez toutes les nouveautés en cliquant sur le numéro de version ci-contre.</div></>,
        },
        audience: {
            users: ["all"],
            teams: ["all"],
        },
        isActive: false,
        isPersistent: false
    },
]

export const get_popup_list = () => {
    return popup_list
}