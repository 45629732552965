import * as turf from '@turf/turf';
import * as THREE from 'three';
import mapboxgl from 'mapbox-gl';
import { SkeletonBuilder } from 'straight-skeleton';
import * as map_helpers from '../Map_helpers';




const sources_ids_noland = ['sourceIGNBuildingsNoLand'];
const layers_ids_noland = ['layerIGNBuildingsNoLand_Points', 'layerIGNBuildingsNoLand_3D'];
const sources_ids_land_keep = ['sourceIGNBuildingsLandKeep'];
const layers_ids_land_keep = ['layerIGNBuildingsLandKeep_Points', 'layerIGNBuildingsLandKeep_3D'];
const sources_ids_land_erase = ['sourceIGNBuildingsLandErase'];
const layers_ids_land_erase = ['layerIGNBuildingsLandErase_Points', 'layerIGNBuildingsLandErase_3D'];

export const get_ids = (type) => {
    var sources_ids = [];
    var layers_ids = [];
    if (type === "noland") {
        sources_ids = sources_ids_noland;
        layers_ids = layers_ids_noland;
    }
    else if (type === "land_keep") {
        sources_ids = sources_ids_land_keep;
        layers_ids = layers_ids_land_keep;
    }
    else if (type === "land_erase") {
        sources_ids = sources_ids_land_erase;
        layers_ids = layers_ids_land_erase;
    }

    var sources = [];
    sources_ids.forEach(source_id => {
        sources.push({ id: source_id });
    })

    var layers = [];
    layers_ids.forEach(layer_id => {
        layers.push({ id: layer_id });
    })

    var sources_layers = {
        sources: sources,
        layers: layers
    }

    return sources_layers
}

export const get_data = (capacity, type, highlightedCombination) => {

    var raise_ids = [];
    if (highlightedCombination && highlightedCombination !== null && highlightedCombination.length > 0) {
        highlightedCombination.forEach(hl => {
            if (hl.id.includes("raise")) {
                var index_start = hl.id.indexOf("_");
                var index_end = hl.id.indexOf("-");
                if (index_start > 0 && index_end > index_start) {
                    try {
                        var zone_index = parseInt(hl.id.substring(index_start + 1,index_end));
                        var raise_index = capacity?.buildable?.combinations[zone_index][0]?.raise;
                        var building_index = capacity?.landBase?.buildings?.buildings_land[raise_index - 1];
                        if (building_index >= 0) {
                            raise_ids.push(building_index);
                        }
                    } catch (error) {
                        console.log("Error getting raise id");
                    }
                }
            }
        })
    }

    var sources_ids = [];
    var layers_ids = [];
    if (type === "noland") {
        sources_ids = sources_ids_noland;
        layers_ids = layers_ids_noland;
    }
    else if (type === "land_keep") {
        sources_ids = sources_ids_land_keep;
        layers_ids = layers_ids_land_keep;
    }
    else if (type === "land_erase") {
        sources_ids = sources_ids_land_erase;
        layers_ids = layers_ids_land_erase;
    }

    var map_data = {
        sources: [],
        layers: []
    }


    // // Preset layer data
    var layer_data = {
        type: 'FeatureCollection',
        features: [],
    };

    // // Add points
    // for (var i = 0; i < capacity.landBase.trees.items.length; i++) {
    //     if (capacity.landBase.trees.items[i]?.coords && capacity.landBase.trees.items[i].coords.length === 2) {
    //         layer_data.features.push({
    //             type: "Feature",
    //             properties: capacity.landBase.trees.items[i]?.properties,
    //             geometry: {
    //                 type: "Point",
    //                 coordinates: capacity.landBase.trees.items[i].coords,
    //             }
    //         });
    //     }
    // }
    // if (!layer_data.features.length > 0) {
    //     return map_data
    // }

    // Create sources
    map_data.sources.push(
        {
            id: sources_ids[0],
            value: {
                type: 'geojson',
                data: layer_data
            }
        }
    )

    // // Create layers
    // map_data.layers.push(
    //     {
    //         id: layers_ids[0],
    //         type: 'circle',
    //         source: sources_ids[0],
    //         paint: {
    //             'circle-radius': 4,
    //             'circle-color': '#062134'
    //         },
    //         filter: ['in', '$type', 'Point']
    //     }
    // )

    // 3D
    // Set point 0
    var point0 = capacity.landBase.union.bbox.origin;
    // Georeferencing
    var modelOrigin = point0;
    var modelAltitude = 0;
    var modelRotate = [0, 0, 0];
    var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
        modelOrigin,
        modelAltitude
    );
    // Set position, rotation and scale
    var modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()
    };
    // Set THREE
    var customLayer = {
        id: layers_ids[1],
        type: 'custom',
        source: sources_ids[0],
        renderingMode: '3d',
        onAdd: function (map, gl) {
            this.camera = new THREE.Camera();
            this.scene = new THREE.Scene();
            // Create lights
            var directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.5);
            // directionalLight1.position.set(-100, 20, -100).normalize();
            directionalLight1.position.x = -10;
            directionalLight1.position.y = -10;
            directionalLight1.position.z = 20;
            this.scene.add(directionalLight1);

            var directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.3);
            // directionalLight1.position.set(-100, 20, -100).normalize();
            directionalLight2.position.x = 10;
            directionalLight2.position.y = 10;
            directionalLight2.position.z = 20;
            this.scene.add(directionalLight2);

            var ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
            this.scene.add(ambientLight);


            // Loop th all buildings
            // var type = "land_keep";
            for (var j = 0; j < capacity.landBase.buildings.buildings.length; j++) {
                var building = capacity.landBase.buildings.buildings[j];

                // Check type
                var test_type = false;
                if (type === "noland" && !(capacity?.landBase?.buildings?.buildings_land && capacity.landBase.buildings.buildings_land.includes(j))) {
                    test_type = true;
                }
                if (type === "land_keep" && building?.properties?.demolition === false) {
                    test_type = true;
                }
                if (type === "land_erase" && building?.properties?.demolition === true) {
                    test_type = true;
                }
                if (!test_type) {
                    continue;
                }
                // console.log("BUILDING", building);

                // Get local coordinates
                var building_coords_global = building["geometry"]["coordinates"][0];
                if (building["geometry"]["type"] === "MultiPolygon") {
                    building_coords_global = building["geometry"]["coordinates"][0][0];
                }
                var building_coords = [];
                for (var i = 0; i < building_coords_global.length; i++) {
                    var deltaX = (turf.distance(turf.point(building_coords_global[i]), turf.point([point0[0], building_coords_global[i][1]])) * 1000);
                    if (building_coords_global[i][0] < point0[0]) {
                        deltaX = -deltaX;
                    }
                    var deltaY = (turf.distance(turf.point(building_coords_global[i]), turf.point([building_coords_global[i][0], point0[1]])) * 1000);
                    if (building_coords_global[i][1] < point0[1]) {
                        deltaY = -deltaY;
                    }
                    building_coords.push([deltaX, deltaY]);
                }
                // Shape and Geometry
                var landShape = new THREE.Shape();
                landShape.moveTo(building_coords[0][0], building_coords[0][1]);
                for (var i = 0; i < building_coords.length; i++) {
                    landShape.lineTo(building_coords[i][0], building_coords[i][1]);
                }
                var height = building?.properties?.height || 1;
                if (type !== "land_keep" && building?.properties?.height_max > height) {
                    height = building.properties.height_max;
                }
                var landExtrudeSettings = {
                    steps: 1,
                    depth: height,
                    bevelEnabled: false
                };
                var landGeometry = new THREE.ExtrudeGeometry(landShape, landExtrudeSettings);
                // var color = 'red';
                var color = '#F1F1F1';
                var opacity_var = 1;
                if (type === "land_erase") {
                    opacity_var = 0.2;
                }
                if (type === "land_keep" && highlightedCombination !== null && highlightedCombination.length > 0 && highlightedCombination[0].section !== null && highlightedCombination[0].section !== "existing") {
                    opacity_var = 0.2;
                }
                // if (building.properties.type === "matched") {
                //     color = 'green';
                // }
                // else if (building.properties.type === "osm") {
                //     color = 'blue';
                // }
                // else if (building.properties.type === "ign") {
                //     color = 'yellow';
                // }
                var landMaterial = new THREE.MeshLambertMaterial({
                    color: color,
                    transparent: true,
                    opacity: opacity_var,
                    side: THREE.DoubleSide,
                    // wireframe: true
                });
                var landMesh = new THREE.Mesh(landGeometry, landMaterial);
                this.scene.add(landMesh);
                // Edges
                if (type === "land_erase") {
                    var edgesGeometry = new THREE.EdgesGeometry(landGeometry);
                    var edgesMesh = new THREE.LineSegments(edgesGeometry, new THREE.LineDashedMaterial({ color: "#F5F5F5", dashSize: 1, gapSize: 0.6 }));
                    edgesMesh.computeLineDistances();
                    this.scene.add(edgesMesh);
                }

                // CREATE ROOF MESH
                if (type === "land_keep" && !raise_ids.includes(j) && building?.properties?.height_max > building?.properties?.height) {
                    // if (level_index === building.levels.length - 1 && capacity?.buildable?.volume?.parameters?.roof_angle > 0) {
                    try {
                        var skeleton_coords = [...building_coords];
                        skeleton_coords.splice(-1);
                        // SKELETON
                        const skeleton = SkeletonBuilder.BuildFromGeoJSON([[skeleton_coords]]);

                        // Get skeletyon points list
                        var angle = 0;
                        var skeleton_points = [];
                        var point_id = 0;
                        var elevation_max = 0;
                        var distance_max = 0;
                        for (const [pointPos, distance] of skeleton.Distances) {
                            if (distance > distance_max) { distance_max = distance; }
                        }
                        if (distance_max > 0) {
                            angle = 45 * (building?.properties?.height_max - building?.properties?.height) / distance_max;
                        }
                        // console.log("distance_max", distance_max);
                        // console.log("angle", angle);
                        if (angle > 0) {
                            for (const [pointPos, distance] of skeleton.Distances) {
                                var coords_local = [pointPos.X, pointPos.Y];
                                var coords_global = map_helpers.local_to_mercator(coords_local, capacity.landBase.union.center.geometry.coordinates, capacity.landBase.union.bbox.matrix)
                                var turf_point = turf.point(coords_global);
                                var elevation_new = building?.properties?.height + ((angle / 45) * distance);
                                turf_point.properties.id = point_id;
                                turf_point.properties.elevation = elevation_new;
                                turf_point.properties.coords_local = coords_local;
                                skeleton_points.push(turf_point);

                                if (elevation_new > elevation_max) {
                                    elevation_max = elevation_new;
                                }

                                point_id++;
                            }
                            // console.log("skeleton_points", skeleton_points);

                            // Get skeleton polygons
                            var skeleton_polys = [];
                            for (const edgeResult of skeleton.Edges) {
                                const poly = edgeResult.Polygon;
                                var skeleton_poly_points = [];
                                for (var sk_point_index = 0; sk_point_index < poly.length; sk_point_index++) {
                                    // Get point id
                                    var sk_poly_id = null;
                                    for (var sk_pointlist_index = 0; sk_pointlist_index < skeleton_points.length; sk_pointlist_index++) {
                                        if (poly[sk_point_index].X === skeleton_points[sk_pointlist_index].properties.coords_local[0] && poly[sk_point_index].Y === skeleton_points[sk_pointlist_index].properties.coords_local[1]) {
                                            sk_poly_id = sk_pointlist_index;
                                            break;
                                        }
                                    }
                                    skeleton_poly_points.push(sk_poly_id);
                                }
                                var skeleton_poly = {
                                    points: skeleton_poly_points,
                                }
                                skeleton_polys.push(skeleton_poly);
                            }
                            // console.log("skeleton_polys", skeleton_polys);

                            // Get grid
                            var skeleton_tin = [];
                            skeleton_polys.forEach(poly => {
                                var points_list = [];
                                var coords_global = [];
                                poly.points.forEach(point_id => {
                                    if (point_id !== null) {
                                        points_list.push(skeleton_points[point_id]);
                                        coords_global.push(skeleton_points[point_id].geometry.coordinates);
                                    }
                                })
                                coords_global.push(coords_global[0]);
                                if (points_list.length >= 3) {
                                    var skeleton_polygon = turf.polygon([coords_global]);
                                    var tin = turf.tin({ features: points_list }, "id");
                                    // Check if poly is inside skeleton polygon
                                    var tin_filtered = [];
                                    for (var tin_index = 0; tin_index < tin.features.length; tin_index++) {
                                        var centroid = turf.centroid(tin.features[tin_index]);
                                        var isInside = turf.booleanPointInPolygon(centroid, skeleton_polygon);
                                        if (isInside === true) {
                                            tin_filtered.push(tin.features[tin_index]);
                                        }
                                    }
                                    skeleton_tin = skeleton_tin.concat(tin_filtered);
                                }
                            })
                            // console.log("skeleton_tin", skeleton_tin);

                            // Create 3D shape
                            var vertices_list = [];
                            for (var i = 0; i < skeleton_tin.length; i++) {
                                // Vertice a
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.coords_local[0]);
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.coords_local[1]);
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.a].properties.elevation);
                                // Vertice b
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.coords_local[0]);
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.coords_local[1]);
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.b].properties.elevation);
                                // Vertice c
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.coords_local[0]);
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.coords_local[1]);
                                vertices_list.push(skeleton_points[skeleton_tin[i].properties.c].properties.elevation);
                            }
                            // console.log("vertices_list", vertices_list);
                            var vertices = new Float32Array(vertices_list);
                            // Set vertices to geometry
                            var geometry = new THREE.BufferGeometry();
                            geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
                            // Compute vertex normals
                            geometry.computeVertexNormals();
                            // Create material
                            var material = new THREE.MeshPhongMaterial({
                                color: '#F1F1F1',
                                side: THREE.DoubleSide,
                                transparent: true,
                                opacity: opacity_var,
                            });
                            // Create mesh
                            var mesh = new THREE.Mesh(geometry, material);
                            // console.log("mesh", mesh);
                            this.scene.add(mesh);
                            // Create edges
                            var edgesGeometry = new THREE.EdgesGeometry(mesh.geometry);
                            var edgesMesh = new THREE.LineSegments(edgesGeometry, new THREE.LineBasicMaterial({ color: "#F5F5F5", transparent: false, opacity: 1 }));
                            // console.log("edgesMesh", edgesMesh);
                            this.scene.add(edgesMesh);
                        }

                    } catch (error) {
                        console.log("ERROR CREATING ROOF", error);
                    }
                }
            }







            // Add map
            this.map = map;
            // use the Mapbox GL JS map canvas for three.js
            this.renderer = new THREE.WebGLRenderer({
                canvas: map.getCanvas(),
                context: gl,
                antialias: true
            });
            // this.renderer.shadowMap.enabled = true;
            // this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
            this.renderer.autoClear = false;




        },
        render: function (gl, matrix) {
            var rotationX = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(1, 0, 0),
                modelTransform.rotateX
            );
            var rotationY = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 1, 0),
                modelTransform.rotateY
            );
            var rotationZ = new THREE.Matrix4().makeRotationAxis(
                new THREE.Vector3(0, 0, 1),
                modelTransform.rotateZ
            );
            var m = new THREE.Matrix4().fromArray(matrix);
            var l = new THREE.Matrix4()
                .makeTranslation(
                    modelTransform.translateX,
                    modelTransform.translateY,
                    modelTransform.translateZ
                )
                .scale(
                    new THREE.Vector3(
                        modelTransform.scale,
                        -modelTransform.scale,
                        modelTransform.scale
                    )
                )
                .multiply(rotationX)
                .multiply(rotationY)
                .multiply(rotationZ);

            this.camera.projectionMatrix = m.multiply(l);
            this.renderer.resetState();
            this.renderer.render(this.scene, this.camera);
            // this.map.triggerRepaint();
        }
    };
    map_data.layers.push(customLayer);





    // Return
    console.log("map_data", map_data);
    return map_data

}

