import plan_topo from '../../assets/release/release_plan_topo.gif';
import point_moyen from '../../assets/release/release_point_moyen.png';
import export_pdf_1 from '../../assets/release/release_export_pdf_1.gif';
import export_ifc_1 from '../../assets/release/release_export_ifc_1.gif';
import submenu from '../../assets/release/release_submenu.gif';
import shab_su from '../../assets/release/release_shab_su.gif';
import land_area from '../../assets/release/release_land_area.gif';
import commodities from '../../assets/release/release_commodities.gif';
import existing_1 from '../../assets/release/release_existing_1.gif';
import existing_2 from '../../assets/release/release_existing_2.png';


export const get_release_data = () => {
    const release_data = [
        {
            version: "2.0",
            date: "10/02/2025",
            title: `Prise en compte des bâtiments existants`,
            type: "majeure",
            detail: {
                features: [
                    {
                        title: "Possibilité de conserver une construction existante",
                        description: "Cette fonctionnalité permet de baculer une construction existante au statut 'Conserver'.\nAinsi, plusieurs paramètres sont précalculés et modifiables :\n- La possibilité de réaliser une surélévation de la construction.\n- Le nombre de niveaux.\n- La surface construite totale.",
                        img: {
                            src: existing_1,
                            max_w: 600
                        },
                    },
                    {
                        title: "Calcul du potentiel inexploité du terrain",
                        description: "Cette fonctionnalité permet de calculer la surface constructible restante en prenant en compte les constructions existantes conservées.",
                        img: {
                            src: existing_2,
                            max_w: 600
                        },
                    },
                ],
                updates: [],
                fixes: []
            }
        },
        {
            version: "1.5",
            date: "26/11/2024",
            title: `Nouveau module contextuel : commodités à proximité du terrain`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Enrichissement du module 'Commodités à proximité du terrain'",
                        description: "Cette fonctionnalité permet une analyse plus approfondie du contexte.\nNous avons enrichie le module détaillant les commodités à proximité immédiate du terrain (entre 500m et 1km selon les thématiques).\nNous proposons maintenant 6 thématiques :\n- Stationnement : analyse les stationnements publics et les bornes de recharge.\n- Transport : analyse les lignes de transport en commun, les gares et les stations de vélo.\n- Santé : analyse les établissements de soin, cabinets médicaux et pharmacies.\n- Education : analyse les établissements d'enseignement (crèche, primaire, secondaire, suppérieur).\n- Commerce : analyse les centres commerciaux et supermarchés.\n- Loisirs : analyse les parcs et jardins publics ; les restaurants, bars et café, ainsi que les établissements culturels (bibliothèques, cinémas, théatres...).\n\nPour bénéficier de cette fonctionnalité sur vos études existantes, vous devez 'Mettre à jour les données externes' de la section 'Contexte'.",
                        img: {
                            src: commodities,
                            max_w: 600
                        },
                    },
                ],
                updates: [],
                fixes: []
            }
        },
        {
            version: "1.4",
            date: "30/10/2024",
            title: `Calcul automatique de la SHAB ou SU, et affichage des surfaces de parcelles issues du cadastre`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Calcul de la SHAB / SU",
                        description: "Cette fonctionnalité permet le calcul automatique de la SHAB ou SU à partir de la surface construite des simulations.\nUne nouvelle variable est accessible permettant de définir un coefficient 'Surface Construite → SHAB/SU'.",
                        img: {
                            src: shab_su,
                            max_w: 500
                        },
                    },
                ],
                updates: [
                    {
                        title: "Affichage des surfaces de parcelles issues du cadastre",
                        description: "Cette fonctionnalité permet d'afficher les surfaces de parcelles directement issues du cadastre.\nCes surfaces sont affichées à titre indicatif, pour grader une cohérence vis-à-vis du cadastre. Mais celles-ci étant peu précises, nous recalculons les surfaces de chaque parcelle.\nPour switcher entre les valeurs 'issues du cadastre' et celles 'recalculées', une nouvelle option est disponible dans la section 'Terrain d'assiette'.\nNB: Nos valeurs recalculées sont très proches de celles recalculées par le Géoportail de l'Urbanisme. Un léger écrat peu être constaté avec les valeurs issues du cadastre.",
                        img: {
                            src: land_area,
                            max_w: 500
                        },
                    },
                ],
                fixes: []
            }
        },
        {
            version: "1.3",
            date: "18/10/2024",
            title: `Ajout des sous-sections dans la barre de navigation`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Sous-sections",
                        description: "Cette fonctionnalité permet l'affichage des sous-sections dans la barre de navigation.\nEn restant une seconde sur un bouton 'section', les sous-sections s'affichent dans un conteneur sur la droite du bouton.\nCela permet une navigation plus rapide et plus précise dans l'application.",
                        img: {
                            src: submenu,
                            max_w: 600
                        },
                    },
                ],
                updates: [],
                fixes: [
                    {
                        title: "Affichage des menus d'export PDF et IFC",
                        description: "Ce correctif corrige l'affichage des menus d'export PDF et IFC sur Safari.",
                    },
                ]
            }
        },
        {
            version: "1.2",
            date: "14/10/2024",
            title: `Amélioration du module "Contexte Topographique"`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Plan topographique",
                        description: "Cette fonctionnalité permet la création et l'affichage du plan topographique. Il affiche tous les points topographiques à l'intérieur du terrain, les bornes topographiques ainsi que les lignes de niveau.\nCe plan est visble en affichant la couche 'Plan topo' sur la carte.\n Chaque point et chaque borne est cliquable, ce qui permet d'afficher les coordionnées exactes de l'élément.\nPour bénéficier de cette fonctionnalité sur vos études existantes, vous devez 'Mettre à jour les données externes' de la section 'Terrain'.",
                        img: {
                            src: plan_topo,
                            max_w: 600
                        },
                    },
                    {
                        title: "Point topographique moyen",
                        description: "Cette fonctionnalité permet le calcul du point topographique moyen du terrain.\nIl est défini en calculant la moyenne des altitudes de tous les points topographiques à l'intérieur du terrain.\nCe point est utilisé comme niveau 0 local du projet.\nPour bénéficier de cette fonctionnalité sur vos études existantes, vous devez 'Mettre à jour les données externes' de la section 'Terrain'.",
                        img: {
                            src: point_moyen,
                            max_w: 600
                        },
                    },
                ],
                updates: [
                    {
                        title: "Mise à jour de l'API Géoplateforme",
                        description: "Cette mise à jour permet à notre application de suivre la migration du service Géoportail vers Géoplateforme.\nNous utilisons les données de cette API pour obtenir les altitudes des points topo du terrain, et calculer les distances couvertes autour du terrain en 5 minutes en voiture et 10 minutes à pied.",
                    },
                ],
                fixes: []
            }
        },
        {
            version: "1.1",
            date: "03/10/2024",
            title: `Première version des modules "Export PDF" et "Export IFC"`,
            type: "mineure",
            detail: {
                features: [
                    {
                        title: "Export PDF",
                        description: "Cette fonctionnalité permet d'exporter les informations de l'étude capacitaire sous forme de rapport PDF.\nDeux templates sont disponibles : un template standard 'Proplab' et un tempate personnalisé qui s'adapte au logo et aux couleurs de votre société.\nCette première version du module d'export permet d'exporter les données concernant la localisation du terrain.",
                        img: {
                            src: export_pdf_1,
                            max_w: 600
                        },
                    },
                    {
                        title: "Export IFC",
                        description: "Cette fonctionnalité permet d'exporter certains éléments de l'étude capacitaire dans une maquette BIM au format IFC.\nNotre module d'export IFC génère des fichiers certifiés valides par Building Smart International (cf. https://www.buildingsmart.org/users/services/ifc-validation-service).\nCette première version du module d'export permet d'exporter les données concernant la parcelle et la topographie 3D, au format IFC 2X3.",
                        img: {
                            src: export_ifc_1,
                            max_w: 600
                        },
                    },
                ],
                updates: [],
                fixes: []
            }
        },
        {
            version: "1.0.8",
            date: "25/09/2024",
            title: "Géolocalisation",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.7",
            date: "18/09/2024",
            title: "Intégration des données SITADEL",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.6",
            date: "30/08/2024",
            title: "Intégration des données SRU",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.5",
            date: "29/08/2024",
            title: "Prise en compte des parkings dans les règles PLU",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0.3",
            date: "01/08/2024",
            title: "Correction d'un bug sur la génération 3D des prospects",
            type: "corrective",
            detail: {}
        },
        {
            version: "1.0.2",
            date: "26/07/2024",
            title: "Possibilité de dupliquer une étude capacitaire",
            type: "mineure",
            detail: {}
        },
        {
            version: "1.0",
            date: "26/07/2024",
            title: "Première version de l'application",
            type: "majeure",
            detail: {}
        },
    ]

    return release_data
}