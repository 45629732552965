import React, { useState, useEffect } from 'react';

// Import CSS & assets
import './Buildings.css';
import ico_buildings_area from '../../../../assets/ico/ico_buildings_area.svg';
import ico_buildings_level from '../../../../assets/ico/ico_buildings_level.svg';
import ico_buildings_hmin from '../../../../assets/ico/ico_buildings_hmin.svg';
import ico_buildings_hmax from '../../../../assets/ico/ico_buildings_hmax.svg';
import ico_buildings_elevation from '../../../../assets/ico/ico_buildings_elevation.svg';
import ico_buildings_wall from '../../../../assets/ico/ico_buildings_wall.svg';
import ico_buildings_roof from '../../../../assets/ico/ico_buildings_roof.svg';
import ico_buildings_area_facade from '../../../../assets/ico/ico_buildings_area_facade.svg';
import ico_buildings_area_window from '../../../../assets/ico/ico_buildings_area_window.svg';
import ico_buildings_keep_blue from '../../../../assets/ico/ico_buildings_keep_blue.svg';
import ico_buildings_keep_white from '../../../../assets/ico/ico_buildings_keep_white.svg';
import ico_buildings_reno_blue from '../../../../assets/ico/ico_buildings_reno_blue.svg';
import ico_buildings_reno_white from '../../../../assets/ico/ico_buildings_reno_white.svg';
import ico_buildings_demol_blue from '../../../../assets/ico/ico_buildings_demol_blue.svg';
import ico_buildings_demol_white from '../../../../assets/ico/ico_buildings_demol_white.svg';
import dpe_A from '../../../../assets/dpe_A.svg';
import dpe_B from '../../../../assets/dpe_B.svg';
import dpe_C from '../../../../assets/dpe_C.svg';
import dpe_D from '../../../../assets/dpe_D.svg';
import dpe_E from '../../../../assets/dpe_E.svg';
import dpe_F from '../../../../assets/dpe_F.svg';
import dpe_G from '../../../../assets/dpe_G.svg';
import dpe_range from '../../../../assets/dpe_range.svg';


// Import external functions
import * as other_helpers from '../../../../helpers/Other_helpers';


// Lists
const materials_wall = {
    // "0": "Indéterminé",
    "1": "Pierre",
    "2": "Meulière",
    "3": "Béton",
    "4": "Brique",
    "5": "Aggloméré",
    "6": "Bois",
    // "9": "Indéterminé",
    // "00": "Indéterminé",
    "01": "Pierre",
    "02": "Meulière",
    "03": "Béton",
    "04": "Brique",
    "05": "Aggloméré",
    "06": "Bois",
    // "09": "Indéterminé",
    "10": "Pierre",
    "11": "Pierre",
    "12": "Meulière",
    "13": "Béton et Pierre",
    "14": "Brique et Pierre",
    "15": "Aggloméré et Pierre",
    "16": "Bois et Pierre",
    "19": "Pierre",
    "20": "Meulière",
    "21": "Meulière et Pierre",
    "22": "Meulière",
    "23": "Béton et Meulière",
    "24": "Brique et Meulière",
    "25": "Aggloméré et Meulière",
    "26": "Bois et Meulière",
    "29": "Meulière",
    "30": "Béton",
    "31": "Béton et Pierre",
    "32": "Béton et Meulière",
    "33": "Béton",
    "34": "Béton et Brique",
    "35": "Aggloméré et Béton",
    "36": "Béton et Bois",
    "39": "Béton",
    "40": "Brique",
    "41": "Brique et Pierre",
    "42": "Brique et Meulière",
    "43": "Béton et Brique",
    "44": "Brique",
    "45": "Aggloméré et Brique",
    "46": "Bois et Brique",
    "49": "Brique",
    "50": "Aggloméré",
    "51": "Aggloméré et Pierre",
    "52": "Aggloméré et Meulière",
    "53": "Aggloméré et Béton",
    "54": "Aggloméré et Brique",
    "55": "Aggloméré",
    "56": "Aggloméré et Bois",
    "59": "Aggloméré",
    "60": "Bois",
    "61": "Bois et Pierre",
    "62": "Bois et Meulière",
    "63": "Béton et Bois",
    "64": "Bois et Brique",
    "65": "Aggloméré et Bois",
    "66": "Bois",
    "69": "Bois",
    // "90": "Indéterminé",
    "91": "Pierre",
    "92": "Meulière",
    "93": "Béton",
    "94": "Brique",
    "95": "Aggloméré",
    "96": "Bois",
    // "99": "Indéterminé",
};

const materials_roof = {
    // "0": "Indéterminé",
    "1": "Tuiles",
    "2": "Ardoises",
    "3": "Zinc",
    "4": "Béton",
    // "9": "Indéterminé",
    // "00": "Indéterminé",
    "01": "Tuiles",
    "02": "Ardoises",
    "03": "Zinc",
    "04": "Béton",
    // "09": "Indéterminé",
    "10": "Tuiles",
    "11": "Tuiles",
    "12": "Ardoises et Tuiles",
    "13": "Tuiles et Zinc",
    "14": "Béton et Tuiles",
    "19": "Tuiles",
    "20": "Ardoises",
    "21": "Ardoises et Tuiles",
    "22": "Ardoises",
    "23": "Ardoises et Zinc",
    "24": "Ardoises et Béton",
    "29": "Ardoises",
    "30": "Zinc",
    "31": "Tuiles et Zinc",
    "32": "Ardoises et Zinc",
    "33": "Zinc",
    "34": "Béton et Zinc",
    "39": "Zinc",
    "40": "Béton",
    "41": "Béton et Tuiles",
    "42": "Ardoises et Béton",
    "43": "Béton et Zinc",
    "44": "Béton",
    "49": "Béton",
    // "90": "Indéterminé",
    "91": "Tuiles",
    "92": "Ardoises",
    "93": "Zinc",
    "94": "Béton",
    // "99": "Indéterminé",
};

const risque_canicule = [
    {
        label: "Très faible",
        description: "Le bâtiment est très peu exposé à une surchauffe estivale"
    },
    {
        label: "Faible",
        description: "Le bâtiment est peu exposé à une surchauffe estivale"
    },
    {
        label: "Moyen",
        description: "Le bâtiment est moyennement exposé à une surchauffe estivale"
    },
    {
        label: "Élevé",
        description: "Le bâtiment est exposé à une surchauffe estivale"
    },
    {
        label: "Très élevé",
        description: "Le bâtiment est très exposé à une surchauffe estivale"
    },
]

const dpe_labels = ["A", "B", "C", "D", "E", "F", "G"];

const Building = ({ capacity, setCapacity, setBuildingUpdateTracker, item, item_index, index }) => {

    const [section, setSection] = useState(0);
    const [section_left, setSection_left] = useState(0);
    const [section_width, setSection_width] = useState(90);
    useEffect(() => {
        var section_title = document.getElementById("buildings_info_section" + item.properties.id + "_" + section);
        setSection_left(section_title.offsetLeft);
        setSection_width(section_title.offsetWidth);
        // setSection_left(section_title.offsetLeft + 4);
        // setSection_width(section_title.offsetWidth - 8);
    }, [section])


    const [status, setStatus] = useState(item?.properties?.status || "demolition");
    const [status_left, setStatus_left] = useState(0);
    const [status_width, setStatus_width] = useState(0);

    const handleStatusChange = (newStatus) => {
        console.log("Changed for " + item_index + " to", newStatus);

        var new_landBase = { ...capacity.landBase };
        new_landBase.buildings.buildings[item_index].properties.status = newStatus;
        // Estimated Built Area
        var builtArea_estimated = new_landBase.buildings.buildings[item_index]?.properties?.area || 0;
        var nbLevel_estimated = 1;
        if (new_landBase.buildings.buildings[item_index]?.properties?.area > 0 && new_landBase.buildings.buildings[item_index]?.properties?.ign_properties?.nombre_d_etages) {
            nbLevel_estimated = new_landBase.buildings.buildings[item_index]?.properties?.ign_properties?.nombre_d_etages;
            builtArea_estimated = new_landBase.buildings.buildings[item_index]?.properties?.area * new_landBase.buildings.buildings[item_index]?.properties?.ign_properties?.nombre_d_etages;
        }
        else if (new_landBase.buildings.buildings[item_index]?.properties?.area > 0 && new_landBase.buildings.buildings[item_index]?.properties?.height) {
            nbLevel_estimated = Math.max(1, Math.round(new_landBase.buildings.buildings[item_index]?.properties?.height / 3));
            builtArea_estimated = new_landBase.buildings.buildings[item_index]?.properties?.area * nbLevel_estimated;
        }
        new_landBase.buildings.buildings[item_index].properties.areaTotal_estimated = builtArea_estimated;
        new_landBase.buildings.buildings[item_index].properties.nbLevel_estimated = nbLevel_estimated;

        // Raisable
        new_landBase.buildings.buildings[item_index].properties.raisable = true;

        var isDemolition = false;
        if (newStatus === "demolition") {
            isDemolition = true;
        }
        new_landBase.buildings.buildings[item_index].properties.demolition = isDemolition;

        var status_text = "démolition";
        if (newStatus === "keep") { status_text = "conservation en l'état" }
        if (newStatus === "renovation") { status_text = "rénovation" }

        // Update hook & indicator
        setStatus(newStatus);
        setIsRaisable(true);

        // Get bounds type
        if (!new_landBase.buildings.buildings[item_index]?.properties?.bounds_type?.mit_distance >= 0) {
            var new_buildings = other_helpers.get_existing_buildings_bound_type({ ...capacity, landBase: new_landBase });
            if (new_buildings !== null) {
                new_landBase.buildings.buildings = new_buildings;
            }
        }

        // Update capacity
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("landBase");
        toSaveData.autoSave = false;
        toSaveData.action = "Modification du statut de la construction existante N°" + (index + 1) + " à";
        toSaveData.value = status_text;
        setCapacity({ ...capacity, landBase: new_landBase, toSave: toSaveData });

        // Track updates
        setBuildingUpdateTracker((Date.now()).toString());
    }

    useEffect(() => {
        var status_title = document.getElementById("buildings_info_status" + item.properties.id + "_" + status);
        if (status_title !== null) {
            setStatus_left(status_title.offsetLeft - 14);
            setStatus_width(status_title.offsetWidth + 28);
        }
    }, [status, item])

    useEffect(() => {
        console.log("CHANGED STATUS of", item_index, item?.properties?.status);
        // Update hook & indicator
        setStatus(item?.properties?.status);
        setIsRaisable(item?.properties?.raisable);
    }, [item.properties.status])


    const handleCheckboxChange = (building_index, checked) => {
        console.log("Changed for " + building_index + " to", checked);

        var new_landBase = { ...capacity.landBase };
        new_landBase.buildings.buildings[building_index].properties.demolition = checked;

        // Update capacity
        setCapacity({ ...capacity, landBase: new_landBase });

        // Track updates
        setBuildingUpdateTracker((Date.now()).toString());
    }



    var wall_mat = undefined;
    if (item?.properties?.ign_properties?.materiaux_des_murs && item?.properties?.ign_properties?.materiaux_des_murs !== null && Object.keys(materials_wall).includes(item?.properties?.ign_properties?.materiaux_des_murs)) {
        wall_mat = materials_wall[item.properties.ign_properties.materiaux_des_murs];
    }
    var roof_mat = undefined;
    if (item?.properties?.ign_properties?.materiaux_de_la_toiture && item?.properties?.ign_properties?.materiaux_de_la_toiture !== null && Object.keys(materials_roof).includes(item?.properties?.ign_properties?.materiaux_de_la_toiture)) {
        roof_mat = materials_roof[item.properties.ign_properties.materiaux_de_la_toiture];
    }
    var isSelected = false;
    if (item?.properties?.demolition === true) {
        isSelected = true;
    }

    var sections = ["Caractéristiques", "Surfaces", "Hauteurs"];
    if (item?.properties?.bdnb_details?.surface_facade_totale > 0) {
        sections.push("Façades");
    }
    if (wall_mat !== undefined || roof_mat !== undefined) {
        sections.push("Matériaux");
    }
    if (item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple) {
        if (item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_source === "dpe simule") {
            sections.push("DPE (simulé)");
        }
        else {
            sections.push("DPE");
        }
        if (item.properties?.bdnb_details?.vf_m2_relative_cat) {
            sections.push("Plus-value verte");
        }
    }

    const get_isRaisable = () => {
        if (Object.keys(item.properties).includes("raisable") && item.properties.raisable === false) {
            console.log("RAISABLE", false);
            return false
        }
        else {
            console.log("RAISABLE", true);
            return true
        }
    }
    const [isRaisable, setIsRaisable] = useState(() => { get_isRaisable() });
    const raisableChange = (newValue) => {
        setIsRaisable(newValue);

        // Update capacity
        var toSaveData = { ...capacity.toSave };
        if (!toSaveData?.keys) { toSaveData.keys = [] }
        toSaveData.keys.push("landBase");
        toSaveData.autoSave = false;
        toSaveData.action = "";
        toSaveData.value = null;
        var new_landBase = { ...capacity.landBase };
        new_landBase.buildings.buildings[item_index].properties.raisable = newValue;
        // setCapacity({ ...capacity, landBase: new_landBase });
        setCapacity({ ...capacity, landBase: new_landBase, toSave: toSaveData });

        // Track updates
        setBuildingUpdateTracker((Date.now()).toString());
    }

    const updateInput_nbLevel = (newValue) => {
        if (parseInt(newValue) !== capacity.landBase.buildings.buildings[item_index]?.properties?.nbLevel_estimated) {
            console.log("UPDATE BUILDING BUILT DATA");
            // Update capacity
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("landBase");
            toSaveData.autoSave = false;
            toSaveData.action = "";
            toSaveData.value = null;
            var new_landBase = { ...capacity.landBase };
            new_landBase.buildings.buildings[item_index].properties.nbLevel_estimated = parseInt(newValue);
            var areaTotal_est = parseFloat((parseInt(newValue) * (new_landBase.buildings.buildings[item_index].properties?.area || 0)).toFixed(2));
            new_landBase.buildings.buildings[item_index].properties.areaTotal_estimated = areaTotal_est;
            // setCapacity({ ...capacity, landBase: new_landBase });
            setCapacity({ ...capacity, landBase: new_landBase, toSave: toSaveData });

            // Update areaTotal estimated input
            var areaTotal_estimated_input = document.getElementById("input_areaTotal_C" + item_index);
            if (areaTotal_estimated_input) { areaTotal_estimated_input.value = areaTotal_est }

            // Track updates
            setBuildingUpdateTracker((Date.now()).toString());
        }
    }

    const updateInput_areaTotal = (newValue) => {
        if (parseFloat(newValue) !== capacity.landBase.buildings.buildings[item_index]?.properties?.areaTotal_estimated) {
            console.log("UPDATE BUILDING BUILT DATA");
            // Update capacity
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("landBase");
            toSaveData.autoSave = false;
            toSaveData.action = "";
            toSaveData.value = null;
            var new_landBase = { ...capacity.landBase };
            var areaTotal_est = parseFloat(parseFloat(newValue).toFixed(2));
            new_landBase.buildings.buildings[item_index].properties.areaTotal_estimated = areaTotal_est;
            // setCapacity({ ...capacity, landBase: new_landBase });
            setCapacity({ ...capacity, landBase: new_landBase, toSave: toSaveData });

            // Update areaTotal estimated input
            var areaTotal_estimated_input = document.getElementById("input_areaTotal_C" + item_index);
            if (areaTotal_estimated_input) { areaTotal_estimated_input.value = areaTotal_est.toFixed(2) }

            // Track updates
            setBuildingUpdateTracker((Date.now()).toString());
        }
    }

    return (
        <div>
            <div className="app__sectiondata__widget">
                {/* <div className="app__buildings_value_main">Construction N°{index + 1} • Typologie : {item.properties.ign_properties === null ? "Indifférencié" : item.properties.ign_properties.usage_1}{item.properties.ign_properties?.construction_legere === true && " • Construction légère"}</div> */}
                <div className="app__buildings_top">
                    <div className="app__buildings_value_main">Construction C{index + 1}</div>
                    {/* <div><span className="app__buildings_tag">Typologie : <strong>{item.properties.ign_properties === null ? "Indifférencié" : item.properties.ign_properties.usage_1}{item.properties?.ign_properties?.nombre_de_logements > 1 && " (" + item.properties.ign_properties.nombre_de_logements + " logements)"}{item.properties?.ign_properties?.nombre_de_logements === 1 && " (1 logement)"}</strong></span>{item.properties.ign_properties?.construction_legere === true && <span className="app__buildings_tag"><strong>Construction légère</strong></span>}{item.properties?.osm_properties?.underground === "true" === true && <span className="app__buildings_tag"><strong>Construction sous-terrainne</strong></span>}{item.properties?.bdnb_details?.annee_construction && <span className="app__buildings_tag">Année de construction : <strong>{item.properties?.bdnb_details?.annee_construction}</strong></span>}</div> */}
                    <div className="app__buildings_info_status">
                        <div id={"buildings_info_status" + item.properties.id + "_keep"} className={`app__buildings_info_status_item ${status === "keep" ? "app__buildings_info_status_item_selected" : ""}`} onClick={() => { handleStatusChange("keep") }}><img src={status === "keep" ? ico_buildings_keep_white : ico_buildings_keep_blue} alt="" />Conserver</div>
                        {/* {(item.properties?.bdnb_details?.vf_m2_relative_cat && item.properties?.bdnb_details?.vf_m2_relative_cat !== null) &&
                            <div id={"buildings_info_status" + item.properties.id + "_renovation"} className={`app__buildings_info_status_item ${status === "renovation" ? "app__buildings_info_status_item_selected" : ""}`} onClick={() => { handleStatusChange("renovation") }}><img src={status === "renovation" ? ico_buildings_reno_white : ico_buildings_reno_blue} alt="" />Rénover</div>
                        } */}
                        <div id={"buildings_info_status" + item.properties.id + "_demolition"} className={`app__buildings_info_status_item ${status === "demolition" ? "app__buildings_info_status_item_selected" : ""}`} onClick={() => { handleStatusChange("demolition") }}><img src={status === "demolition" ? ico_buildings_demol_white : ico_buildings_demol_blue} alt="" />Démolir</div>
                        <div className="app__buildings_info_status_indicator" style={{ left: status_left + 'px', width: status_width + 'px' }}></div>
                    </div>
                </div>
                <div className="app__buildings_body_main">
                    <div className="app__buildings_body">
                        <div className="app__buildings_svg">
                            <svg max-width="100%" max-height="100%" viewBox="-20 -20 840 840" preserveAspectRatio="xMidYMid meet" fillRule="evenodd" fill="none" xmlns="http://www.w3.org/2000/svg">
                                {/* Land svg */}
                                <polygon points={other_helpers.get_svgCoords_from_globalCoords(capacity?.landBase?.union?.geometry?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#AFC76B"} stroke={"red"} strokeWidth="0" />
                                {/* Buildings svgs */}
                                {capacity.landBase.buildings.buildings_land.map((building_index, index) => {
                                    var item_building = capacity.landBase.buildings.buildings[building_index];
                                    var building_opacity = "0.3";
                                    if (building_index === item_index) {
                                        building_opacity = "1.0";
                                    }
                                    if (item_building?.geometry?.type === "Polygon") {
                                        return (
                                            <polygon key={"building_poly_" + index} points={other_helpers.get_svgCoords_from_globalCoords(item_building?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#FFFFFF"} stroke={"grey"} fillOpacity={building_opacity} strokeWidth="5" />
                                        )
                                    }
                                    else if (item_building?.geometry?.type === "MultiPolygon") {
                                        return (
                                            item_building?.geometry?.coordinates.map((building_poly_coords, index_2) => {
                                                return (
                                                    <polygon key={"building_poly_" + index + "_" + index_2} points={other_helpers.get_svgCoords_from_globalCoords(building_poly_coords, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill={"#FFFFFF"} stroke={"grey"} fillOpacity={building_opacity} strokeWidth="5" />
                                                )
                                            })
                                        )
                                    }
                                })}
                            </svg>
                        </div>
                        <div className="app__buildings_infos">
                            <div className="app__buildings_infos_menu">
                                {sections.map((title, index) => {
                                    return (
                                        <div key={"building_div_" + index} id={"buildings_info_section" + item.properties.id + "_" + index} className={`app__buildings_infos_menu_item ${section === index ? "app__buildings_infos_menu_item_selected" : ""}`} onClick={() => { setSection(index) }}>{title}</div>
                                    )
                                })}
                                <div className="app__buildings_infos_menu_indicator" style={{ left: section_left + 'px', width: section_width + 'px' }}></div>
                            </div>
                            <div className="app__buildings_infos_menu_line"></div>
                            <div className="app__buildings_infos_frame">
                                {/* CARACTERISTICS */}
                                <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                    <div className="app__buildings_info_grid">
                                        <div className="app__buildings_info_card">
                                            <div>
                                                <div className="app__buildings_title">Typologie</div>
                                                <div className="app__buildings_value_second">{item.properties.ign_properties === null ? "Indifférencié" : item.properties.ign_properties.usage_1}</div>
                                            </div>
                                        </div>
                                        {item.properties?.ign_properties?.nombre_de_logements >= 1 &&
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Nombre de lot</div>
                                                    <div className="app__buildings_value_second">{item.properties?.ign_properties?.nombre_de_logements >= 1 ? (item.properties?.ign_properties?.nombre_de_logements === 1 ? "1 logement" : item.properties?.ign_properties?.nombre_de_logements + " logements") : "NC"}</div>
                                                </div>
                                            </div>
                                        }
                                        {item.properties?.ign_properties?.construction_legere &&
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Construction légère</div>
                                                    <div className="app__buildings_value_second">{item.properties?.ign_properties?.construction_legere === true ? "Oui" : "Non"}</div>
                                                </div>
                                            </div>
                                        }
                                        {item.properties?.osm_properties?.underground === true &&
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Construction souterraine</div>
                                                    <div className="app__buildings_value_second">{item.properties?.osm_properties?.underground === true ? "Oui" : "Non"}</div>
                                                </div>
                                            </div>
                                        }
                                        {item.properties?.bdnb_details?.annee_construction &&
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Année de construction</div>
                                                    <div className="app__buildings_value_second">{item.properties?.bdnb_details?.annee_construction || "NC"}</div>
                                                </div>
                                            </div>
                                        }
                                        {status === "keep" &&
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Surélévation</div>
                                                    <div className="app__buildings_checkbox_container">
                                                        <input className="app__buildings_checkbox" type="checkbox" id={"buildings_info_raise_" + item.properties.id} onChange={(e) => { raisableChange(e.target.checked) }} checked={isRaisable} />
                                                        <label id={"buildings_info_raise_" + item.properties.id + "_label"} className="app__buildings_checkbox_label" htmlFor={"buildings_info_raise_" + item.properties.id}>{isRaisable ? "Autoriser la surélévation" : "Interdire la surélévation"}</label>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* AREAS */}
                                <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                    <div className="app__buildings_info_grid">
                                        <div className="app__buildings_info_card">
                                            <img src={ico_buildings_area} alt="building_type" />
                                            <div>
                                                <div className="app__buildings_title">Emprise au sol</div>
                                                <div className="app__buildings_value_second">{other_helpers.get_pretty_num(item.properties.area.toFixed(2))} m²</div>
                                            </div>
                                        </div>
                                        <div className="app__buildings_info_card">
                                            <img src={ico_buildings_level} alt="building_type" />
                                            <div>
                                                <div className="app__buildings_title">Nombre d'étages</div>
                                                {status === "demolition" ?
                                                    <div className="app__buildings_value_second">{item.properties.ign_properties.nombre_d_etages || "NC"}</div>
                                                    :
                                                    <input className="app__buildings_input" type="number" id={"input_nb_level_C" + item_index} name={"input_nb_level_C" + item_index} min="1" max="100" step="1" defaultValue={item?.properties?.nbLevel_estimated || 1} onBlur={(e) => { updateInput_nbLevel(e.target.value) }} onKeyUp={(e) => { if (e.key === "Enter") { document.getElementById("input_nb_level_C" + item_index).blur() } }}></input>
                                                }
                                            </div>
                                        </div>
                                        <div className="app__buildings_info_card">
                                            <img src={ico_buildings_area} alt="building_type" />
                                            <div>
                                                <div className="app__buildings_title">Surface construite totale</div>
                                                {status === "demolition" ?
                                                    <div className="app__buildings_value_second">{item.properties?.areaTotal_estimated ? (other_helpers.get_pretty_num(item.properties.areaTotal_estimated.toFixed(2)) + " m²") : "NC"}</div>
                                                    :
                                                    <div className="app__buildings_input_block">
                                                        <input className="app__buildings_input" type="number" id={"input_areaTotal_C" + item_index} name={"input_areaTotal_C" + item_index} min="1" max="10000000" step="0.01" defaultValue={item?.properties?.areaTotal_estimated ? item?.properties?.areaTotal_estimated.toFixed(2) : item?.properties.area.toFixed(2)} onBlur={(e) => { updateInput_areaTotal(e.target.value) }} onKeyUp={(e) => { if (e.key === "Enter") { document.getElementById("input_areaTotal_C" + item_index).blur() } }}></input>
                                                        <div className="app__buildings_input_unit">{item?.properties?.areaTotal_estimated ? item?.properties?.areaTotal_estimated.toFixed(2) : item?.properties.area.toFixed(2)}<span className="app__buildings_input_unit_visible"> m²</span></div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* HEIGHTS */}
                                <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                    <div className="app__buildings_info_grid">
                                        {(item.properties?.ign_properties?.altitude_minimale_toit && item.properties?.ign_properties?.altitude_maximale_toit && item.properties?.ign_properties?.altitude_minimale_sol) ?
                                            <>
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_hmin} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Hauteur à l'égout du toit</div>
                                                        <div className="app__buildings_value_second">{other_helpers.get_pretty_num((item.properties.ign_properties.altitude_minimale_toit - item.properties.ign_properties.altitude_minimale_sol).toFixed(1))} m</div>
                                                    </div>
                                                </div>
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_hmax} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Hauteur au faîtage</div>
                                                        <div className="app__buildings_value_second">{other_helpers.get_pretty_num((item.properties.ign_properties.altitude_maximale_toit - item.properties.ign_properties.altitude_minimale_sol).toFixed(1))} m</div>
                                                    </div>
                                                </div>
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_elevation} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Altitude (Niveau 0)</div>
                                                        <div className="app__buildings_value_second">{other_helpers.get_pretty_num(item.properties.ign_properties.altitude_minimale_sol.toFixed(2))} m NGF</div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            item.properties?.height ?
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_hmax} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Hauteur</div>
                                                        <div className="app__buildings_value_second">{item.properties.height.toFixed(1)} m</div>
                                                    </div>
                                                </div>
                                                : <></>
                                        }
                                    </div>
                                </div>
                                {/* FACADES */}
                                {item?.properties?.bdnb_details?.surface_facade_totale > 0 &&
                                    <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                        <div className="app__buildings_info_grid">
                                            {(item.properties?.bdnb_details?.surface_facade_totale > 0) &&
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_area_facade} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Surface de façade</div>
                                                        <div className="app__buildings_value_second">{item.properties?.bdnb_details?.surface_facade_totale} m²</div>
                                                    </div>
                                                </div>
                                            }
                                            {(item.properties?.bdnb_details?.surface_facade_mitoyenne > 0) &&
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_area_facade} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Surface de façade mitoyenne</div>
                                                        <div className="app__buildings_value_second">{item.properties?.bdnb_details?.surface_facade_mitoyenne} m²</div>
                                                    </div>
                                                </div>
                                            }
                                            {(item.properties?.bdnb_details?.surface_facade_ext > 0 && item.properties?.bdnb_details?.surface_facade_vitree > 0) &&
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_area_window} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Surface de façade vitrée</div>
                                                        <div className="app__buildings_value_second">{item.properties?.bdnb_details?.surface_facade_vitree} m²<span className="app__buildings_value_second_small">soit {Math.round(100 * item.properties?.bdnb_details?.surface_facade_vitree / item.properties?.bdnb_details?.surface_facade_ext)} %</span></div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {/* MATERIALS */}
                                {(wall_mat !== undefined && roof_mat !== undefined) &&
                                    <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                        <div className="app__buildings_info_grid">
                                            {wall_mat !== undefined &&
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_wall} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Matériaux des murs ext.</div>
                                                        <div className="app__buildings_value_second">{wall_mat}</div>
                                                    </div>
                                                </div>
                                            }
                                            {roof_mat !== undefined &&
                                                <div className="app__buildings_info_card">
                                                    <img src={ico_buildings_roof} alt="building_type" />
                                                    <div>
                                                        <div className="app__buildings_title">Matériaux de la toiture</div>
                                                        <div className="app__buildings_value_second">{roof_mat}</div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple &&
                                    <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                        <div style={{ display: 'flex' }}>
                                            <div className="app__buildings_info_dpe_img">
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "A" &&
                                                    <img src={dpe_A} alt="" />
                                                }
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "B" &&
                                                    <img src={dpe_B} alt="" />
                                                }
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "C" &&
                                                    <img src={dpe_C} alt="" />
                                                }
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "D" &&
                                                    <img src={dpe_D} alt="" />
                                                }
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "E" &&
                                                    <img src={dpe_E} alt="" />
                                                }
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "F" &&
                                                    <img src={dpe_F} alt="" />
                                                }
                                                {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple === "G" &&
                                                    <img src={dpe_G} alt="" />
                                                }
                                            </div>
                                            <div className="app__buildings_info_dpe_data">
                                                <div className="app__buildings_info_dpe_data_split">
                                                    {item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple !== null &&
                                                        <div className="app__buildings_info_card">
                                                            <div>
                                                                {/* <div className="app__buildings_title">Etiquette DPE{item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_source === "dpe simule" && " (simulé)"}</div> */}
                                                                <div className="app__buildings_title">Etiquette DPE</div>
                                                                <div className="app__buildings_value_second">{item.properties?.bdnb_details?.etiquette_dpe_synthese_particulier_simple}
                                                                    {item.properties?.bdnb_details?.identifiant_dpe_synthese_particulier && item.properties?.bdnb_details?.identifiant_dpe_synthese_particulier !== null &&
                                                                        <div className="app__land_data_button" style={{ marginLeft: '5px' }}><a target='_blanck' href={"https://observatoire-dpe-audit.ademe.fr/afficher-dpe/" + item.properties?.bdnb_details?.identifiant_dpe_synthese_particulier}>DPE détaillé</a></div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {item.properties?.bdnb_details?.etat_initial_risque_canicule > 0 &&
                                                        <div className="app__buildings_info_card">
                                                            <div>
                                                                <div className="app__buildings_title">Risque de surchauffe estivale</div>
                                                                <div className="app__buildings_value_second">{risque_canicule[item.properties?.bdnb_details?.etat_initial_risque_canicule - 1].label}</div>
                                                                {/* <div className="app__buildings_value_second_small2">{risque_canicule[item.properties?.bdnb_details?.etat_initial_risque_canicule - 1].description}</div> */}
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_mean > 0 &&
                                                    <div style={{ position: 'relative' }}>
                                                        <div className="app__buildings_title">Consommation énergétique (kWh/m²/an)</div>
                                                        <div className="app__buildings_value_second_range_min">{item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_lower}</div>
                                                        <div className="app__buildings_value_second_range_mean" style={{ left: (15 + 100 * (item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_mean - item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_lower) / (item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_upper - item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_lower)) + "%" }}>{item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_mean}</div>
                                                        <div className="app__buildings_value_second_range_max">{item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_upper}</div>
                                                        <img className="app__buildings_range" src={dpe_range} alt="" />
                                                    </div>
                                                }
                                                {item.properties?.bdnb_details?.etat_initial_ges_estim_mean > 0 &&
                                                    <div style={{ position: 'relative' }}>
                                                        <div className="app__buildings_title">Emission de gaz à effet de serre (kg CO₂/m²/an)</div>
                                                        <div className="app__buildings_value_second_range_min">{item.properties?.bdnb_details?.etat_initial_ges_estim_lower}</div>
                                                        <div className="app__buildings_value_second_range_mean" style={{ left: (15 + 100 * (item.properties?.bdnb_details?.etat_initial_ges_estim_mean - item.properties?.bdnb_details?.etat_initial_ges_estim_lower) / (item.properties?.bdnb_details?.etat_initial_ges_estim_upper - item.properties?.bdnb_details?.etat_initial_ges_estim_lower)) + "%" }}>{item.properties?.bdnb_details?.etat_initial_ges_estim_mean}</div>
                                                        <div className="app__buildings_value_second_range_max">{item.properties?.bdnb_details?.etat_initial_ges_estim_upper}</div>
                                                        <img className="app__buildings_range" src={dpe_range} alt="" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(item.properties?.bdnb_details?.vf_m2_relative_cat && item.properties?.bdnb_details?.vf_m2_relative_cat !== null) &&
                                    <div className="app__buildings_infos_subframe" style={{ transform: 'translateX(-' + (section * 100) + '%)' }}>
                                        <div className="app__buildings_title">Dans l'hypothèse d'une rénovation énergétique globale du bien, le CSTB estime les évolutions suivantes :</div>
                                        {/* <div className="app__buildings_description">Dans l'hypothèse d'une rénovation énergétique globale du bien, le CSTB estime que la consommation énergétique diminuerait de {Math.round(100 * item.properties?.bdnb_details?.gisement_gain_energetique_mean / item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_mean)}%, et l'émission de gaz à effet de serre de {Math.round(100 * item.properties?.bdnb_details?.gisement_gain_ges_mean / item.properties?.bdnb_details?.etat_initial_ges_estim_mean)}%. L'étiquette DPE du bien passerait de {item.properties?.bdnb_details?.etiquette_dpe_2021_initial_map} à {item.properties?.bdnb_details?.etiquette_dpe_2021_renove_map}.</div> */}
                                        {/* <div className="app__buildings_description">La plus-value sur le valeur du bien est estimé à {item.properties?.bdnb_details?.vf_m2_relative_cat}.</div> */}
                                        <div className="app__sectiondata_widget_spacer_10"></div>
                                        {/* <div className="" style={{ display: 'flex' }}> */}

                                        <div className="app__buildings_info_grid" style={{ gridRowGap: '10px' }}>
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Etiquette DPE</div>
                                                    <div className="app__buildings_value_second">Gain de {Math.abs(dpe_labels.indexOf(item.properties?.bdnb_details?.etiquette_dpe_2021_renove_map) - dpe_labels.indexOf(item.properties?.bdnb_details?.etiquette_dpe_2021_initial_map))} échelon{Math.abs(dpe_labels.indexOf(item.properties?.bdnb_details?.etiquette_dpe_2021_renove_map) - dpe_labels.indexOf(item.properties?.bdnb_details?.etiquette_dpe_2021_initial_map)) > 1 ? "s" : ""} <span className="app__buildings_value_second_small">({item.properties?.bdnb_details?.etiquette_dpe_2021_renove_map})</span></div>
                                                    {/* <div className="app__buildings_value_second_small">valeur atuelle : {item.properties?.bdnb_details?.etiquette_dpe_2021_initial_map}</div>
                                                    <div className="app__buildings_value_second_small">valeur rénovée : {item.properties?.bdnb_details?.etiquette_dpe_2021_renove_map}</div> */}
                                                </div>
                                            </div>
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Consommation énergétique</div>
                                                    <div className="app__buildings_value_second">-{Math.round(100 * item.properties?.bdnb_details?.gisement_gain_energetique_mean / item.properties?.bdnb_details?.etat_initial_consommation_energie_primaire_estim_mean)}%<span className="app__buildings_value_second_small"> ({item.properties?.bdnb_details?.etat_renove_consommation_energie_estim_mean} kWh/m²/an)</span></div>
                                                </div>
                                            </div>
                                            <div className="app__buildings_info_card_main">
                                                <div className="app__buildings_title">Valeur du bien</div>
                                                <div className="app__buildings_value_second">Plus-value de {item.properties?.bdnb_details?.vf_m2_relative_cat}</div>
                                            </div>
                                            <div className="app__buildings_info_card">
                                                <div>
                                                    <div className="app__buildings_title">Emission de gaz à effet de serre</div>
                                                    <div className="app__buildings_value_second">-{Math.round(100 * item.properties?.bdnb_details?.gisement_gain_ges_mean / item.properties?.bdnb_details?.etat_initial_ges_estim_mean)}%<span className="app__buildings_value_second_small"> ({item.properties?.bdnb_details?.etat_renove_ges_estim_mean} kg CO₂/m²/an)</span></div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* </div> */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* <div className="app__sectiondata_widget_spacer_10"></div> */}
                    {/* <div className="app__buildings_bottom">
                        <div className="app__buildings_info_title">Qu'envisagez-vous de faire de cette construction ?</div>
                        <div className="app__buildings_info_status">
                            <div id={"buildings_info_status" + item.properties.id + "_keep"} className={`app__buildings_info_status_item ${status === "keep" ? "app__buildings_info_status_item_selected" : ""}`} onClick={() => { handleStatusChange("keep") }}><img src={status === "keep" ? ico_buildings_keep_white : ico_buildings_keep_blue} alt="" />Conserver</div>
                            {(item.properties?.bdnb_details?.vf_m2_relative_cat && item.properties?.bdnb_details?.vf_m2_relative_cat !== null) &&
                                <div id={"buildings_info_status" + item.properties.id + "_renovation"} className={`app__buildings_info_status_item ${status === "renovation" ? "app__buildings_info_status_item_selected" : ""}`} onClick={() => { handleStatusChange("renovation") }}><img src={status === "renovation" ? ico_buildings_reno_white : ico_buildings_reno_blue} alt="" />Rénover</div>
                            }
                            <div id={"buildings_info_status" + item.properties.id + "_demolition"} className={`app__buildings_info_status_item ${status === "demolition" ? "app__buildings_info_status_item_selected" : ""}`} onClick={() => { handleStatusChange("demolition") }}><img src={status === "demolition" ? ico_buildings_demol_white : ico_buildings_demol_blue} alt="" />Démolir</div>
                            <div className="app__buildings_info_status_indicator" style={{ left: status_left + 'px', width: status_width + 'px' }}></div>
                        </div>
                        {status !== "demolition" &&
                            <div className="app__buildings_info_status_data_container">
                                <div className="app__buildings_info_card">
                                    <img src={ico_buildings_hmax} alt="building_type" />
                                    <div>
                                        <div className="app__buildings_title">Surface construite</div>
                                        <div className="app__buildings_value_second">500 m²</div>
                                    </div>
                                </div>
                                <div className="app__buildings_info_card">
                                    <img src={ico_buildings_hmax} alt="building_type" />
                                    <div>
                                        <div className="app__buildings_title">Nombre d'étages</div>
                                        <div className="app__buildings_value_second">3</div>
                                    </div>
                                </div>
                                <div className="app__buildings_info_card">
                                    <img src={ico_buildings_hmax} alt="building_type" />
                                    <div>
                                        <div className="app__buildings_title">Surélévation</div>
                                        <div className="ruleeditor__input_checkbox_container"><input className="app__ruleset_rule_variable_input_element_checkbox" type="checkbox" id={"buildings_info_raise_" + item.properties.id} onChange={(e) => { raisableChange(e.target.checked) }} checked={isRaisable} /><label id={"buildings_info_raise_" + item.properties.id + "_label"} className="ruleeditor__input_checkbox_label" htmlFor={"buildings_info_raise_" + item.properties.id} style={{ color: "#062134", fontSize: "11px" }}>Autoriser la surélévation</label></div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div> */}
                </div>
            </div>
            <div className="app__sectiondata_widget_spacer_10"></div>
        </div>
    )
};

export default Building;