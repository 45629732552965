import React, { useState, useEffect } from 'react';

// Import external functions
import * as helpers from '../../../../helpers/Other_helpers';

// Import CSS & assets
import './BuildableDetails.css';
import ico_map_layers from '../../../../assets/ico/ico_map_layers.svg';
import WidgetInfo from './WidgetInfo';
import DoughnutChart from '../../../charts/DoughnutChart';


const BuildableDetails = ({ capacity, setCapacity, mapLayers, setMapLayers, handleToaster }) => {


    // Show level plan
    const [selectedLevel, setSelectedLevel] = useState(null);

    const switchSelectedLevel = (clickedIndex) => {
        if (selectedLevel === clickedIndex) {
            setSelectedLevel(null);
        }
        else {
            setSelectedLevel(clickedIndex);
        }
    }

    // Change level height
    const getHeightLevel = () => {
        if (capacity?.buildable?.volume?.parameters?.levelHeight) {
            return capacity.buildable.volume.parameters.levelHeight;
        }
        else {
            return 2.7;
        }
    }
    const updateHeightLevel = (newValue) => {
        if (parseFloat(newValue) !== capacity?.buildable?.volume?.parameters?.levelHeight) {
            // addToaster("buildable");
            handleToaster([{ key: "buildable", status: "loading" }]);
            setHeightLevel(newValue);
            var buildable = { ...capacity.buildable };
            buildable.volume.parameters.levelHeight = parseFloat(newValue);
            buildable.volume.levels = "fetching";
            var toSaveData = { ...capacity.toSave };
            if (!toSaveData?.keys) { toSaveData.keys = [] }
            toSaveData.keys.push("buildable");
            toSaveData.action = "Modification de la hauteur moyenne d'un niveau à";
            toSaveData.value = newValue;
            toSaveData.autoSave = false;
            setCapacity({ ...capacity, buildable: buildable, toSave: toSaveData });
        }
    }
    const [heightLevel, setHeightLevel] = useState(getHeightLevel());
    useEffect(() => {
        setHeightLevel(getHeightLevel());
    }, [capacity?.buildable?.volume?.parameters?.levelHeight]);


    // LAYER : Toggle
    const layer_Toggle = (layer) => {
        console.log("TOGGLE LAYER", layer);
        // Get state
        var mapLayersState = mapLayers;
        // Update state
        mapLayersState[layer]["isVisible"] = !mapLayersState[layer]["isVisible"];
        // Update original
        setMapLayers({ ...mapLayersState });
    }

    // CHART
    const pieChartOptions = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'center'
            },
        },
        cutout: 80
    }
    var chart_builtArea = capacity?.buildable?.volume?.parameters?.builtArea || 0;
    var chart_builtArea_ground = capacity?.buildable?.volume?.parameters?.builtArea_ground || 0;
    var chart_buildableArea_total = (capacity?.buildable?.volume?.parameters?.max_area_total > 0 ? Math.min(capacity?.buildable?.volume?.parameters?.max_area_total, capacity?.buildable?.volume?.parameters?.buildableArea) : capacity?.buildable?.volume?.parameters?.buildableArea) || 0;
    var chart_buildableArea_free = Math.max(0, chart_buildableArea_total - chart_builtArea);
    var chart_buildableArea_raise = capacity?.buildable?.volume?.parameters?.buildableArea_raise || 0;
    var chart_buildableArea_ground = capacity?.buildable?.volume?.parameters?.max_buildableArea_ground || 0;
    var chart_free_buildableArea_ground_all = capacity?.buildable?.volume?.parameters?.free_buildableArea_ground_all || 0;
    var chart_free_buildableArea_raise = capacity?.buildable?.volume?.parameters?.free_buildableArea_raise || 0;
    var chart_free_buildableArea_total = capacity?.buildable?.volume?.parameters?.free_buildableArea_total || 0;
    var chart_free_buildableArea_unpossible = Math.max(0, ((capacity?.buildable?.volume?.parameters?.free_buildableArea_max || 0) - (capacity?.buildable?.volume?.parameters?.free_buildableArea_total || 0)));

    var chart_labels = [];
    var chart_data = [];
    if (chart_builtArea >= 0) {
        chart_labels.push('Surface déja construite');
        chart_data.push(Math.round(chart_builtArea));
    }
    if (chart_free_buildableArea_ground_all >= 0) {
        chart_labels.push('Surface constructible (sans surélévation)');
        chart_data.push(Math.round(chart_free_buildableArea_ground_all));
    }
    if (chart_free_buildableArea_raise >= 0) {
        chart_labels.push('Surface constructible (en surélévation)');
        chart_data.push(Math.round(chart_free_buildableArea_raise));
    }
    if (chart_free_buildableArea_unpossible >= 0) {
        chart_labels.push('Surface constructible inexploitable');
        chart_data.push(Math.round(chart_free_buildableArea_unpossible));
    }
    // if (chart_buildableArea_free > 0) {
    //     if (chart_buildableArea_raise > 0 && chart_buildableArea_raise < chart_buildableArea_free) {
    //         if (chart_builtArea_ground >= chart_buildableArea_ground) {
    //             chart_labels.push('Surface constructible (non utilisable)');
    //             chart_data.push(Math.round(chart_buildableArea_free - chart_buildableArea_raise));
    //             chart_labels.push('Surface constructible (en surélévation)');
    //             chart_data.push(Math.round(chart_buildableArea_raise));
    //         }
    //         else {
    //             chart_labels.push('Surface constructible (sans surélévation)');
    //             chart_data.push(Math.round(chart_buildableArea_free - chart_buildableArea_raise));
    //             chart_labels.push('Surface constructible (en surélévation)');
    //             chart_data.push(Math.round(chart_buildableArea_raise));
    //         }
    //     }
    //     else {
    //         chart_labels.push('Surface constructible restante');
    //         chart_data.push(Math.round(chart_buildableArea_free));
    //     }
    // }

    var chartData = {
        labels: chart_labels,
        datasets: [
            {
                label: 'Surfaces',
                data: chart_data,
                backgroundColor: [
                    '#062134',
                    '#a5b0b6',
                    '#ccd1d3',
                    '#E5E5E5',
                ],
                hoverBackgroundColor: [
                    '#062134',
                    '#a5b0b6',
                    '#ccd1d3',
                    '#E5E5E5',
                ],
                borderColor: "#D9D9D9",
                hoverBorderColor: [
                    '#062134',
                    '#a5b0b6',
                    '#ccd1d3',
                    '#E5E5E5',
                ],
                borderWidth: 0,
                hoverBorderWidth: 2,
            },
        ],
    };

    // Calculate data
    const get_ratio = () => {

        var ratio = 0;
        // if (chart_buildableArea_total > 0 && chart_buildableArea_free > 0 && chart_builtArea < chart_buildableArea_total) {
        //     // ratio = (chart_buildableArea_free / chart_builtArea) * 100;
        //     ratio = (chart_buildableArea_free / chart_buildableArea_total) * 100;
        // }
        if (capacity?.buildable?.volume?.parameters?.buildableArea && capacity?.buildable?.volume?.parameters?.free_buildableArea_total) {
            ratio = (capacity?.buildable?.volume?.parameters?.free_buildableArea_total / capacity?.buildable?.volume?.parameters?.buildableArea) * 100;
        }
        return ratio
    }

    return (
        <div>
            {/* PARAMETERS */}
            <div className="app__sectiondata_widget_title">Paramètres du volume constructible</div>
            <div className={`${window.innerWidth < 550 ? "app__sectiondata_widget_layout_B" : capacity?.buildable?.volume?.parameters?.max_height > capacity?.buildable?.volume?.parameters?.max_height_facade ? "app__sectiondata_widget_layout_A" : "app__sectiondata_widget_layout_A1"}`}>
                <div className="app__sectiondata__widget">
                    <div className="app__sectiondata_widget_value_container">
                        <div className="app__sectiondata_widget_title">Hauteur maximum</div>
                        <div className={`${capacity?.buildable?.volume?.parameters?.max_height > capacity?.buildable?.volume?.parameters?.max_height_facade ? "app__sectiondata_widget_layout_A" : ""}`}>
                            <div style={{ position: "relative" }}>
                                {capacity?.buildable?.volume?.parameters?.max_height ?
                                    <>
                                        <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.max_height).toFixed(2))}</div>
                                        {capacity?.buildable?.volume?.parameters?.max_height > capacity?.buildable?.volume?.parameters?.max_height_facade ?
                                            <div className="app__sectiondata_widget_value_legend_medium">m<br></br>au faîtage</div>
                                            :
                                            <div className="app__sectiondata_widget_value_legend_medium">m</div>
                                        }
                                        {/* INFO MESSAGE */}
                                        {capacity?.buildable?.volume?.parameters?.max_height_type === "database" &&
                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>En l'absence de règle PLU, la hauteur maximum est définie par <strong>le plus haut batiment</strong> existant sur le terrain ou à proximité immédiate.</>} />
                                        }
                                        {(capacity?.buildable?.volume?.parameters?.max_height_database && capacity?.buildable?.volume?.parameters?.max_height_rules && capacity?.buildable?.volume?.parameters?.max_height_type === "rules" && capacity?.buildable?.volume?.parameters?.max_height_database > capacity?.buildable?.volume?.parameters?.max_height_rules) &&
                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>La hauteur maximum est définie par les règles PLU, mais le plus haut batiment à proximité du terrain fait <strong>{capacity.buildable.volume.parameters.max_height_database.toFixed(2)} m</strong>.</>} />
                                        }
                                    </>
                                    :
                                    <>
                                        <div className="app__sectiondata_widget_value_text_medium">0</div>
                                        <div className="app__sectiondata_widget_value_legend_medium">m</div>
                                    </>
                                }
                            </div>
                            {capacity?.buildable?.volume?.parameters?.max_height > capacity?.buildable?.volume?.parameters?.max_height_facade &&
                                <div style={{ position: "relative" }}>
                                    <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.max_height_facade).toFixed(2))}</div>
                                    <div className="app__sectiondata_widget_value_legend_medium">m<br></br>en façade</div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="app__sectiondata__widget">
                    <div className="app__sectiondata_widget_title">Hauteur moyenne d'un niveau</div>
                    <div className="app__sectiondata_widget_layout_A">
                        <div style={{ position: "relative" }}>
                            {(capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0 && capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0) ?
                                <>
                                    {(capacity?.buildable?.volume?.parameters?.min_height_level?.ground === capacity?.buildable?.volume?.parameters?.min_height_level?.current) ?
                                        <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.ground.toFixed(2))}</div>
                                        :
                                        <div className="app__sectiondata_widget_value_text_medium" style={{ fontSize: "30px" }}>{helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.ground.toFixed(2))} et {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.current.toFixed(2))}</div>
                                    }
                                    <div className="app__sectiondata_widget_value_legend_medium">m</div>
                                </>
                                :
                                <>
                                    <div className="app__sectiondata_widget_value_text_medium">
                                        <input className="buildable__input" type="number" id="hmoy_level" name="hmoy_level" min="1" max="10" step="0.1" defaultValue={heightLevel} onBlur={(e) => { updateHeightLevel(e.target.value) }} onKeyUp={(e) => { if (e.key === "Enter") { document.getElementById('hmoy_level').blur() } }}></input>
                                    </div>
                                    <div className="app__sectiondata_widget_value_legend_medium" style={{ color: "#007384" }}>m</div>
                                </>
                            }
                            {(capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0 && capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0 && capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) ?
                                <WidgetInfo ico={"💡"} title={"Information"} text={<>Les hauteurs d'étages sont définies par les règles PLU :<br></br>- RDC : {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.ground.toFixed(2))} m<br></br>- Etages courants : {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.current.toFixed(2))} m<br></br>- Dernier étage : {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.top.toFixed(2))} m</>} />
                                :
                                (capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0 && capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0) ?
                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>Les hauteurs d'étages sont définies par les règles PLU :<br></br>- RDC: {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.ground.toFixed(2))} m<br></br>- Etages courants: {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.current.toFixed(2))} m</>} />
                                    :
                                    (capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0 && capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) ?
                                        <WidgetInfo ico={"💡"} title={"Information"} text={<>Les hauteurs d'étages sont définies par les règles PLU :<br></br>- Etages courants: {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.current.toFixed(2))} m<br></br>- Dernier étage: {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.top.toFixed(2))} m</>} />
                                        :
                                        (capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0 && capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0) ?
                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>Les hauteurs d'étages sont définies par les règles PLU :<br></br>- RDC: {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.ground.toFixed(2))} m<br></br>- Dernier étage: {helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.top.toFixed(2))} m</>} />
                                            :
                                            capacity?.buildable?.volume?.parameters?.min_height_level?.ground > 0 ?
                                                <WidgetInfo ico={"💡"} title={"Information"} text={<>La hauteur du RDC est définie par les règles PLU à <strong>{helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.ground.toFixed(2))} m</strong>.<br></br>Vous pouvez modifier la hauteur des étages courants.</>} />
                                                :
                                                capacity?.buildable?.volume?.parameters?.min_height_level?.current > 0 ?
                                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>La hauteur des étages courants est définie par les règles PLU à <strong>{helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.current.toFixed(2))} m</strong>.<br></br>Vous pouvez modifier la hauteur du RDC.</>} />
                                                    :
                                                    capacity?.buildable?.volume?.parameters?.min_height_level?.top > 0 ?
                                                        <WidgetInfo ico={"💡"} title={"Information"} text={<>La hauteur du dernier étage est définie par les règles PLU à <strong>{helpers.get_pretty_num(capacity?.buildable?.volume?.parameters?.min_height_level?.top.toFixed(2))} m</strong>.<br></br>Vous pouvez modifier la hauteur des autres étages.</>} />
                                                        :
                                                        <></>
                            }
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="app__sectiondata_widget_value_text_medium">{capacity?.buildable?.volume?.levels ? capacity?.buildable?.volume?.levels.length - 1 : "0"}{(capacity?.buildable?.volume?.levels && capacity?.buildable?.volume?.levels.length - 1 > 1) ?
                                <span style={{ fontSize: '20px', lineHeight: '20px', marginLeft: '14px', marginTop: '5px' }}> (R+ {capacity?.buildable?.volume?.levels.length - 2})</span>
                                : ""}</div>
                            <div className="app__sectiondata_widget_value_legend_medium" style={{ fontSize: '12.6px' }}>{(capacity?.buildable?.volume?.levels && capacity?.buildable?.volume?.levels.length - 1 > 1) ? "niveaux constructibles" : "niveau constructible"}</div>
                            {(capacity?.buildable?.volume?.levels && capacity?.buildable?.volume?.parameters?.max_nb_level_super && capacity?.buildable?.volume?.parameters?.max_nb_level_super !== null && (capacity?.buildable?.volume?.levels.length - 1) >= capacity?.buildable?.volume?.parameters?.max_nb_level_super) ?
                                <WidgetInfo ico={"💡"} title={"Information"} text={<>Le nombre de niveaux est limité par les règles PLU.</>} />
                                :
                                (capacity?.buildable?.volume?.parameters?.min_height_level?.ground === null && capacity?.buildable?.volume?.parameters?.min_height_level?.current === null && capacity?.buildable?.volume?.parameters?.min_height_level?.top === null && capacity?.buildable?.volume?.parameters?.higherPlane && capacity?.buildable?.volume?.levels && (Math.floor(10 * capacity.buildable.volume.parameters.higherPlane / capacity.buildable.volume.levels.length) / 10) >= 2.4 && (Math.floor(10 * capacity.buildable.volume.parameters.higherPlane / capacity.buildable.volume.levels.length) / 10) < heightLevel) ?
                                    <WidgetInfo ico={"👍"} title={"Astuce"} text={<>Il est possible de construire un niveau supplémentaire en diminuant la hauteur à <strong>{(Math.floor(10 * capacity.buildable.volume.parameters.higherPlane / capacity.buildable.volume.levels.length) / 10).toFixed(2)} m</strong>.</>} />
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* BUILDABLE */}
            {(capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels !== "fetching" && capacity.buildable.volume.levels.length > 0) ?
                <>
                    <div className="app__sectiondata_widget_spacer_40"></div>
                    {/* GLOBAL */}
                    <div className="app__sectiondata_widget_title_container">
                        <div id="section_5_volume" className="app__sectiondata_widget_title">Volume constructible</div>
                        <div className="app__sectiondata_widget_layer">
                            <img src={ico_map_layers} alt="ico_map_layers" />
                            <div className="app__map_layer_checkbox"><input className="app__map_layer_checkbox_element" type="checkbox" id="sectiondata_switchbuildable" checked={mapLayers.buildable_volume.isVisible} onChange={() => { layer_Toggle("buildable_volume") }} /><label className="app__map_layer_checkbox_label" htmlFor="sectiondata_switchbuildable">Toggle</label></div>
                        </div>
                    </div>

                    <div className="app__sectiondata__widget">
                        <div className="buildable__total">
                            <div className={`${window.innerWidth < 550 ? "app__sectiondata_widget_layout_B" : "app__sectiondata_widget_layout_A"}`}>
                                <div className="app__sectiondata_widget_value_container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                    <div className="app__sectiondata_widget_title">Surface constructible au sol</div>
                                    <div>
                                        <div className="app__sectiondata_widget_value_text_medium">{(capacity?.buildable?.volume?.levels && capacity?.buildable?.volume?.levels.length >= 2) ? helpers.get_pretty_num((capacity.buildable.volume.levels[1].buildableArea).toFixed(0)) : 0}</div>
                                        <div className="app__sectiondata_widget_value_legend">m²</div>
                                    </div>
                                </div>
                                {/* <div className="app__sectiondata_widget_value_container" style={{ width: window.innerWidth >= 550 && "60%" }}> */}
                                <div className="app__sectiondata_widget_value_container" style={{ width: window.innerWidth >= 550 && "60%" }}>
                                    <div className="app__sectiondata_widget_title">Surface constructible totale (sur {capacity?.buildable?.volume?.levels.length - 1} niveaux)</div>
                                    <div style={{ position: "relative" }}>
                                        {(capacity?.buildable?.volume?.parameters?.max_area_total && capacity?.buildable?.volume?.parameters?.max_area_total !== null && capacity?.buildable?.volume?.parameters?.buildableArea > (capacity?.buildable?.volume?.parameters?.max_area_total + 0.01)) ?
                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_area_total).toFixed(0))}</div>
                                            :
                                            <div className="app__sectiondata_widget_value_text">{capacity?.buildable?.volume?.parameters?.buildableArea ? helpers.get_pretty_num((capacity.buildable.volume.parameters.buildableArea).toFixed(0)) : 0}</div>
                                        }
                                        <div className="app__sectiondata_widget_value_legend">m²</div>
                                        {(capacity?.buildable?.volume?.parameters?.max_area_total && capacity?.buildable?.volume?.parameters?.max_area_total !== null && capacity?.buildable?.volume?.parameters?.buildableArea > (capacity?.buildable?.volume?.parameters?.max_area_total + 0.01)) ?
                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>La surface constructible totale est plafonnée par les règles PLU à {helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.max_area_total).toFixed(2))} m². Sans cette contrainte, la surface constructible totale serait de <strong>{helpers.get_pretty_num((capacity.buildable.volume.parameters.buildableArea).toFixed(2))} m²</strong>.</>} />
                                            :
                                            <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* EXISTING */}
                    {(capacity?.buildable?.volume?.parameters?.builtArea > 0 && capacity?.buildable?.volume?.parameters?.builtArea_ground > 0) &&
                        <>
                            <div className="app__sectiondata_widget_spacer_40"></div>
                            <div id="section_5_existing" className="app__sectiondata_widget_title">Analyse de l'existant</div>
                            <div className="app__sectiondata__widget">
                                <div className="buildable__total">
                                    <div className={`${window.innerWidth < 550 ? "app__sectiondata_widget_layout_B" : "app__sectiondata_widget_layout_A"}`}>
                                        <div className="app__sectiondata_widget_value_container" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                            <div className="app__sectiondata_widget_title">Surface construite au sol</div>
                                            <div>
                                                <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity.buildable.volume.parameters.builtArea_ground).toFixed(0))}</div>
                                                <div className="app__sectiondata_widget_value_legend">m²</div>
                                            </div>
                                        </div>
                                        {/* <div className="app__sectiondata_widget_value_container" style={{ width: window.innerWidth >= 550 && "60%" }}> */}
                                        <div className="app__sectiondata_widget_value_container" style={{ width: window.innerWidth >= 550 && "60%" }}>
                                            <div className="app__sectiondata_widget_title">Surface construite totale</div>
                                            <div style={{ position: "relative" }}>
                                                <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                <div className="app__sectiondata_widget_value_legend">m²</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="app__sectiondata_widget_spacer_10"></div>
                            {/* POTENTIAL */}
                            <div className="app__sectiondata__widget">
                                <div className="buildable__total">
                                    <div className={`${window.innerWidth < 550 ? "app__sectiondata_widget_layout_B" : "app__sectiondata_widget_layout_A"}`}>
                                        {/* VALUES SIDE */}
                                        <div>
                                            <div className="app__sectiondata_widget_value_container">
                                                <div className="app__sectiondata_widget_title">Surface constructible totale restante</div>
                                                <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.free_buildableArea_total || 0).toFixed(0))}</div>
                                                <div className="app__sectiondata_widget_value_legend">m²</div>
                                                {capacity?.buildable?.volume?.parameters?.free_buildableArea_max > capacity?.buildable?.volume?.parameters?.free_buildableArea_total &&
                                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>Il reste <strong>{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.free_buildableArea_max).toFixed(0))} m² de surface constructible inexploitée</strong>.<br />Mais seulement <strong>{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.free_buildableArea_total).toFixed(0))} m²</strong> sont exploitables.</>} />
                                                }
                                            </div>
                                            <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.free_buildableArea_ground_all || 0).toFixed(0))}</div>
                                                <div className="app__sectiondata_widget_value_legend">m²</div>
                                                {capacity?.buildable?.volume?.parameters?.free_buildableArea_ground_all <= 0 ?
                                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>Les <strong>{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.buildable_super_zones[0]?.buildableArea).toFixed(0))} m²</strong> de surface constructible au sol sont <strong>entièrement utilisés par les constructions existantes</strong>.</>} />
                                                    :
                                                    <>
                                                        {capacity?.buildable?.volume?.parameters?.free_buildableArea_ground_all < capacity?.buildable?.volume?.parameters?.free_buildableArea_max &&
                                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>La surface constructible restante est limitée par la surface constructible restante au sol de <strong>{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.free_buildableArea_ground).toFixed(0))} m²</strong>.</>} />
                                                        }
                                                    </>
                                                }
                                            </div>
                                            <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.free_buildableArea_raise || 0).toFixed(0))}</div>
                                                <div className="app__sectiondata_widget_value_legend">m²</div>
                                            </div>
                                        </div>
                                        {/* {(capacity?.buildable?.volume?.parameters?.max_buildableArea && capacity.buildable.volume.parameters.builtArea < capacity.buildable.volume.parameters.max_buildableArea) ?
                                            // CASE 1 : SOME AREA STILL AVAILABLE
                                            <>
                                                {capacity?.buildable?.volume?.parameters?.buildableArea_raise > 0 ?
                                                    // CASE 1.1 : RAISE POSSIBLE
                                                    <>
                                                        {capacity?.buildable?.volume?.parameters?.builtArea_ground >= 0 && capacity?.buildable?.volume?.parameters?.buildable_super_zones.length > 0 && capacity?.buildable?.volume?.parameters?.buildable_super_zones[0]?.buildableArea > capacity?.buildable?.volume?.parameters?.builtArea_ground ?
                                                            // CASE 1.1.1 : POSSIBLE TO BUILD MORE ON GROUND AND IN RAISE
                                                            <>
                                                                {capacity?.buildable?.volume?.parameters?.buildableArea_raise <= (capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea) ?
                                                                    // CASE 1.1.1.1 : POSSIBLE TO BUILD MORE ON GROUND AND IN RAISE
                                                                    <div>
                                                                        <div className="app__sectiondata_widget_value_container">
                                                                            <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 1.1.1.1</div>
                                                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - (capacity.buildable.volume.parameters.builtArea + (capacity?.buildable?.volume?.parameters?.buildableArea_raise || 0))).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.buildableArea_raise).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    // CASE 1.1.1.2 : POSSIBLE TO BUILD MORE ON GROUND AND IN RAISE, BUT RAISE IS MORE THAN NEEDED
                                                                    <div>
                                                                        <div className="app__sectiondata_widget_value_container">
                                                                            <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 1.1.1.2</div>
                                                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>Les {helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))} m² de surface constructible restante peuvent être réalisés soit en nouvelles constructions, soit en surélévation.</>} />
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            // CASE 1.1.2 : NOT POSSIBLE TO BUILD MORE ON GROUND BUT ONLY IN RAISE
                                                            <>
                                                                {capacity?.buildable?.volume?.parameters?.buildableArea_raise < (capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea) ?
                                                                    // CASE 1.1.2.1 : AVAILABLE RAISE AREA IS SMALLER THAN WHAT IS STILL BUILDABLE
                                                                    <div>
                                                                        <div className="app__sectiondata_widget_value_container">
                                                                            <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 1.1.2.1</div>
                                                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.buildableArea_raise).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>Il reste <strong>{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))} m² de surface constructible inexploitée</strong>.<br />Mais toute la surface constructible au sol est déja utilisée par les constructions existantes.<br />Et seulement {helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.buildableArea_raise).toFixed(0))} m² sont disponibles en surélévation.</>} />
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">0</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.buildableArea_raise).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                            <WidgetInfo ico={"💡"} title={"Information"} text={<><strong>Seulement {helpers.get_pretty_num((capacity?.buildable?.volume?.parameters?.buildableArea_raise).toFixed(0))} m² sont réalisables en surélévation</strong>, ce qui ne permet pas d'exploiter à 100% le potentiel du terrain.</>} />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    // CASE 1.1.2.2 : AVAILABLE RAISE AREA IS ENOUGHT TO GET 100% BUILDABLE AREA
                                                                    <div>
                                                                        <div className="app__sectiondata_widget_value_container">
                                                                            <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 1.1.2.2</div>
                                                                            <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                            <WidgetInfo ico={"💡"} title={"Information"} text={<>Il y a <strong>{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))} m² de surface constructible inexploitée</strong> qui peut être réalisée uniquement en surélévation car toute la surface constructible au sol est utilisée par les constructions existantes.</>} />
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">0</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                        <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                            <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                                            <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                            <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    // CASE 1.2 : RAISE NOT ALLOWED
                                                    <>
                                                        {capacity?.buildable?.volume?.parameters?.builtArea_ground >= 0 && capacity?.buildable?.volume?.parameters?.buildable_super_zones.length > 0 && capacity?.buildable?.volume?.parameters?.buildable_super_zones[0]?.buildableArea > capacity?.buildable?.volume?.parameters?.builtArea_ground ?
                                                            // CASE 1.2.1 : POSSIBLE TO BUILD MORE ON GROUND
                                                            <div>
                                                                <div className="app__sectiondata_widget_value_container">
                                                                    <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 1.2.1</div>
                                                                    <div className="app__sectiondata_widget_value_text">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                </div>
                                                                <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                    <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                                    <div className="app__sectiondata_widget_value_text_medium">{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))}</div>
                                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                </div>
                                                                <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                    <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                                    <div className="app__sectiondata_widget_value_text_medium">0</div>
                                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                </div>
                                                            </div>
                                                            :
                                                            // CASE 1.2.2 : NOT POSSIBLE TO BUILD MORE ON GROUND AND NO RAISE ALLOWED
                                                            <div>
                                                                <div className="app__sectiondata_widget_value_container">
                                                                    <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 1.2.2</div>
                                                                    <div className="app__sectiondata_widget_value_text">0</div>
                                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>La surface constructible au sol est déja atteinte par les constructions existantes. Cependant, il reste <strong>{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))} m² de surface constructible inexploitée</strong> qui pourrait être réalisée uniquement en surélévation.</>} />
                                                                </div>
                                                                <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                    <div className="app__sectiondata_widget_title">Surface constructible restante<br />(sans surélévation)</div>
                                                                    <div className="app__sectiondata_widget_value_text_medium">0</div>
                                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                </div>
                                                                <div className="app__sectiondata_widget_value_container" style={{ marginTop: "14px" }}>
                                                                    <div className="app__sectiondata_widget_title">Surface constructible restante<br />(en surélévation)</div>
                                                                    <div className="app__sectiondata_widget_value_text_medium">0</div>
                                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                                    <WidgetInfo ico={"💡"} title={"Information"} text={<>Il y a <strong>{helpers.get_pretty_num((capacity.buildable.volume.parameters.max_buildableArea - capacity.buildable.volume.parameters.builtArea).toFixed(0))} m² de surface constructible inexploitée</strong> qui pourrait être réalisée en surélévation.</>} />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            // CASE 0 : NO SPACE AVAILABLE ANY MORE
                                            <div>
                                                <div className="app__sectiondata_widget_value_container">
                                                    <div className="app__sectiondata_widget_title">Surface constructible totale restante - CASE 0</div>
                                                    <div className="app__sectiondata_widget_value_text">0</div>
                                                    <div className="app__sectiondata_widget_value_legend">m²</div>
                                                </div>
                                            </div>
                                        } */}

                                        {/* GRAPH SIDE */}
                                        <div className="app__sectiondata_widget_value_container" style={{ width: window.innerWidth >= 550 && "60%" }}>
                                            <div className="app__sectiondata_widget_title">Potentiel</div>
                                            {(capacity.buildable.volume.parameters.builtArea >= (capacity?.buildable?.volume?.parameters?.max_area_total > 0 ? Math.min(capacity?.buildable?.volume?.parameters?.max_area_total, capacity?.buildable?.volume?.parameters?.buildableArea) : capacity?.buildable?.volume?.parameters?.buildableArea)) ?
                                                <div>Le terrain <strong>est exploité à son plein potentiel</strong>.</div>
                                                :
                                                <>
                                                    {(capacity.buildable.volume.parameters?.free_buildableArea_total <= 0) ?
                                                        <div>Le terrain présente une surface constructible inexploitée de <strong>{(capacity?.buildable?.volume?.parameters?.free_buildableArea_max).toFixed(0)} m²</strong>. Mais dans la configuration actuelle cette surface n'est pas utilisable.</div>
                                                        :
                                                        // <div>Le terrain présente un <strong>potentiel inexploité de {(((capacity?.buildable?.volume?.parameters?.max_area_total > 0 ? Math.min(capacity?.buildable?.volume?.parameters?.max_area_total, capacity?.buildable?.volume?.parameters?.buildableArea) : capacity?.buildable?.volume?.parameters?.buildableArea) || 0) - capacity?.buildable?.volume?.parameters?.builtArea).toFixed(0)} m²</strong>, soit <strong>{helpers.get_pretty_num((get_ratio()).toFixed(1))} %</strong> de la surface constructible qui est encore disponible.</div>
                                                        // <div>Le terrain présente un <strong>potentiel inexploité de {helpers.get_pretty_num((get_ratio()).toFixed(1))} %</strong>, soit <strong>{(((capacity?.buildable?.volume?.parameters?.max_area_total > 0 ? Math.min(capacity?.buildable?.volume?.parameters?.max_area_total, capacity?.buildable?.volume?.parameters?.buildableArea) : capacity?.buildable?.volume?.parameters?.buildableArea) || 0) - capacity?.buildable?.volume?.parameters?.builtArea).toFixed(0)} m²</strong> de surface constructible encore disponible.</div>
                                                        <div>Le terrain présente une surface constructible inexploitée de <strong>{(capacity?.buildable?.volume?.parameters?.free_buildableArea_max).toFixed(0)} m²</strong>. Uniquement <strong>{(capacity?.buildable?.volume?.parameters?.free_buildableArea_total).toFixed(0)} m²</strong> sont réellement exploitables, soit un <strong>potentiel restant de {helpers.get_pretty_num((get_ratio()).toFixed(1))} %</strong>.</div>
                                                    }
                                                </>
                                            }
                                            {/* <div className="app__buildings_chart" style={{ marginTop: '10px', width: '280px', height: '170px', transform: 'translateX(-54px)' }}> */}
                                            <div className="app__buildings_chart" style={{ marginTop: '10px', width: '280px', height: '190px' }}>
                                                {(capacity.buildable.volume.parameters.builtArea >= (capacity?.buildable?.volume?.parameters?.max_area_total > 0 ? Math.min(capacity?.buildable?.volume?.parameters?.max_area_total, capacity?.buildable?.volume?.parameters?.buildableArea) : capacity?.buildable?.volume?.parameters?.buildableArea)) ?
                                                    <div className="app__buildings_chart_value" style={{ top: '50px', left: '80px', width: '120px', flexDirection: 'column' }}>
                                                        <div><span style={{ fontSize: '32px', lineHeight: '28px', fontWeight: '600', marginLeft: '4px', marginRight: '4px' }}>100</span>%</div>
                                                        <div style={{ width: '100%', textAlign: 'center', fontSize: '10px' }}>de surface constructible</div>
                                                        <div style={{ width: '100%', textAlign: 'center', fontSize: '10px' }}>exploitée</div>
                                                    </div>
                                                    :
                                                    <div className="app__buildings_chart_value" style={{ top: '50px', left: '80px', width: '120px', flexDirection: 'column' }}>
                                                        <div><span style={{ fontSize: '32px', lineHeight: '28px', fontWeight: '600', marginLeft: '4px', marginRight: '4px' }}>{helpers.get_pretty_num((get_ratio()).toFixed(1))}</span>%</div>
                                                        <div style={{ width: '100%', textAlign: 'center', fontSize: '10px' }}>de surface constructible</div>
                                                        <div style={{ width: '100%', textAlign: 'center', fontSize: '10px' }}>encore exploitable</div>
                                                    </div>
                                                }
                                                <DoughnutChart chartData={chartData} chartOptions={pieChartOptions} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {/* LEVEL DETAILS */}
                    <div className="app__sectiondata_widget_spacer_40"></div>
                    <div id="section_5_surfaces" className="app__sectiondata_widget_title">Détail de la surface constructible par niveaux</div>
                    <div className="buildable__levels" onMouseLeave={() => { setSelectedLevel(null) }}>
                        <div className="buildable__listpart">
                            {/* <div className="buildable__listpart" onMouseLeave={() => { setSelectedLevel(null) }}> */}
                            <div className="buildable__listpart_title">
                                {/* <div className="buildable__listpart_title_item buildable__col0">Niveau</div> */}
                                <div className="buildable__listpart_title_item buildable__col1">Niveau</div>
                                <div className="buildable__listpart_title_item buildable__col2">Surface disponible</div>
                                <div className="buildable__listpart_title_item buildable__col3">Surface constructible</div>
                            </div>
                            <div className="buildable__listpart_item_container">
                                {capacity.buildable.volume.levels.map((level, indexLevel) => {
                                    if (indexLevel > 0) {
                                        var currentIndex = (capacity.buildable.volume.levels.length - 0) - indexLevel;
                                        var currentItem = capacity.buildable.volume.levels[currentIndex];
                                        var currentPrevItem = capacity.buildable.volume.levels[currentIndex - 1];

                                        return (
                                            <div key={"buildable_" + indexLevel} className={`buildable__listpart_item ${selectedLevel === currentIndex ? "buildable__listpart_item_selected" : ""} ${(selectedLevel !== null && selectedLevel !== currentIndex) ? "buildable__listpart_item_unselected" : ""}`} onClick={() => { switchSelectedLevel(currentIndex) }}>
                                                {/* <div className={`buildable__listpart_item ${selectedLevel === currentIndex ? "buildable__listpart_item_selected" : ""} ${(selectedLevel !== null && selectedLevel !== currentIndex) ? "buildable__listpart_item_unselected" : ""}`} onMouseEnter={() => { setSelectedLevel(currentIndex) }}> */}
                                                {currentIndex === 1 ?
                                                    <div className="buildable__listpart_item_title">RDC</div>
                                                    :
                                                    <div className="buildable__listpart_item_title">R+{currentIndex - 1}</div>
                                                }
                                                <div className="buildable__listpart_item_body">
                                                    {/* <div className="buildable__listpart_item_value buildable__col1">{currentIndex === 1 ? "0.00 m" : currentPrevItem.elevation.toFixed(2) + "m - " + currentItem.elevation.toFixed(2) + "m"}</div> */}
                                                    <div className="buildable__listpart_item_value buildable__col1" style={{ fontSize: '10px', lineHeight: '9px' }}>
                                                        <div className="buildable__listpart_item_subvalue"><div className="buildable__listpart_item_subvalue_title">haut:</div>{currentItem.elevation.toFixed(2)}m</div>
                                                        <div className="buildable__listpart_item_subvalue"><div className="buildable__listpart_item_subvalue_title">bas:</div>{currentIndex === 1 ? "0.00" : currentPrevItem.elevation.toFixed(2)}m</div>
                                                    </div>
                                                    {/* <div className="buildable__listpart_item_value buildable__col2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: "10px" }}><div style={{ fontWeight: "600" }}>{helpers.get_pretty_num(currentItem.availableArea.toFixed(2))} m²</div><div style={{ color: "grey", fontSize: "12px" }}>{Math.round(100 * currentItem.availableArea / capacity.landBase.union.area)}%</div></div> */}
                                                    <div className="buildable__listpart_item_value buildable__col2" style={{ position: "relative" }}><div style={{ fontWeight: "400" }}>{helpers.get_pretty_num(currentItem.availableArea.toFixed(2))} m²</div><div style={{ color: "grey", fontSize: "10px", position: "absolute", top: "-10px" }}>{Math.round(100 * currentItem.availableArea / capacity.landBase.union.area)}%</div></div>
                                                    <div className="buildable__listpart_item_value buildable__col3" style={{ position: "relative" }}><div style={{ fontWeight: "600" }}>{helpers.get_pretty_num(currentItem.buildableArea.toFixed(2))} m²</div><div style={{ color: "grey", fontSize: "10px", position: "absolute", top: "-10px" }}>{Math.round(100 * currentItem.buildableArea / capacity.landBase.union.area)}%</div></div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                        {window.innerWidth > 550 &&
                            <div className="buildable__floorplan_part">
                                <div className={`buildable__floorplan_box ${selectedLevel !== null ? "buildable__floorplan_box_selected" : ""}`}>
                                    <div className={`buildable__floorplan_container ${selectedLevel !== null ? "buildable__floorplan_container_selected" : ""}`}>
                                        <svg viewBox="-20 -20 840 840" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <polygon points={helpers.get_svgCoords_from_globalCoords(capacity?.landBase?.union?.geometry?.geometry?.coordinates, capacity?.landBase?.union?.bbox).join(" ")} fillRule="evenodd" fill="none" stroke="#062134" strokeWidth="6" />
                                            {capacity?.landBase?.union?.bounds_groups.map((item, index) => {
                                                return (
                                                    <circle key={"circle_" + index} cx={helpers.get_svgCoords_from_localCoords([item.start_coord_local], capacity?.landBase?.union?.bbox)[0][0]} cy={helpers.get_svgCoords_from_localCoords([item.start_coord_local], capacity?.landBase?.union?.bbox)[0][1]} r="10" fill="#062134" />
                                                )
                                            })}
                                        </svg>
                                    </div>
                                    {(capacity?.buildable?.volume?.levels && capacity.buildable.volume.levels.length > 0) &&
                                        <>
                                            {capacity.buildable.volume.levels.map((level, indexLevel) => {
                                                if (indexLevel > 0) {
                                                    // if (indexLevel !== capacity.buildable.volume.levels.length - 1) {
                                                    return (
                                                        // <div className={`buildable__floorplan_container ${selectedLevel === indexLevel ? "buildable__floorplan_container_selected" : ""} ${(selectedLevel !== null && selectedLevel !== indexLevel) ? "buildable__floorplan_container_unselected" : ""}`} style={{ top: indexLevel * -14 + "px" }}>
                                                        <div key={"buildable2_" + indexLevel} className={`buildable__floorplan_container ${selectedLevel === indexLevel ? "buildable__floorplan_container_selected" : ""} ${(selectedLevel !== null && selectedLevel !== indexLevel) ? "buildable__floorplan_container_unselected" : ""}`}>
                                                            <svg viewBox="-20 -20 840 840" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                {/* DRAW SVG SHAPES */}
                                                                {level?.availableSurfaces && level.availableSurfaces.length > 0 &&
                                                                    <>
                                                                        {level.availableSurfaces.map(((surface, index1) => {
                                                                            var colorStroke = "#1ECD97";
                                                                            var colorFill = "#BBF0E0";
                                                                            if (surface.properties.buildableArea < surface.properties.availableArea) {
                                                                                colorFill = "#E5F9F3";
                                                                            }
                                                                            return (
                                                                                <>
                                                                                    {surface?.properties?.path_svg ?
                                                                                        <>
                                                                                            {surface?.properties?.path_svg.map((path, index2) => {
                                                                                                return (
                                                                                                    <path key={"polygon1_" + index2} d={path} fillRule="evenodd" fill={colorFill} stroke={colorStroke} strokeWidth="4" />
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            {surface?.properties?.coords_svg.map((surfaceItem, index2) => {
                                                                                                return (
                                                                                                    <polygon key={"polygon2_" + index2} points={surfaceItem.join(" ")} fill={colorFill} stroke={colorStroke} strokeWidth="4" />
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }))}
                                                                    </>
                                                                }
                                                                {level?.builtSurfaces && level?.builtSurfaces.length > 0 &&
                                                                    <>
                                                                        {level.builtSurfaces.map(((surface, index1) => {
                                                                            if (surface?.properties?.type === "built" || surface?.properties?.raisable === true) {
                                                                                // var colorStroke = "#808080";
                                                                                // var colorFill = "#e7e7e7";
                                                                                var colorStroke = "#062134";
                                                                                var colorFill = "#A5B0B6";
                                                                                var opacityFill = "1";
                                                                                if (surface?.properties?.type !== "built") {
                                                                                    colorFill = "#CCD1D3";
                                                                                    opacityFill = "0.5";
                                                                                }
                                                                                return (
                                                                                    <>
                                                                                        {surface?.properties?.path_svg &&
                                                                                            <path key={"polygon1_" + index1} d={surface?.properties?.path_svg} fillRule="evenodd" fill={colorFill} fill-opacity={opacityFill} stroke={colorStroke} strokeWidth="2" />
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        }))}
                                                                    </>
                                                                }
                                                                {/* DRAW SVG TEXT */}
                                                                {level?.availableSurfaces && level.availableSurfaces.length > 0 &&
                                                                    <>
                                                                        {level.availableSurfaces.map(((surface, index) => {
                                                                            return (
                                                                                <>
                                                                                    {Array.isArray(surface?.properties?.centerCoords_svg[0]) ?
                                                                                        <>
                                                                                            {surface?.properties?.centerCoords_svg.map((text, index2) => {
                                                                                                return (
                                                                                                    <text key={"text_" + index2} className={`buildable__floorplan_text ${selectedLevel === indexLevel ? "buildable__floorplan_text_selected" : ""}`} x={surface?.properties?.centerCoords_svg[index2][0] - 50} y={surface?.properties?.centerCoords_svg[index2][1] + 10} style={{ fontSize: "36px" }} fill="#0b9779">{surface?.properties?.centerCoords_area[index2].toFixed(2)} m²</text>
                                                                                                )
                                                                                            })}
                                                                                        </>
                                                                                        :
                                                                                        <text key={"text_" + index} className={`buildable__floorplan_text ${selectedLevel === indexLevel ? "buildable__floorplan_text_selected" : ""}`} x={surface?.properties?.centerCoords_svg[0] - 50} y={surface?.properties?.centerCoords_svg[1] + 10} style={{ fontSize: "36px" }} fill="#0b9779">{surface?.properties?.buildableArea.toFixed(2)} m²</text>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }))}
                                                                    </>
                                                                }
                                                            </svg>
                                                        </div>
                                                    )
                                                }
                                            })}
                                        </>
                                    }
                                </div>
                                {/* LEGENDE */}
                                <div className={`buildable__legend_container ${selectedLevel !== null ? "buildable__legend_container_selected" : ""}`}>
                                    <div className="buildable__legend_item">
                                        <div className="buildable__legend_square" style={{ backgroundColor: "#BBF0E0" }}></div>
                                        <div className="buildable__legend_square_text">{capacity?.buildable?.volume?.parameters?.availableArea === capacity?.buildable?.volume?.parameters?.buildableArea ? "Surface constructible" : "Surface totalement constructible"}</div>
                                    </div>
                                    {!(capacity?.buildable?.volume?.parameters?.availableArea === capacity?.buildable?.volume?.parameters?.buildableArea) &&
                                        <div className="buildable__legend_item">
                                            <div className="buildable__legend_square" style={{ backgroundColor: "#E5F9F3" }}></div>
                                            <div className="buildable__legend_square_text">Surface partiellement constructible</div>
                                        </div>
                                    }
                                    {capacity?.buildable?.volume?.parameters?.builtArea > 0 &&
                                        <div className="buildable__legend_item">
                                            <div className="buildable__legend_square" style={{ backgroundColor: "#A5B0B6", borderColor: "#062134" }}></div>
                                            <div className="buildable__legend_square_text">Surface déja construite</div>
                                        </div>
                                    }
                                    {(capacity?.buildable?.volume?.parameters?.builtArea > 0 && capacity?.buildable?.volume?.parameters?.buildableArea_raise > 0) &&
                                        <div className="buildable__legend_item">
                                            <div className="buildable__legend_square" style={{ backgroundColor: "#CCD1D3", borderColor: "#062134" }}></div>
                                            <div className="buildable__legend_square_text">Surface constructible en surélévation</div>
                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                    {/* LEGEND MARGIN */}
                    {window.innerWidth > 550 &&
                        <>
                            <div className={`buildable__legend_margin ${selectedLevel === null ? "" : "buildable__legend_margin_large"}`}></div>
                            {!(capacity?.buildable?.volume?.parameters?.availableArea === capacity?.buildable?.volume?.parameters?.buildableArea) &&
                                <div className={`buildable__legend_margin2 ${selectedLevel === null ? "" : "buildable__legend_margin_large2"}`}></div>
                            }
                            {capacity?.buildable?.volume?.parameters?.builtArea > 0 &&
                                <div className={`buildable__legend_margin2 ${selectedLevel === null ? "" : "buildable__legend_margin_large2"}`}></div>
                            }
                            {(capacity?.buildable?.volume?.parameters?.builtArea > 0 && capacity?.buildable?.volume?.parameters?.buildableArea_raise > 0) &&
                                <div className={`buildable__legend_margin2 ${selectedLevel === null ? "" : "buildable__legend_margin_large2"}`}></div>
                            }
                        </>
                    }
                </>
                :
                <div className="app__sectiondata_loading_container_medium1">
                    <div className="app__sectiondata_loading"></div>
                    <div className="app__sectiondata_loading_text">Nous calculons le volume constructible</div>
                </div>
            }
        </div>
    );
};

export default BuildableDetails;