// Import dependencies
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { capaCreate } from '../../../actions/capa';

// Import CSS & assets
import './NewCapa.css';

const NewCapa = ({ user }) => {

    // Set global functions
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Set specific functions
    // Create new capa
    const createNewCapa = () => {
        var userId = "";
        var teams = [];
        var info = {
            user_name: "",
            team_name: "",
        };
        if (user?.result?._id) { userId = user.result._id; info.user_name = (user?.result?.firstName || "") + " " + (user?.result?.lastName || "") };
        if (user?.teams && user.teams.length > 0) { teams = [user.teams[0]._id]; info.team_name = user.teams[0]?.name || "" };
        const formData = { userId, teams, info };
        dispatch(capaCreate(formData, handleSuccess, handleFail));
    }
    // Loading
    const handleSuccess = (data) => {
        console.log("SUCCESS", data);
        navigate(`/capastudy/${data.result._id}`);
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    return (
        <div className="app__newcapa_container">
            <button className="app__newcapa_button" onClick={createNewCapa}>+</button>
            <div className="app__newcapa_text">Nouvelle étude</div>
            {/* <div onClick={() => { navigate('/capastudy/1') }}>GO TO CAPASTUDY</div> */}
        </div>
    );
};

export default NewCapa;