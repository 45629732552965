// Import deoendencies
import React, { useState, useEffect } from 'react';
import decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resendMail } from '../../../actions/auth';
import { capaCreate } from '../../../actions/capa';
import { teamGetById } from '../../../actions/teams';


// Import CSS & assets
import './Header.css';
import user_pp from '../../../assets/user_pp_default.png';
import logo_mobile from '../../../assets/logo_mobile.svg';
import logo from '../../../assets/logo.svg';

// Import variables
import { LOGOUT } from '../../../constants/actionTypes';


const Header = ({ title, isApp, userHook, capacity, isDark, isLogo }) => {

    // Set global functions
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Set hooks
    // User
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
    // User validation
    const [isMailSend, setIsMailSend] = useState(false);
    // User advanced info
    const [toogleAdvancedInfo, setToogleAdvancedInfo] = useState(false);
    // Team
    const [currentCapaTeam, setCurrentCapaTeam] = useState(null);

    // Set hook funcitons
    // On load function
    useEffect(() => {
        // Get users' token
        const token = user?.token;
        // If token exists
        if (token) {
            // Decode the token
            const decodedToken = decode(token);
            // Check if token is still valid
            if (decodedToken.exp * 1000 < new Date().getTime()) {
                console.log("User token expired");
                logout("expired");
            }
        }
        else {
            console.log("User not logged in");
            logout();
        }
    }, []);
    // Team
    useEffect(() => {
        if (user?.teams && user.teams.length > 0 && capacity?.teams && capacity?.teams.length > 0 && user.teams[0]._id !== capacity?.teams[0]) {
            // Get team data
            getTeam(capacity?.teams[0]);
        }
    }, [capacity]);
    const getTeam = (team_id) => {
        // console.log("-FETCHING RULES- : Loading...");
        dispatch(teamGetById(team_id, handleTeamGetSuccess, handleTeamGetFail));
    }
    // Get response from Rules fetching : success
    const handleTeamGetSuccess = (data) => {
        console.log("-FETCHING TEAM- : Success", data);
        if (data?.team?.name) {
            setCurrentCapaTeam(data.team);
        }
    };
    // Get response from Rules fetching : error
    const handleTeamGetFail = (error) => {
        console.log("-FETCHING TEAM- : Error", error.response.data);
    }
    // User updated from page
    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')));
    }, [userHook]);
    // Specific functions
    const handleToggleUserInfo = () => {
        setToogleAdvancedInfo(!toogleAdvancedInfo);
    }
    const logout = (reason) => {
        // console.log(window.location);
        // Dispatch log out action
        dispatch({ type: LOGOUT });
        // Go back to home page
        navigate('/auth?type=signin&cause=' + reason + '&source=' + window.location.pathname);
    }
    const resendValidationMail = () => {
        console.log("RESEND MAIL");
        dispatch(resendMail({ userId: user?.result?._id }, handleSuccessMail, handleFailMail));
    }
    const handleSuccessMail = () => {
        setIsMailSend(true);
    }
    const handleFailMail = (error) => {
        console.log("error:", error);
    }
    // Create new capa
    const createNewCapa = () => {
        var userId = "";
        var teams = [];
        var info = {
            user_name: "",
            team_name: "",
        };
        if (user?.result?._id) { userId = user.result._id; info.user_name = (user?.result?.firstName || "") + " " + (user?.result?.lastName || "") };
        if (user?.teams && user.teams.length > 0) { teams = [user.teams[0]._id]; info.team_name = user.teams[0]?.name || "" };
        const formData = { userId, teams, info };
        dispatch(capaCreate(formData, handleSuccess, handleFail));
    }
    const handleSuccess = (data) => {
        console.log("SUCCESS", data);
        // navigate(`/capastudy/${data.result._id}`);
        // window.location.reload(false);
        window.location.href = window.location.origin + "/capastudy/" + data.result._id;
    }
    const handleFail = (error) => {
        console.log("FAIL", error.response.data.message);
    }

    return (
        <div id="header" className={`app__header ${isApp ? 'app__padding_small' : 'app__padding_large'} ${isDark ? 'app__header_dark' : ''}`}>
            <div className="app__header_info_container">
                <div className='app__header_info_title_container'>
                    {isLogo &&
                        <div className="app__header_info_logo_container">
                            <img src={logo} alt="logo" />
                        </div>
                    }
                    <div className="app__header_info_title">
                        <h1>PROPLAB</h1>
                        <h2>{title}{(window.innerWidth > 550 && capacity?.title) && <span className="app__header_info_studytitle"> • {capacity?.title}</span>}{currentCapaTeam?.name && <span className="app__header_info_studytitle"> • Equipe : {currentCapaTeam?.name}</span>}</h2>
                    </div>
                </div>
                {/* <div style={{ position: "relative" }} onMouseLeave={() => { setToogleAdvancedInfo(true) }} className={`app__header_info_user_container ${toogleAdvancedInfo ? 'app__header_info_user_container_visible' : 'app__header_info_user_container_hidden'}`}> */}
                <div onMouseLeave={() => { setToogleAdvancedInfo(false) }} className="app__header_info_user_container">
                    <div onClick={() => { setToogleAdvancedInfo(true) }} className={`app__header_info_user_basic_container ${toogleAdvancedInfo ? 'app__header_info_user_basic_container_focus' : ''}`}>
                        <div className={`app__header_info_user_basic_text ${toogleAdvancedInfo ? 'app__header_info_user_basic_text_focus' : ''}`}>
                            <div className="app__header_info_user_basic_text_name">
                                {user?.result?.firstName}
                            </div>
                            <div className="app__header_info_user_basic_text_group">
                                {(user?.teams && user?.teams.length > 0) ?
                                    user.teams[0].name :
                                    ""
                                }
                            </div>
                        </div>
                        <div className="app__header_info_user_basic_img">
                            {user?.result ?
                                <img src={user?.result?.avatar} alt="user_pp" />
                                :
                                <img src={user_pp} alt="user_pp" />
                            }
                        </div>
                    </div>
                    {toogleAdvancedInfo &&
                        <div className={`app__header_info_user_container_visible scale-up-topright ${isDark ? 'app__header__info_dark' : ''}`}>
                            <div className={`app__header_info_user_advanced_container ${toogleAdvancedInfo ? 'app__header_info_user_advanced_visible' : 'app__header_info_user_advanced_hidden'}`}>
                                <div className={`app__header_info_user_advanced_spacer ${isDark ? 'app__header_info_user_advanced_spacer_dark' : ''}`}></div>
                                {(!isDark && (user?.teams && user?.teams.length > 0)) &&
                                    <div onClick={() => { createNewCapa() }} className="app__header_info_user_advanced_link">
                                        Nouvelle étude
                                    </div>
                                }
                                {(isApp && !isDark) &&
                                    <div onClick={() => { navigate('/dashboard') }} className="app__header_info_user_advanced_link">
                                        Dashboard
                                    </div>
                                }
                                {isDark &&
                                    <div onClick={() => { navigate('/dashboard') }} className="app__header_info_user_advanced_link">
                                        Dashboard
                                    </div>
                                }
                                {(!isDark && (user?.result?.email.includes("@fractal-buildings.com") || user?.result?.email.includes("@proplab.fr"))) &&
                                    <div onClick={() => { navigate('/admin') }} className="app__header_info_user_advanced_link">
                                        Administration
                                    </div>
                                }
                                <div onClick={logout} className="app__header_info_user_advanced_button">
                                    Déconnexion
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/* {
                user?.result?.emailValidation === false &&
                <div className="app__header_banner_container">
                    <div className="app__header_banner_text">
                        ✋ Votre compte n'est pas activé. Veuillez l'activer pour continuer à utiliser PropLab.
                    </div>
                    {isMailSend ?
                        <div className="app__header_banner_text_mail">
                            {isMailSend ? `✔ email envoyé à ${user?.result?.email}` : <span><em>Une erreur est survenue, veuillez contacter <a className='app__header_link' href="mailto:jordan@proplab.fr?subject=Erreur lors de l'activation de mon compte">le support</a></em></span>}
                        </div>
                        :
                        <div onClick={resendValidationMail} className="app__header_banner_button">
                            Renvoyer l'email d'activation
                        </div>
                    }
                </div>
            } */}
            {/* {
                !(user?.teams && user?.teams.length > 0) &&
                <div className="app__header_banner_container">
                    <div className="app__header_banner_text">
                        ✋ Votre essai gratuit se termine dans XX jours.
                    </div>
                    <div onClick={() => { }} className="app__header_banner_button">
                        Passer premium
                    </div>
                </div>
            } */}
        </div >
    );
};

export default Header;